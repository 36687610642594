import React, { useEffect } from "react";
import GlobalTemplate from "../../../templates/global";
import { Container, Grid, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import BannerList from "./components/BannerList";
import SliderList from "./components/SliderList";
import GridList from "./components/GridList";

function Discover() {
  const programDetails = useSelector((state) => state.program.listings);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.program.getListings());
  }, [dispatch]);

  return (
    <GlobalTemplate title="İçerikler">
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          px: { xs: 2, md: 0 },
          pb: { md: 10 },
        }}
      >
        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "70vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          Object.entries(programDetails).map(([key, value]) => {
            const data = value.items;
            if (value.type == "BANNER_SLIDER") {
              return <BannerList key={key} banner={data} keyIndex={key} />;
            }
            if (value.type == "SLIDER") {
              return (
                <SliderList
                  key={key}
                  title={value.title}
                  keyIndex={key}
                  slider={data}
                />
              );
            }
            if (value.type == "GRID") {
              return (
                <GridList
                  key={key}
                  title={value.title}
                  keyIndex={key}
                  grid={data}
                />
              );
            }
          })
        )}
      </Container>
    </GlobalTemplate>
  );
}

export default Discover;
