import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Typography,
  Container,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  Chip,
  MenuItem,
  Stack,
  Button,
  Grid,
} from "@mui/material";
import TagsInput from "../../../../../../components/admin/tagsInput";
import AdminTemplate from "../../../../templates/global";
import {
  CssTextField,
  CssFormControl,
  CssAutocomplete,
} from "../../../../../../styles/DefaultComponents";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../store/actions";

//---------------DUMMY DATA START-------------------//

const mIcons = [
  { label: "MATURITY_TR_13_PLUS" },
  { label: "MATURITY_TR_18_PLUS" },
  { label: "MATURITY_TR_NEGATIVE_EXAMPLES" },
];

//---------------DUMMY DATA END-------------------//

const NewEpisode = () => {
  const [fields, setFields] = useState({
    title: "",
    description: "",
    cast: [],
    season: 1,
    index: 0,
    videoMediaUrl: "",
    imageMediaUrl: "",
    premiereTime: dayjs().toISOString(),
    isPublic: "",
    isTrailer: "",
    maturityIcons: [],
  });

  const uiLoading = useSelector((state) => state.default.loading.container);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const medias = useSelector((state) => state.media.medias);
  const dispatch = useDispatch();

  let { program_id } = useParams();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.media.getMedias(["IMAGE", "VIDEO"], ["DISTRIBUTED"]));
  }, [dispatch]);

  const imageURLS = [];
  const videoURLS = [];

  medias.map((media) => {
    if (media.type == "IMAGE") {
      imageURLS.push({ label: media.fileName, url: media.cdnUrl });
    } else {
      videoURLS.push({ label: media.fileName, url: media.cdnUrl });
    }
  });

  const handleSelectedTags = (items) => {
    setFields({ ...fields, cast: items });
  };

  const handleChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    const icons = fields["maturityIcons"].map((ico) => ico.label);

    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.episode.createEpisode(
        fields["cast"],
        fields["description"],
        fields["index"],
        fields["isPublic"],
        fields["isTrailer"],
        icons,
        fields["premiereTime"],
        program_id,
        fields["season"],
        fields["title"],
        fields["videoMediaUrl"].url,
        fields["imageMediaUrl"].url
      )
    );
  };

  return (
    <AdminTemplate title="New Episode">
      <Container
        maxWidth="false"
        sx={{ maxWidth: "1950px" }}
        className="custom-popper"
      >
        <Typography variant="h4" gutterBottom>
          New Episode
        </Typography>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  value={fields["title"]}
                  label="Title"
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  name="title"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <TagsInput
                  selectedTags={handleSelectedTags}
                  tags={fields["cast"]}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  name="cast"
                  placeholder="Press enter for each cast"
                  label="Cast"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={fields["season"]}
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  label="Season Number"
                  name="season"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  type="number"
                  required
                  fullWidth
                  variant="outlined"
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  value={fields["index"]}
                  label="Episode Number"
                  name="index"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={videoURLS}
                  value={fields["videoMediaUrl"]}
                  onChange={(_, value) => handleChange(value, "videoMediaUrl")}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Video Media URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={imageURLS}
                  onChange={(_, value) => handleChange(value, "imageMediaUrl")}
                  value={fields["imageMediaUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Image Media URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Public</InputLabel>
                  <Select
                    label="Public"
                    value={fields["isPublic"]}
                    name="isPublic"
                    onChange={(e) =>
                      setFields({ ...fields, [e.target.name]: e.target.value })
                    }
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(true)}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(false)}
                    >
                      No
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Trailer</InputLabel>
                  <Select
                    label="Trailer"
                    value={fields["isTrailer"]}
                    name="isTrailer"
                    onChange={(e) =>
                      setFields({ ...fields, [e.target.name]: e.target.value })
                    }
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(true)}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(false)}
                    >
                      No
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>

              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  multiple
                  options={mIcons}
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  filterSelectedOptions
                  onChange={(_, value) => handleChange(value, "maturityIcons")}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        color="primary"
                        variant="outlined"
                        label={option.label}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      label="Maturity Icons"
                      placeholder="Add icon"
                      margin="normal"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    label="Premiere Time"
                    value={fields["premiereTime"]}
                    onChange={(value) =>
                      handleChange(value.toISOString(), "premiereTime")
                    }
                    renderInput={(params) => (
                      <CssTextField
                        margin="normal"
                        size="small"
                        fullWidth
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <CssTextField
              margin="normal"
              size="small"
              fullWidth
              required
              variant="outlined"
              label="Description"
              placeholder="Episode description..."
              value={fields["description"]}
              multiline
              rows={4}
              onChange={(e) =>
                setFields({ ...fields, [e.target.name]: e.target.value })
              }
              name="description"
            />
            <Grid
              item
              md={8}
              lg={4}
              mt={5}
              xl={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-end" },
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "20%" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  href={`/admin/program/${program_id}/details`}
                >
                  Cancel
                </Button>
                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  fullWidth
                  type="submit"
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Stack>
            </Grid>
          </Box>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default NewEpisode;
