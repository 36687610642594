import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import RaffleCard from "./components/RaffleCard";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

const RaffleArchived = () => {
  const raffles = useSelector((state) => state.raffle.raffles);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.raffle.getRaffles());
  }, [dispatch]);

  return (
    <AdminTemplate title="Archived Raffles">
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Typography variant="h4" gutterBottom>
         Archived Raffles
        </Typography>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {raffles.map(
              (raffle) =>
                raffle.isArchived && (
                  <Grid key={raffle.id} item xs={12} sm={6} md={3}>
                    <RaffleCard raffle={raffle} />
                  </Grid>
                )
            )}
          </Grid>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default RaffleArchived;
