import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  Typography,
  Container,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  Grid,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import {
  CssTextField,
  CssFormControl,
  CssAutocomplete,
} from "../../../../../styles/DefaultComponents";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

const EditRaffle = () => {
  const [fields, setFields] = useState({ loading: true });
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const raffle = useSelector((state) => state.raffle.raffle);
  const medias = useSelector((state) => state.media.medias);
  const dispatch = useDispatch();

  let { raffle_id } = useParams();

  const imageURLS = [];
  const videoURLS = [];

  medias.map((media) => {
    if (media.type == "IMAGE") {
      imageURLS.push({ label: media.fileName, url: media.cdnUrl });
    } else {
      videoURLS.push({ label: media.fileName, url: media.cdnUrl });
    }
  });

  useEffect(() => {
    dispatch(actions.raffle.getEditRaffle(raffle_id));
  }, [dispatch]);

  useEffect(() => {
    if (raffle?.title) {
      const sponsorUrl = imageURLS.filter(
        (URL) => URL.url === raffle.sponsorBannerMediaUrl
      );

      const promoUrl = videoURLS.filter(
        (URL) => URL.url === raffle.promoVideoMediaUrl
      );

      const posterUrl = imageURLS.filter(
        (URL) => URL.url === raffle.verticalPosterImageMediaUrl
      );

      setFields({
        title: raffle.title,
        description: raffle.description,
        sponsorUrl: sponsorUrl[0],
        loading: false,
        posterUrl: posterUrl[0],
        promoUrl: promoUrl[0],
        startTime: dayjs(raffle.startTime),
        endTime: dayjs(raffle.endTime),
        isPublic: raffle.isPublic,
        isArchived: raffle.isArchived,
      });
    }
  }, [raffle]);

  const handleChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.raffle.updateRaffle(
        raffle_id,
        fields["description"],
        fields["endTime"],
        fields["isPublic"],
        fields["isArchived"],
        fields["promoUrl"].url,
        fields["sponsorUrl"].url,
        fields["title"],
        fields["posterUrl"].url
      )
    );
  };

  return (
    <AdminTemplate
      title={raffle.title ? `Edit ${raffle.title}` : "Yükleniyor..."}
    >
      <Container
        maxWidth="false"
        sx={{ maxWidth: "1950px" }}
        className="custom-popper"
      >
        <Typography variant="h4" gutterBottom>
          Edit {raffle.title}
        </Typography>

        {fields["loading"] ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  id="title"
                  value={fields["title"]}
                  label="Title"
                  name="title"
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={imageURLS}
                  onChange={(_, value) => handleChange(value, "posterUrl")}
                  value={fields["posterUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Poster Image URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={videoURLS}
                  onChange={(_, value) => handleChange(value, "promoUrl")}
                  value={fields["promoUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Promo Video URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={imageURLS}
                  onChange={(_, value) => handleChange(value, "sponsorUrl")}
                  value={fields["sponsorUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Sponsor Image URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Public</InputLabel>
                  <Select
                    label="Public"
                    value={fields["isPublic"]}
                    name="isPublic"
                    onChange={(e) =>
                      setFields({ ...fields, [e.target.name]: e.target.value })
                    }
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(true)}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(false)}
                    >
                      No
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Archived</InputLabel>
                  <Select
                    label="Archived"
                    value={fields["isArchived"]}
                    name="isArchived"
                    onChange={(e) =>
                      setFields({ ...fields, [e.target.name]: e.target.value })
                    }
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(true)}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(false)}
                    >
                      No
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    label="Start Time Date"
                    value={fields["startTime"]}
                    disabled
                    renderInput={(params) => (
                      <CssTextField
                        margin="normal"
                        size="small"
                        fullWidth
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    label="End Time Date"
                    value={fields["endTime"]}
                    onChange={(value) =>
                      handleChange(value.toISOString(), "endTime")
                    }
                    renderInput={(params) => (
                      <CssTextField
                        margin="normal"
                        size="small"
                        fullWidth
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <CssTextField
              margin="normal"
              size="small"
              fullWidth
              required
              variant="outlined"
              label="Description"
              placeholder="Raffle description..."
              value={fields["description"]}
              multiline
              rows={4}
              onChange={(e) =>
                setFields({ ...fields, [e.target.name]: e.target.value })
              }
              name="description"
            />
            <Grid
              item
              md={8}
              lg={4}
              mt={5}
              xl={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-end" },
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "20%" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  href="/admin/raffle/display"
                >
                  Cancel
                </Button>
                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  fullWidth
                  type="submit"
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Stack>
            </Grid>
          </Box>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default EditRaffle;
