import React, { useEffect, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import ResponsiveAppBar from "../../../components/client/appbar";
import ArrowUpwardSharpIcon from "@mui/icons-material/ArrowUpwardSharp";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import { modalStyle, CssTextField } from "../../../styles/DefaultComponents";

export default function GlobalTemplate({
  children,
  bgBackground,
  bgLight = false,
  autoHeight = false,
  title,
  isAppbar = true,
}) {
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const bgSettings = bgBackground
    ? {
        backgroundImage: `url(/assets/client/images/${
          bgLight ? "bg.jpg" : "BG.png"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundColor: "#000",
        backgroundSize: "auto",
        backgroundPosition: "top",
        ["@media (min-width:2000px)"]: {
          backgroundRepeat: "round",
          backgroundPosition: "center",
        },
      }
    : {};

  useEffect(() => {
    document.title = title ? `Bananativi | ${title}` : "Bananativi";
  }, [title]);

  useEffect(() => {
    const backToTop = document?.getElementById("back-to-top");
    if (backToTop !== null && backToTop !== undefined) {
      document
        ?.getElementById("back-to-top")
        ?.classList.add("animated", "fadeOut");
      window.addEventListener("scroll", (e) => {
        if (document.documentElement.scrollTop > 50) {
          document.getElementById("back-to-top")?.classList.remove("fadeOut");
          document.getElementById("back-to-top")?.classList.add("fadeIn");
        } else {
          document.getElementById("back-to-top")?.classList.remove("fadeIn");
          document.getElementById("back-to-top")?.classList.add("fadeOut");
        }
      });
      // scroll body to 0px on click
      document.querySelector("#top").addEventListener("click", (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    }
  }, []);

  const [emailVerified, isGsm] = useOutletContext();

  const confirmEmailLink = async () => {
    if (isGsm) {
      setModalShow(true);
      return;
    }
    await Auth.verifyCurrentUserAttribute("email");
    navigate("/email-verify");
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Geçersiz e-posta adresi")
      .required("E-posta alanı gerekli"),
  });

  const onSubmit = async ({ email }) => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        email,
      });
      if (result) {
        navigate("/email-verify");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  return (
    <>
      {/* Modal Section */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalShow}
        onClose={() => setModalShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalShow}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Yeni e-posta adresini doğrulayın
            </Typography>
            <Box
              id="transition-modal-description"
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <CssTextField
                margin="normal"
                size="small"
                required
                fullWidth
                variant="outlined"
                id="email"
                label="E-Posta Adresi"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
              />
              <Grid container justifyContent={"flex-end"}>
                <Button
                  id="transition-modal-button"
                  disabled={!formik.isValid}
                  type="submit"
                  variant={"contained"}
                  sx={{
                    mt: 3,
                    mb: 2,
                    color: formik.isValid ? "#000" : "#ffde0075 !important",
                    border: formik.isValid
                      ? "inherit"
                      : "1px solid #ffde0075 !important",
                  }}
                >
                  Devam Et
                </Button>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* End Modal Section */}

      <Collapse in={!emailVerified}>
        <Alert severity={"warning"} sx={{ borderRadius: 0 }}>
          {!emailVerified ? (
            <Typography variant="subtitle1" sx={{ fontWeight: "normal" }}>
              BananaTiVi ayrıcalıklarından eksiksiz yararlanmaya devam etmek
              için lütfen{" "}
              <Button
                onClick={confirmEmailLink}
                sx={{
                  fontWeight: "700",
                  color: "#60513b",
                  p: 0,
                  minWidth: 0,
                  textTransform: "inherit",
                  "&:hover": {
                    textDecoration: "underline",
                    color: "#c9892e",
                  },
                }}
              >
                buraya
              </Button>{" "}
              tıklayarak e-posta adresinizi onaylayınız!
            </Typography>
          ) : null}
        </Alert>
      </Collapse>
      <Grid
        container
        component="main"
        sx={{
          minHeight: bgBackground ? (autoHeight ? "auto" : !emailVerified ? "94vh" : "100vh") : "auto",
          ...bgSettings,
        }}
      >
        <div id="back-to-top">
          <a className="top" to="#" id="top">
            <ArrowUpwardSharpIcon fontSize="medium" />
          </a>
        </div>
        {isAppbar ? (
          <Grid container sx={{ height: "10vh" }}>
            <ResponsiveAppBar />
          </Grid>
        ) : null}

        {children}
      </Grid>
    </>
  );
}
