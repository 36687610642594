import { API } from "aws-amplify";
import * as types from "./types";
import * as tMedia from "../media/types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get all ads */
export const getAds = () => async (dispatch) => {
  try {
    const listAdsQuery = API.graphql({
      query: queries.listAds,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page: 1, size: 100 } } },
    });

    const allAds = await listAdsQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allAds.data.me,
    });

    dispatch({
      type: types.GET_ADS,
      payload: allAds.data,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get all admin ads */
export const getAdminAds = () => async (dispatch) => {
  try {
    const listAdsQuery = API.graphql({
      query: queries.adminListAds,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page: 1, size: 100 } } },
    });

    const allAds = await listAdsQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allAds.data.me,
    });

    dispatch({
      type: types.GET_ADMIN_ADS,
      payload: allAds.data,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get single ad details */
export const getAd = (id) => async (dispatch) => {
  try {
    const getAdQuery = API.graphql({
      query: queries.getAd,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id } },
    });

    const singleAd = await getAdQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleAd.data.me,
    });

    dispatch({
      type: types.SINGLE_AD,
      payload: singleAd.data.getPublishedAd,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Trigger ad quiz answer */
export const answerAdQuiz =
  (adId, title, value, resCallback) => async (dispatch) => {
    try {
      const answerQuizQuery = API.graphql({
        query: mutations.answerAdQuiz,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: { adId, choice: title, choice_value: value } },
      });

      const answerRes = await answerQuizQuery;
      const data = answerRes.data.answerAdQuiz;

      resCallback(data.answeredRight, data.rewardedBananaAmount);

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Delete ad */
export const deleteAd = (id) => async (dispatch) => {
  try {
    const deleteAdQuery = API.graphql({
      query: mutations.deleteAd,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await deleteAdQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Create ad */
export const createAd =
  (
    videoMediaUrl,
    thumbnailMediaUrl,
    isPublished,
    bananaAmount,
    question,
    rightChoice,
    wrongChoices
  ) =>
  async (dispatch) => {
    try {
      const createAdQuery = API.graphql({
        query: mutations.createAd,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            videoMediaUrl,
            thumbnailMediaUrl,
            isPublished,
            bananaAmount,
            question,
            rightChoice,
            wrongChoices,
          },
        },
      });

      await createAdQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace("/admin/ads");
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Publish watch ad */
export const publishAd = (id) => async (dispatch) => {
  try {
    const publishAdQuery = API.graphql({
      query: mutations.updateAd,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
          isPublished: true,
        },
      },
    });

    await publishAdQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Unpublish watch ad */
export const unPublishAd = (id) => async (dispatch) => {
  try {
    const unPublishAdQuery = API.graphql({
      query: mutations.updateAd,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
          isPublished: false,
        },
      },
    });

    await unPublishAdQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get edit ad details */
export const getEditAd = (id) => async (dispatch) => {
  try {
    const getEditAdQuery = API.graphql({
      query: queries.getEditAd,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        ad: { id },
        media: {
          pagination: { page: 1, size: 100 },
          fileStatuses: ["DISTRIBUTED"],
          fileTypes: ["IMAGE", "VIDEO"],
        },
      },
    });

    const singleAd = await getEditAdQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleAd.data.me,
    });

    dispatch({
      type: tMedia.GET_MEDIAS,
      payload: singleAd.data,
    });

    dispatch({
      type: types.SINGLE_AD,
      payload: singleAd.data.adminGetAd,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Update watch ad */
export const updateAd =
  (
    id,
    videoMediaUrl,
    thumbnailMediaUrl,
    isPublished,
    bananaAmount,
    question,
    rightChoice,
    wrongChoices
  ) =>
  async (dispatch) => {
    try {
      const updateAdQuery = API.graphql({
        query: mutations.updateAd,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            id,
            videoMediaUrl,
            thumbnailMediaUrl,
            isPublished,
            bananaAmount,
            question,
            rightChoice,
            wrongChoices,
          },
        },
      });

      await updateAdQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace(`/admin/ads`);
    } catch (err) {
      console.error("error: ", err);
    }
  };
