import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  Grid,
} from "@mui/material";
import TagsInput from "../../../../../components/admin/tagsInput";
import {
  CssAutocomplete,
  CssTextField,
  CssFormControl,
} from "../../../../../styles/DefaultComponents";
import AdminTemplate from "../../../templates/global";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

const NewWatchAd = () => {
  const [fields, setFields] = useState({
    videoMediaUrl: "",
    thumbnailMediaUrl: "",
    isPublished: "",
    bananaAmount: "",
    question: "",
    rightChoice: "",
    wrongChoices: [],
  });
  const uiLoading = useSelector((state) => state.default.loading.container);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const medias = useSelector((state) => state.media.medias);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.media.getMedias(["IMAGE", "VIDEO"], ["DISTRIBUTED"]));
  }, [dispatch]);

  const imageURLS = [];
  const videoURLS = [];

  medias.map((media) => {
    if (media.type == "IMAGE") {
      imageURLS.push({ label: media.fileName, url: media.cdnUrl });
    } else {
      videoURLS.push({ label: media.fileName, url: media.cdnUrl });
    }
  });

  const handleSelectedTags = (items) => {
    setFields({ ...fields, wrongChoices: items });
  };

  const handleChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.watchAd.createAd(
        fields["videoMediaUrl"].url,
        fields["thumbnailMediaUrl"].url,
        fields["isPublished"],
        fields["bananaAmount"],
        fields["question"],
        fields["rightChoice"],
        fields["wrongChoices"]
      )
    );
  };

  return (
    <AdminTemplate title="New Watch Ad">
      <Container
        maxWidth="false"
        sx={{ maxWidth: "1950px" }}
        className="custom-popper"
      >
        <Typography variant="h4" gutterBottom>
          New Watch Ad
        </Typography>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={videoURLS}
                  value={fields["videoMediaUrl"]}
                  onChange={(_, value) => handleChange(value, "videoMediaUrl")}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Video Media URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={imageURLS}
                  onChange={(_, value) =>
                    handleChange(value, "thumbnailMediaUrl")
                  }
                  value={fields["thumbnailMediaUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Image Media URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  type="number"
                  value={fields["bananaAmount"]}
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  label="Bananas to be rewarded"
                  name="bananaAmount"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Publish</InputLabel>
                  <Select
                    label="Publish"
                    value={fields["isPublished"]}
                    name="isPublished"
                    onChange={(e) =>
                      setFields({ ...fields, [e.target.name]: e.target.value })
                    }
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(true)}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(false)}
                    >
                      No
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  value={fields["question"]}
                  label="Question"
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  name="question"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  value={fields["rightChoice"]}
                  label="Right Choice"
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  name="rightChoice"
                />
              </Grid>
              <Grid item xs={12} mt={1}>
                <TagsInput
                  selectedTags={handleSelectedTags}
                  tags={fields["wrongChoices"]}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                  name="wrongChoices"
                  placeholder="Press enter for each choice"
                  label="Wrong Choices"
                  multiline
                  rows={3}
                  primary
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={8}
              lg={4}
              mt={5}
              xl={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-end" },
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "20%" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  href="/admin/ads"
                >
                  Cancel
                </Button>
                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  fullWidth
                  type="submit"
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Stack>
            </Grid>
          </Box>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default NewWatchAd;
