//DEFAULT USER PROFILE
export const PROFILE_DEFAULT = "PROFILE_DEFAULT";
export const REFRESH_PROFILE = "REFRESH_PROFILE";

//DEFAULT LIST PAGINATION
export const LIST_PAGINATION = "LIST_PAGINATION";

// LOADING REQUESTS
export const LOADING_BUTTON_DONE = "LOADING_BUTTON_DONE";
export const LOADING_BUTTON_REQUEST = "LOADING_BUTTON_REQUEST";
export const UI_LOADING_DONE = "UI_LOADING_DONE";
export const UI_LOADING_REQUEST = "UI_LOADING_REQUEST";