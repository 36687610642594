import React, { useEffect, useState } from "react";
import GlobalTemplate from "../../templates/global";
import {
  Divider,
  Container,
  Chip,
  Backdrop,
  Typography,
  CircularProgress,
  InputAdornment,
  List,
  Button,
  Modal,
  Grid,
  Fade,
  Stack,
  Box,
} from "@mui/material";
import Moment from "react-moment";
import moment from "moment";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import * as actions from "../../../../store/actions";
import Details from "./components/details";
import CardDetails from "./components/cardDetails";
import CardDeleteModal from "./components/CardDeleteModal";
import PurchaseDetails from "./components/PurchaseDetails";
import { modalStyle, CssTextField } from "../../../../styles/DefaultComponents";
import PasswordChange from "./components/PasswordChange";

function Profile() {
  const [modalShow, setModalShow] = useState(false);
  const [passwordModalShow, setPassModalShow] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isProfileEdit, setProfileEdit] = useState(false);
  const [warningShow, setWarningShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cardData, setCardData] = useState({ id: "", name: "" });
  const [popupWindow, setPopupWindow] = useState(null);
  const [waitingSubscriptionDetail, setWaitingSubscriptionDetail] =
    useState(false);

  const [fields, setFields] = useState({
    given: "",
    family: "",
  });

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.default.profile);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const btnLoading = useSelector((state) => state.default.loading.button);

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.subscribe.getProfile());
  }, [dispatch]);

  useEffect(() => {
    setFields({
      given: profile?.given,
      family: profile?.family,
    });
  }, [profile]);

  const schema = yup.object().shape({
    gsm: yup
      .string()
      .required("Bir telefon numarası gerekli")
      .matches(/^5([0-9]){9}$/, "Geçerli telefon numarası girin"),
  });

  const onSubmit = ({ gsm }) => {
    try {
      openPopup();
      setWaitingSubscriptionDetail(true);
      setModalShow(false);
      dispatch(actions.subscribe.startSubscription(gsm));
      dispatch(actions.subscribe.getProfile());
      setWaitingSubscriptionDetail(false);
    } catch (error) {
      console.error(error);
      dispatch(actions.subscribe.getProfile());
      setWaitingSubscriptionDetail(false);
    }
  };

  const cancelSubscription = ({ id }) => {
    try {
      setWarningShow(false);
      setWaitingSubscriptionDetail(true);
      dispatch(actions.subscribe.cancelSubscription(id));
      dispatch(actions.subscribe.getProfile());
      setWaitingSubscriptionDetail(false);
    } catch (error) {
      console.error(error);
      dispatch(actions.subscribe.getProfile());
      setWaitingSubscriptionDetail(false);
    }
  };

  const handleProfileEdit = async () => {
    setButtonLoading(true);
    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        given_name: fields["given"],
        family_name: fields["family"],
      });
      if (result) {
        dispatch(actions.dActions.refreshMeQuery());
      }
      setProfileEdit(false);
    } catch (error) {
      console.error(error);
    }
    setButtonLoading(false);
  };

  const handlePasswordUpdate = async (
    values,
    { _, setFieldError, __, resetForm }
  ) => {
    setButtonLoading(true);
    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.changePassword(
        user,
        values.oldpassword,
        values.newpassword
      );
      if (result) {
        resetForm({});
        setPassModalShow(false);
      }
    } catch (err) {
      if (err.name === "NotAuthorizedException")
        setFieldError("oldpassword", "Yanlış eski şifre.");

      console.error(err);
    }
    setButtonLoading(false);
  };

  const deleteSavedCard = (id) => {
    try {
      dispatch(actions.dActions.buttonLoading());
      dispatch(actions.subscribe.deleteCard(id));
    } catch (error) {
      console.error(error);
    }
    setDeleteModal(false);
  };

  const openPopup = () => {
    if (popupWindow === null || popupWindow.closed) {
      let newPopupWindow = window.open("/paynet", "Paynet", "popup");
      setPopupWindow(newPopupWindow);
    } else {
      popupWindow.focus();
    }
  };

  const formik = useFormik({
    initialValues: {
      gsm: profile.gsm ? profile.gsm.slice(-10) : "",
    },
    validationSchema: schema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <GlobalTemplate
      bgLight={Boolean(true)}
      bgBackground={Boolean(true)}
      title="Profilim"
    >
      <CardDeleteModal
        open={deleteModal}
        modalStyle={modalStyle}
        data={cardData}
        modalClose={setDeleteModal}
        loading={btnLoading}
        deleteAction={deleteSavedCard}
      />
      {/* Modal Section */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalShow}
        onClose={() => setModalShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalShow}>
          <Box sx={modalStyle}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Yeni abonelik başlat
            </Typography>
            <Box
              id="transition-modal-description"
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <CssTextField
                margin="normal"
                size="small"
                required
                fullWidth
                variant="outlined"
                id="gsm"
                label="Telefon numarası"
                name="gsm"
                value={formik.values.gsm}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.gsm)}
                helperText={formik.errors.gsm}
                inputProps={{
                  maxLength: 10,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+90</InputAdornment>
                  ),
                }}
              />
              <Grid container justifyContent={"flex-end"}>
                <Button
                  id="transition-modal-button"
                  disabled={!formik.isValid}
                  type="submit"
                  variant={"contained"}
                  sx={{
                    mt: 3,
                    mb: 2,
                    color: formik.isValid ? "#000" : "#ffde0075 !important",
                    border: formik.isValid
                      ? "inherit"
                      : "1px solid #ffde0075 !important",
                  }}
                >
                  Devam Et
                </Button>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* End Modal Section */}

      {/* WarningModal Section */}
      <Modal
        aria-labelledby="transition-warningmodal-title"
        aria-describedby="transition-warningmodal-description"
        open={warningShow}
        onClose={() => setWarningShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={warningShow}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-warningmodal-title"
              variant="h6"
              component="h2"
            >
              Üyelik iptaline devam etmek istiyor musunuz?
            </Typography>
            <Typography id="transition-warningmodal-description" sx={{ mt: 2 }}>
              Üyeliğinizi iptal ettiğiniz taktirde içeriklerimize erişiminiz ve
              çekilişlere katılma hakkınız iptal edilecektir.
            </Typography>
            <Grid container sx={{ mt: 2 }} spacing={5}>
              <Grid item xs={12} sm={6}>
                <Button
                  id="transition-modal-button"
                  fullWidth
                  variant={"outlined"}
                  onClick={(e) => {
                    e.preventDefault();
                    setWarningShow(false);
                  }}
                >
                  Vazgeç
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button
                  id="transition-modal-button"
                  fullWidth
                  variant={"contained"}
                  onClick={(e) => {
                    e.preventDefault();
                    cancelSubscription({ id: profile.subscription?.id });
                    setWarningShow(false);
                  }}
                >
                  İptal Et
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      {/* End WarningModal Section */}

      {/* Password Change Modal Section */}
      <PasswordChange
        modalShow={passwordModalShow}
        buttonLoading={buttonLoading}
        setModal={setPassModalShow}
        submit={handlePasswordUpdate}
      />
      {/* End Password Change Modal Section */}

      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          background: "rgb(0, 0, 0)",
          boxShadow: "rgb(0 0 0) 0px 0px 5px 5px",
          borderRadius: "5px",
          p: 5,
          my: 10,
          mx: { xs: 7, md: "auto" },
          height: "fit-content",
        }}
      >
        <Divider light flexItem>
          <Chip label="Kişisel Bilgiler" color="primary" variant="outlined" />
        </Divider>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "20vh" }}
          >
            <CircularProgress color="primary" size={35} />
          </Grid>
        ) : (
          <>
            {isProfileEdit ? (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: { xs: "100%", md: "30%" },
                  float: { xs: "none", md: "right" },
                  pt: { xs: 3, md: 0 },
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={() => {
                    setFields({
                      given: profile?.given,
                      family: profile?.family,
                    });
                    setProfileEdit(false);
                  }}
                >
                  İptal
                </Button>

                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  fullWidth
                  onClick={handleProfileEdit}
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Güncelle"
                  )}
                </Button>
              </Stack>
            ) : (
              <>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    width: { xs: "100%", md: "30%" },
                    float: { xs: "none", md: "right" },
                  }}
                >
                  <Button
                    variant="text"
                    sx={{ p: { xs: "6px 2px", md: "6px 8px" } }}
                    onClick={() => setPassModalShow(true)}
                  >
                    Parola değişimi
                  </Button>
                  <Button
                    variant="text"
                    sx={{ p: { xs: "6px 2px", md: "6px 8px" } }}
                    onClick={() => setProfileEdit(true)}
                  >
                    Profili Düzenle
                  </Button>
                </Stack>
              </>
            )}

            <Details
              email={profile.email}
              phone={profile.gsm}
              given={profile.given}
              family={profile.family}
              fields={fields}
              handleEdit={setFields}
              isEdit={isProfileEdit}
            />
          </>
        )}

        {profile.cards?.length > 0 && (
          <>
            <Divider light flexItem>
              <Chip
                label="Kayıtlı Kartlar"
                color="primary"
                variant="outlined"
              />
            </Divider>

            {uiLoading ? (
              <Grid
                container
                component="main"
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "20vh" }}
              >
                <CircularProgress color="primary" size={35} />
              </Grid>
            ) : (
              <List>
                {profile.cards?.map((card, i) => (
                  <CardDetails
                    data={card}
                    key={i}
                    setData={setCardData}
                    deleteAction={setDeleteModal}
                  />
                ))}
              </List>
            )}
          </>
        )}

        <Divider light flexItem>
          <Chip
            label="Abonelik Ayrıntıları"
            color="primary"
            variant="outlined"
          />
        </Divider>

        {waitingSubscriptionDetail || uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "20vh" }}
          >
            <CircularProgress color="primary" size={35} />
          </Grid>
        ) : (
          <>
            {/* NO SUBSCRIPTION */}
            {profile.subscription === null ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ my: 2 }}
                >
                  Geçerli aboneliğiniz bulunmamaktadır
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h2"
                  sx={{ mb: 5 }}
                >
                  Bir abonelik başlatmak için tıklayınız.
                </Typography>

                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={(e) => {
                    e.preventDefault();
                    setModalShow(true);
                  }}
                >
                  Premium abonelik
                </Button>
              </>
            ) : null}

            {/* CANCELLED */}
            {profile.subscription !== null &&
            profile.subscription?.status === "CANCELLED" ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ my: 2 }}
                >
                  Aboneliğiniz iptal edilmiştir.
                </Typography>
                {moment(profile.subscription?.validBefore).isAfter(
                  moment.now()
                ) ? (
                  <Typography
                    variant="subtitle1"
                    color="#ffffff61"
                    component="h1"
                    sx={{ mb: 2 }}
                  >
                    <Moment local format="DD/MM/YYYY">
                      {profile.subscription?.validBefore}
                    </Moment>{" "}
                    tarihine kadar içeriklere erişiminiz devam edecektir.
                  </Typography>
                ) : null}
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ mb: 5 }}
                >
                  Yeni bir abonelik başlatmak için tıklayınız.
                </Typography>
                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={(e) => {
                    e.preventDefault();
                    setModalShow(true);
                  }}
                >
                  Premium abonelik
                </Button>
              </>
            ) : null}

            {/* EXPIRED SUBSCRIPTION */}
            {profile.subscription !== null &&
            profile.subscription?.validBefore != null &&
            profile.subscription?.paymentType !== "MOBILE" &&
            moment(profile.subscription?.validBefore).isBefore(moment.now()) ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ my: 2 }}
                >
                  Aboneliğiniz sona ermiştir.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ mb: 5 }}
                >
                  Yeni bir abonelik başlatmak için tıklayınız
                </Typography>

                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={(e) => {
                    e.preventDefault();
                    setModalShow(true);
                  }}
                >
                  Premium abonelik
                </Button>
              </>
            ) : null}

            {/* ACTIVE WITH VALID DATE OF VALIDBEFORE */}
            {profile.subscription !== null &&
            profile.subscription?.status === "ACTIVE" &&
            profile.subscription?.paymentType !== "MOBILE" &&
            profile.subscription?.validBefore != null &&
            moment(profile.subscription?.validBefore).isAfter(moment.now()) ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ my: 2 }}
                >
                  Aboneliğiniz aktiftir.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ mb: 5 }}
                >
                  Aboneliğiniz{" "}
                  <Moment local format="DD/MM/YYYY">
                    {profile.subscription?.validBefore}
                  </Moment>{" "}
                  tarihinde yenilenecektir. *
                </Typography>
                <a
                  id="profileLink"
                  onClick={(e) => {
                    e.preventDefault();
                    setWarningShow(true);
                  }}
                >
                  * Yenilemeyi durdur
                </a>
              </>
            ) : null}

            {/* ACTIVE BUT VALIDBEFORE IS NULL */}
            {profile.subscription !== null &&
            profile.subscription?.status === "ACTIVE" &&
            profile.subscription?.validBefore == null ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ my: 2 }}
                >
                  Aboneliğiniz etkinleştirildi, ancak ödeme sağlayıcısından onay
                  bekleniyor. Lütfen kısa bir süre sonra tekrar kontrol ediniz.
                </Typography>
              </>
            ) : null}

            {/* ACTIVE SUBSCRIPTION FROM MOBILE */}
            {profile.subscription !== null &&
            profile.subscription?.status === "ACTIVE" &&
            profile.subscription?.paymentType === "MOBILE" &&
            profile.subscription?.validBefore != null ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ my: 2 }}
                >
                  Üyeliğinizi iptal etmek için “iptal banana” yazıp 6464'e
                  gönderiniz.
                </Typography>
              </>
            ) : null}

            {/* WAITING_USER_ACTION or PROCESSING */}
            {profile.subscription !== null &&
            profile.subscription?.paymentType !== "MOBILE" &&
            (profile.subscription?.status === "WAITING_USER_ACTION" ||
              profile.subscription?.status === "PROCESSING") ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ my: 2 }}
                >
                  Devam eden abonelik başvurunuz bulunmakta.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#ffffff61"
                  component="h1"
                  sx={{ mb: 5 }}
                >
                  Başvurunuzu tamamlamak için kart bilgilerinizi girerek devam
                  edebilir ya da mevcut başvurunuzu iptal edip yeniden
                  başvurabilirsiniz.
                </Typography>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant={"contained"}
                      onClick={(e) => {
                        e.preventDefault();
                        openPopup();
                      }}
                    >
                      Kart bilgilerimi gir
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant={"outlined"}
                      onClick={(e) => {
                        e.preventDefault();
                        cancelSubscription({
                          id: profile.subscription?.id,
                        });
                      }}
                    >
                      Başvurumu iptal et
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </>
        )}

        {profile.purchases?.length > 0 && (
          <>
            <Divider light flexItem sx={{ mt: 3 }}>
              <Chip
                label="Satın alma geçmişi"
                color="primary"
                variant="outlined"
              />
            </Divider>

            {uiLoading ? (
              <Grid
                container
                component="main"
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ height: "20vh" }}
              >
                <CircularProgress color="primary" size={35} />
              </Grid>
            ) : (
              <List dense sx={{ px: {xs: 0, md: 5} }}>
                {profile.purchases?.map((purchase, i) => (
                  <PurchaseDetails data={purchase} key={i} />
                ))}
              </List>
            )}
          </>
        )}
      </Container>
    </GlobalTemplate>
  );
}

export default Profile;
