import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GlobalTemplate from "../../../templates/global";
import Vimeo from "@u-wave/react-vimeo";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import LockIcon from "@mui/icons-material/Lock";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

// ----------------------------------------------------------------------

const ProgramImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

const EpisodeImgStyle = styled("img")({
  width: "100%",
});

// ----------------------------------------------------------------------

function Program() {
  const [activeEpisode, setActive] = useState({
    isPlaying: false,
    episode: null,
  });

  const program = useSelector((state) => state.program.program);
  const episodes = useSelector((state) =>
    state.program.program?.episodes?.sort(
      (a, b) => Number(b.isTrailer) - Number(a.isTrailer)
    )
  );
  const sub = useSelector((state) => state.default.profile.subscription);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const dispatch = useDispatch();

  let { program_id, episode_id } = useParams();

  const player = useRef();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.program.getProgram(program_id));
  }, [dispatch]);

  useEffect(() => {
    if (episodes?.length > 0) {
      if (episode_id == 0) {
        setActive({
          episode: episodes[0],
          isPlaying: false,
        });

        window.history.replaceState(
          null,
          "",
          `/program/${program_id}/episode/${episodes[0].id}`
        );
        return;
      }

      for (let i = 0; i < episodes?.length; i++) {
        if (episodes[i]?.id == episode_id) {
          checkPermission(episodes[i]);
          setActive({
            episode: episodes[i],
            isPlaying: false,
          });
        }
      }
    }
  }, [program]);

  const checkPermission = (episode) => {
    if (!episode?.isPublic) {
      if (
        sub == null ||
        !moment(sub?.validBefore).isValid() ||
        moment(sub?.validBefore).isBefore(moment.now())
      ) {
        window.location.replace(`/plans`);
        return;
      }
    }
  };

  const goToTop = (program_id, episode_id) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    window.history.replaceState(
      null,
      "",
      `/program/${program_id}/episode/${episode_id}`
    );
  };

  const startPlayer = () => {
    setActive({ episode: episodes[0], isPlaying: true });
    checkPermission(episodes[0]);
    goToTop(program?.id, episodes[0].id);
  };

  const renderMaturityIcons = (icons) => {
    let images = [];

    for (let i = 0; i < icons?.maturityIcons?.length; i++) {
      images.push(
        <Grid key={`icons${i}`} item xs={2} sm={1} md={2}>
          <Box sx={{ pt: "100%", position: "relative" }}>
            <ProgramImgStyle
              src={
                "/assets/client/images/" +
                icons.maturityIcons[i].toLowerCase() +
                ".png"
              }
              alt={icons.maturityIcons[i]}
            />
          </Box>
        </Grid>
      );
    }
    return images;
  };

  const renderCast = (episode) => {
    let cast = [];

    for (let i = 0; i < episode?.cast?.length; i++) {
      cast.push(
        <Typography
          key={i}
          variant="body1"
          align="left"
          color="text.primary"
          component="h6"
          sx={{ fontWeight: "lighter" }}
        >
          {episode.cast[i]}
        </Typography>
      );
    }
    return cast;
  };

  const renderEpisodes = () => {
    let images = [];

    for (let i = 0; i < episodes?.length; i++) {
      images.push(
        <Grid key={`episode${i}`} item xs={12} sm={6} md={3}>
          <Card
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
              bgcolor:
                activeEpisode?.episode === episodes[i]
                  ? "#ffe53917"
                  : "#cdc8c82e",
              borderRadius: activeEpisode?.episode === episodes[i] ? "4px" : 0,
            }}
            onClick={() => {
              checkPermission(episodes[i]);
              setActive({ episode: episodes[i], isPlaying: true });
              goToTop(program?.id, episodes[i].id);
            }}
          >
            <Box>
              <EpisodeImgStyle
                alt={episodes[i].title}
                src={episodes[i].videoThumbnailImageMediaUrl}
              />
            </Box>

            <Stack spacing={2} sx={{ p: 1 }}>
              <Typography variant="subtitle2" noWrap>
                {episodes[i].isTrailer
                  ? `${episodes[i].title} (Fragman)`
                  : episodes[i].title}
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ pb: 3 }}
              >
                <Typography noWrap color="primary" variant="body2">
                  {program.title}
                </Typography>
                {!episodes[i].isTrailer && !episodes[i].isPublic ? (
                  sub == null ||
                  !moment(sub?.validBefore).isValid() ||
                  moment(sub?.validBefore).isBefore(moment.now()) ? (
                    <LockIcon color="primary" sx={{ fontSize: "18px" }} />
                  ) : null
                ) : null}
              </Stack>
            </Stack>
          </Card>

          <Typography variant="subtitle2" sx={{ p: 1 }}>
            {episodes[i].description}
          </Typography>
        </Grid>
      );
    }
    return images;
  };

  return (
    <GlobalTemplate title={program.title ? program.title : "Yükleniyor..."}>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "100%",
          px: { xs: 2, md: 0 },
          pb: { md: 10 },
        }}
      >
        <Box
          sx={{
            minHeight: { xs: "15vh", md: "30vh" },
            my: { xs: 4, md: 6, lg: 10 },
          }}
        >
          {activeEpisode.episode?.videoMediaUrl ? (
            <Vimeo
              ref={player}
              video={activeEpisode.episode?.videoMediaUrl}
              className="banana-player"
              color="ffde00"
              height="564"
              onLoaded={() =>
                activeEpisode.isPlaying ? player.current.player.play() : null
              }
            />
          ) : (
            <Skeleton
              sx={{
                bgcolor: "#101010",
                width: { xs: "95vw", md: "90vw", xl: "80vw" },
                height: { xs: "30vh", sm: "45vh", md: "50vh", lg: "60vh" },
              }}
              variant="rectangular"
            />
          )}
        </Box>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "22px" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid container spacing={3} sx={{ px: { sm: 5, md: 6, lg: 0 } }}>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="h5"
                  align="left"
                  color="text.primary"
                  component="h3"
                  sx={{ mb: 1 }}
                >
                  {program?.categoryDisplayName}
                </Typography>

                <Grid container spacing={3}>
                  {renderMaturityIcons(activeEpisode.episode)}
                </Grid>

                <Typography
                  variant="h6"
                  align="left"
                  color="text.primary"
                  component="h4"
                  sx={{ mt: 5 }}
                >
                  Oyuncular
                </Typography>
                {renderCast(activeEpisode.episode)}
              </Grid>

              <Grid item xs={12} md={9}>
                <Typography
                  variant="h6"
                  align="left"
                  color="text.primary"
                  component="h4"
                >
                  Tanım
                </Typography>

                <Typography
                  variant="h6"
                  align="left"
                  color="text.primary"
                  component="p"
                  sx={{ mb: 5, fontWeight: "lighter" }}
                >
                  {activeEpisode.episode?.description}
                </Typography>
                <Button variant="contained" onClick={startPlayer}>
                  İzlemeye Başla
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{ px: { sm: 5, md: 6, lg: 0 }, py: 5 }}
            >
              {renderEpisodes()}
            </Grid>
          </>
        )}
      </Container>
    </GlobalTemplate>
  );
}

export default Program;
