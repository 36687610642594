import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Container, Grid, Typography, Button, CircularProgress } from "@mui/material";
import AdminTemplate from "../../../templates/global";
import ProgramCard from "./components/ProgramCard";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

const AdminProgram = () => {
  const programs = useSelector((state) => state.program.programs);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.program.getPrograms());
  }, [dispatch]);

  return (
    <AdminTemplate title="Program">
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            All Programs
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/admin/program/new"
            startIcon={<AddIcon />}
          >
            New Program
          </Button>
        </Stack>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            {programs?.map((progr) => (
              <Grid key={progr.id} item xs={12} sm={6} md={3}>
                <ProgramCard progr={progr} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default AdminProgram;
