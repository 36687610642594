import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Typography,
  Container,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  Grid,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import {
  CssTextField,
  CssFormControl,
  CssAutocomplete,
} from "../../../../../styles/DefaultComponents";

const NewRaffle = () => {
  const [fields, setFields] = useState({
    title: "",
    description: "",
    promoUrl: "",
    posterUrl: "",
    startTime: dayjs().toISOString(),
    endTime: dayjs().toISOString(),
    sponsorUrl: "",
    isPublic: "",
    isPublished: "",
  });
  const uiLoading = useSelector((state) => state.default.loading.container);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const medias = useSelector((state) => state.media.medias);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.media.getMedias(["IMAGE", "VIDEO"], ["DISTRIBUTED"]));
  }, [dispatch]);

  const imageURLS = [];
  const videoURLS = [];

  medias.map((media) => {
    if (media.type == "IMAGE") {
      imageURLS.push({ label: media.fileName, url: media.cdnUrl });
    } else {
      videoURLS.push({ label: media.fileName, url: media.cdnUrl });
    }
  });

  const handleChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.raffle.createRaffle(
        fields["description"],
        fields["endTime"],
        0,
        fields["isPublic"],
        fields["isPublished"],
        fields["promoUrl"].url,
        fields["sponsorUrl"].url,
        fields["startTime"],
        fields["title"],
        fields["posterUrl"].url
      )
    );
  };

  return (
    <AdminTemplate title="New Raffle">
      <Container
        maxWidth="false"
        sx={{ maxWidth: "1950px" }}
        className="custom-popper"
      >
        <Typography variant="h4" gutterBottom>
          New Raffle
        </Typography>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  id="title"
                  value={fields["title"]}
                  label="Title"
                  name="title"
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={imageURLS}
                  onChange={(_, value) => handleChange(value, "posterUrl")}
                  value={fields["posterUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Poster Image URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={videoURLS}
                  onChange={(_, value) => handleChange(value, "promoUrl")}
                  value={fields["promoUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Promo Video URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={imageURLS}
                  onChange={(_, value) => handleChange(value, "sponsorUrl")}
                  value={fields["sponsorUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Sponsor Image URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Public</InputLabel>
                  <Select
                    label="Public"
                    value={fields["isPublic"]}
                    name="isPublic"
                    onChange={(e) =>
                      setFields({ ...fields, [e.target.name]: e.target.value })
                    }
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(true)}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(false)}
                    >
                      No
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Publish</InputLabel>
                  <Select
                    label="Publish"
                    value={fields["isPublished"]}
                    name="isPublished"
                    onChange={(e) =>
                      setFields({ ...fields, [e.target.name]: e.target.value })
                    }
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(true)}
                    >
                      Yes
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={Boolean(false)}
                    >
                      No
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    label="Start Time Date"
                    value={fields["startTime"]}
                    onChange={(value) =>
                      handleChange(value.toISOString(), "startTime")
                    }
                    renderInput={(params) => (
                      <CssTextField
                        margin="normal"
                        size="small"
                        fullWidth
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    label="End Time Date"
                    value={fields["endTime"]}
                    onChange={(value) =>
                      handleChange(value.toISOString(), "endTime")
                    }
                    renderInput={(params) => (
                      <CssTextField
                        margin="normal"
                        size="small"
                        fullWidth
                        required
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <CssTextField
              margin="normal"
              size="small"
              fullWidth
              required
              variant="outlined"
              label="Description"
              placeholder="Raffle description..."
              value={fields["description"]}
              multiline
              rows={4}
              onChange={(e) =>
                setFields({ ...fields, [e.target.name]: e.target.value })
              }
              name="description"
            />
            <Grid
              item
              md={8}
              lg={4}
              mt={5}
              xl={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-end" },
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "20%" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  href="/admin/raffle/display"
                >
                  Cancel
                </Button>
                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  fullWidth
                  type="submit"
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Stack>
            </Grid>
          </Box>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default NewRaffle;
