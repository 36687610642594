import React from "react";
import Slider from "react-slick";
import { Link, CardMedia, Box } from "@mui/material";

const BannerList = ({ banner, keyIndex }) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    lazyLoad: "progressive",
    className: "banana-slider"
  };
 
  const renderImageBanner = () => {
    let images = [];

    for (let i = 0; i < banner.length; i++) {
      images.push(
        <Box
          key={`${keyIndex}trailer${i}`}
          sx={{
            backgroundColor: "#101010",
            position: "relative",
            minHeight: { xs: "15vh", md: "25vh" },
            width: "100%",
            mb: { xs: 2, md: 0 },
            mt: { xs: 2, md: 0 },
          }}
        >
          <Link href={ banner[i].episodesCount < 1 ? '/coming' : `/program/${banner[i].id}/episode/0`}>
            <CardMedia
              component="img"
              image={banner[i].bannerImageMediaUrl}
              alt={banner[i].title}
              sx={{
                height: { xs: "auto", md: "545px", lg: "690px", xl: "700px" },
                objectFit: "contain",
                backgroundSize: "contain",
              }}
            />
          </Link>
        </Box>
      );
    }
    return images;
  };

  return <Slider {...settings}>{renderImageBanner()}</Slider>;
};

export default BannerList;
