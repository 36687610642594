import { useRef, useState } from "react";
import Vimeo from "@u-wave/react-vimeo";

export default function RafflePlayer(props) {
  const { onEnd = () => {}, ...parentProps } = props;
  const player = useRef(null);

  const [maxTime, setMaxTime] = useState(0);

  const onSeeked = function (data) {
    if (data.seconds > maxTime) {
      player.current.player.setCurrentTime(maxTime);
    }
  };

  const onTimeUpdate = function (data) {
    if (maxTime > data.seconds - 1 && maxTime < data.seconds) {
      setMaxTime(data.seconds);
    }
  };

  const onEndHandler = function (data) {
    const previousTime = maxTime;

    if (maxTime < data.seconds - 1) {
      player.current.player.loadVideo(props.video).then((id) => {
        player.current.player
          .play()
          .then(() =>
            player.current.player
              .setCurrentTime(previousTime - 1)
              .then((seconds) => {})
          );
      });
    } else {
      return onEnd(data);
    }
  };

  return (
    <Vimeo
      ref={player}
      {...parentProps}
      onTimeUpdate={onTimeUpdate}
      onSeeked={onSeeked}
      onEnd={onEndHandler}
    />
  );
}
