import React, { useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  Stack,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import AddIcon from "@mui/icons-material/Add";
import WebAssetOffIcon from "@mui/icons-material/WebAssetOff";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import SectionAreaDrop from "./components/SectionAreaDrop";
import ProgramSearch from "./components/ProgramSearch";
import ProgramAreaDrop from "./components/ProgramAreaDrop";
import SectionModal from "./components/SectionModal";

const ProgramSections = () => {
  const uiLoading = useSelector((state) => state.default.loading.container);
  const APISections = useSelector((state) => state.program.listings);
  const [fields, setFields] = useState({ programs: [], title: "", type: "" });
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [listings, updateListings] = useState([]);
  const [programs, updatePrograms] = useState([]);
  const [sectionId, updateSectionId] = useState({ id: null, title: null });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.program.getSections());
  }, [dispatch]);

  useEffect(() => {
    updateListings(APISections);
  }, [APISections]);

  const handleChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const handleListingOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(listings);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updateListings(items);
    dispatch(
      actions.program.reorderProgramSection(items.map((item) => item.id))
    );
  };

  const handleProgramsOnDragEnd = (result) => {
    if (!result.destination) {
      const copyItems = Array.from(programs);
      const copyReorderedItem = copyItems
        .slice(0, result.source.index)
        .concat(copyItems.slice(result.source.index + 1, copyItems.length));

      updatePrograms(copyReorderedItem);
      dispatch(
        actions.program.reorderProgramsInSection(
          sectionId["id"],
          copyReorderedItem.map((item) => item.id)
        )
      );
      return;
    }
    const items = Array.from(programs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    updatePrograms(items);
    dispatch(
      actions.program.reorderProgramsInSection(
        sectionId["id"],
        items.map((item) => item.id)
      )
    );
  };

  const handleNewSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.program.createProgramSection(
        fields["title"],
        fields["type"],
        APISections?.length + 1
      )
    );
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.program.updateProgramSection(
        fields["title"],
        fields["type"],
        fields["id"]
      )
    );
  };

  const handleSectionEdit = (title, type, id) => {
    setFields({ ...fields, title, type, id });
    setEditModal(true);
  };

  const handleDelete = (id) => {
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.program.deleteProgramSection(id));
  };

  const handleAddPrograms = () => {
    const newPrograms = fields["programs"]?.map((item) => item.id);
    const combinedPrograms = [
      ...programs.map((item) => item.id),
      ...newPrograms,
    ];
    dispatch(actions.dActions.buttonLoading());
    const APIPrograms = dispatch(
      actions.program.addProgramsSection(sectionId["id"], combinedPrograms)
    );
    APIPrograms.then((json) => {
      updatePrograms(json.items);
      handleChange([], "programs");
    });
  };

  return (
    <AdminTemplate title="Program Sections">
      <SectionModal
        name="create"
        open={newModal}
        modalClose={setNewModal}
        handleChange={handleChange}
        handleSubmit={handleNewSubmit}
        title={fields["title"]}
        type={fields["type"]}
      />
      <SectionModal
        name="edit"
        open={editModal}
        modalClose={setEditModal}
        handleChange={handleChange}
        handleSubmit={handleEditSubmit}
        title={fields["title"]}
        type={fields["type"]}
      />
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Program Section
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setFields({ programs: [], title: "", type: "" });
              setNewModal(true);
            }}
            startIcon={<AddIcon />}
          >
            New Section
          </Button>
        </Stack>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={5}>
              <DragDropContext onDragEnd={handleListingOnDragEnd}>
                <SectionAreaDrop
                  list={listings}
                  handlePreview={updatePrograms}
                  resetPrograms={handleChange}
                  sectionId={updateSectionId}
                  handleEdit={handleSectionEdit}
                  handleDelete={handleDelete}
                />
              </DragDropContext>
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Card
                sx={{
                  width: "100%",
                  bgcolor: "#ffde0030",
                }}
              >
                <CardContent>
                  <Typography variant="h5">
                    Program Preview{" "}
                    {sectionId["title"] && `- ${sectionId["title"]}`}
                  </Typography>

                  <ProgramSearch
                    id={sectionId["id"]}
                    handleAddPrograms={handleAddPrograms}
                    handleChange={handleChange}
                    selectedPrograms={fields["programs"]}
                  />

                  {programs.length > 0 ? (
                    <DragDropContext onDragEnd={handleProgramsOnDragEnd}>
                      <ProgramAreaDrop list={programs} />
                    </DragDropContext>
                  ) : (
                    <Grid
                      container
                      component="div"
                      flexDirection={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{ height: "40vh" }}
                    >
                      <Typography sx={{ fontSize: "5rem" }} color="#ffffff61">
                        <WebAssetOffIcon fontSize="inherit" color="inherit" />
                      </Typography>

                      <Typography variant="h6">Nothing to preview!</Typography>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default ProgramSections;
