import { combineReducers } from "redux";
import defaultReducer from "./default/reducer";
import raffleReducer from "./states/raffle/reducers";
import programReducer from "./states/program/reducers";
import mediaReducer from "./states/media/reducers";
import influencerReducer from "./states/influencer/reducers";
import userReducer from "./states/user/reducers";
import episodeReducer from "./states/episode/reducers";
import purchaseReducer from "./states/purchase/reducers";
import adReducer from "./states/ad/reducers";
import statisticsReducer from "./states/statistics/reducers";

export default combineReducers({
  default: defaultReducer,
  raffle: raffleReducer,
  program: programReducer,
  media: mediaReducer,
  influencer: influencerReducer,
  user: userReducer,
  purchase: purchaseReducer,
  episode: episodeReducer,
  ad: adReducer,
  statistics: statisticsReducer,
});
