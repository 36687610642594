import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthTemplate from "../../../templates/auth";
import RecoverUi from "./components/recover_ui";
import CfRecoverUi from "./components/cfrecover_ui";
import { Typography } from "@mui/material";
import "../../../../../config";
import { API_URL } from "../../../../../config";
import { Auth } from "aws-amplify";

function Recover() {
  const [signed_user, setUser] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [uiTemplate, setUiTemplate] = useState("recover");
  const navigate = useNavigate();

  const onSubmit = async (values, { _, setFieldError, __ }) => {
    setLoading(true);
    const isValidEmail = /\S+@\S+\.\S+/.test(values.email);
    if (!isValidEmail && values.email != null) {
      try {
        let headers = new Headers();

        headers.append("Content-Type", "application/json");

        const gsmLookupRequest = await fetch(API_URL, {
          headers,
          method: "POST",
          body: JSON.stringify({ gsm: values.email }),
        });
        const data = await gsmLookupRequest.json();

        if (gsmLookupRequest.status == 400) {
          setFieldError("email", "Telefon numaranız +90 ile başlamalıdır…");
          console.error("Error", data.message);
          setLoading(false);
          return;
        }

        awsAuthForgotPass(
          data.userId,
          values.code,
          values.password,
          setFieldError
        );
      } catch (e) {
        setFieldError("email", "Kullanıcı bulunamadı.");
        setLoading(false);
        console.error("Error", e);
      }

      return;
    }
    awsAuthForgotPass(
      values.email,
      values.code,
      values.password,
      setFieldError
    );
  };

  const awsAuthForgotPass = async (email, code, pass, setFieldError) => {
    try {
      if (uiTemplate === "recover") {
        const user = await Auth.forgotPassword(email);

        if (user) {
          setUiTemplate("cfRecover");
          setUser(email);
        }
      } else {
        await Auth.forgotPasswordSubmit(signed_user, code, pass);
        navigate('/auth/signin');
      }
    } catch (err) {
      if (err.name === "UserNotFoundException")
        setFieldError("email", "Kullanıcı bulunamadı");

      if (err.name === "CodeMismatchException")
        setFieldError("code", "Geçersiz doğrulama kodu");

      if (err.name === "LimitExceededException")
        setFieldError(
          "email",
          "Deneme limiti aşıldı, lütfen bir süre sonra tekrar deneyin."
        );

      console.error("Error", err);
    }
    setLoading(false);
  };

  return (
    <AuthTemplate>
      <Typography component="h1" variant="h5">
        {uiTemplate === "recover"
          ? "Parolanızı mı unuttunuz ?"
          : "Parola kurtarmayı onayla"}
      </Typography>

      {uiTemplate === "recover" ? (
        <RecoverUi submit={onSubmit} loading={isLoading} />
      ) : (
        <CfRecoverUi submit={onSubmit} loading={isLoading} />
      )}
    </AuthTemplate>
  );
}

export default Recover;
