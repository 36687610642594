import * as types from "./types";

const initialState = {
  medias: [],
  uploadLoading: false,
  progressValue: 0,
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MEDIAS:
      const { listMediaFiles } = action.payload;
      return {
        ...state,
        medias: listMediaFiles.items,
      };

    case types.PROGRESS_VALUE_UPDATE:
      return { ...state, progressValue: action.payload };

    case types.PROGRESS_VALUE_RESET:
      return { ...state, progressValue: 0 };

    case types.LOADING_UPLOAD_DONE:
      return { ...state, uploadLoading: false };

    case types.LOADING_UPLOAD_REQUEST:
      return { ...state, uploadLoading: true };

    default:
      return state;
  }
};

export default mediaReducer;
