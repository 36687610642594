import React from "react";
import { Box, Card, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Label from "../../../../../../components/admin/label";
import PolicyIcon from "@mui/icons-material/Policy";
import RaffleMoreMenu from "./RaffleMoreMenu";

// ----------------------------------------------------------------------

const RaffleImgStyle = styled("img")({
  width: "100%",
});

// ----------------------------------------------------------------------

const RaffleCard = ({ raffle }) => {
  const { id, title, verticalPosterImageMediaUrl, isPublic } = raffle;
  return (
    <Card sx={{ bgcolor: "#ffde004d" }}>
      <Box>
        <RaffleImgStyle alt={title} src={verticalPosterImageMediaUrl} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>

          <RaffleMoreMenu name={title} id={id} />
        </Stack>

        <Label
          variant={!isPublic ? "outlined" : "filled"}
          color={"warning"}
          sx={{ width: "fit-content" }}
          startIcon={<PolicyIcon />}
        >
          {!isPublic ? "Private" : "Public"}
        </Label>
      </Stack>
    </Card>
  );
};

export default RaffleCard;
