import * as types from "./types";

const initialState = {
  episodes: [],
  episode: {},
};

const influencerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_EPISODES:
      const { listEpisodes } = action.payload;
      return {
        ...state,
        episodes: listEpisodes.items,
      };

    case types.SINGLE_EPISODE:
      const { getEpisode } = action.payload;
      return {
        ...state,
        episode: getEpisode,
      };

    default:
      return state;
  }
};

export default influencerReducer;
