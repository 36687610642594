import React, { useEffect } from "react";
import GlobalTemplate from "../../templates/global";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CardMedia } from "@mui/material";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions";

function About() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.defaultAction());
  }, [dispatch]);

  return (
    <GlobalTemplate
      bgBackground={Boolean(true)}
      title="Hakkimizda"
    >
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CardMedia
          component="img"
          image="/logo.png"
          alt="Bananativi logo"
          height="auto"
          sx={{
            width: "40%",
          }}
        />
        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          component="h3"
          sx={{ mt: 4, mb: 8 }}
        >
          7 yıllık bir geçmişe sahip, yüzlerce eğlenceli yerel içerik
          yayınlayan, Kıbrıs kültürünün samimi ruhunu doyasıya yaşatan bir
          sosyal medya kanalı olan Banana TV, Türkiye’den ve Yurtdışından
          binlerce kişi tarafından sevilerek takip edilmektedir.
        </Typography>

        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          component="h4"
          sx={{ mb: 8 }}
        >
          Banana TV artık{" "}
          <span style={{ color: "#ffde00" }}>www.bananativi.com</span> ile
          Kıbrıs’ın ilk dijital eğlence platformu ünvanına kavuşmuştur.
        </Typography>

        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          component="h5"
        >
          www.bananativi.com
        </Typography>

        <Typography
          variant="h5"
          align="center"
          color="text.primary"
          component="h6"
        >
          Bir BNT Medya Prodüksiyon Ltd. Şti. markasıdır.
        </Typography>

        <Typography
          variant="subtitle1"
          align="center"
          color="#ffffff75"
          component="p"
          sx={{ mt: 4 }}
        >
          copyright © {new Date().getFullYear()} tüm hakları saklıdır.
        </Typography>
      </Container>
    </GlobalTemplate>
  );
}

export default About;
