import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { Grid } from "@mui/material";
import ProgramCard from "./ProgramCard";

export default function ProgramAreaDrop({ list }) {
  return (
    <Droppable droppableId="programSections" direction="horizontal">
      {(provided) => (
        <Grid
          container
          display={'flex'}
          flexDirection={'column'}
          spacing={3}
          mt={2}
          sx={{
            maxHeight: "400px",
            overflowX: "scroll",
          }}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {list?.map(({ id, ...progr }, index) => (
            <Draggable key={id} draggableId={id} index={index}>
              {(provided) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <ProgramCard progr={progr} />
                </Grid>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </Grid>
      )}
    </Droppable>
  );
}
