import React from "react";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { CssTextField } from "../../../../../../styles/DefaultComponents";
import { useFormik } from "formik";
import * as yup from "yup";

const RecoverUi = ({ submit, loading }) => {
  const schema = yup.object().shape({
    email: yup.string().required("E-posta / Tel.(+90...) alanı gerekli"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schema,
    onSubmit: submit,
  });

  return (
    <Box component="form" sx={{ pt: 7 }} onSubmit={formik.handleSubmit}>
      <CssTextField
        margin="normal"
        size="small"
        required
        fullWidth
        variant="outlined"
        id="email"
        label="E-posta / Tel."
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.email)}
        helperText={formik.errors.email}
      />

      <Button
        disabled={!formik.isValid}
        type="submit"
        fullWidth
        variant={loading ? "outlined" : "contained"}
        sx={{
          mt: 3,
          mb: 2,
          color: formik.isValid ? "#000" : "#ffde0075 !important",
          border: formik.isValid ? "inherit" : "1px solid #ffde0075 !important",
        }}
      >
        {loading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          "Parolayı Sıfırla "
        )}
      </Button>
      <Grid container justifyContent={"center"} display={"flex"}>
        <Link href="/auth/signin" variant="subtitle1" underline="hover">
          ← Geri Dön
        </Link>
      </Grid>
    </Box>
  );
};

export default RecoverUi;
