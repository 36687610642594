/* eslint-disable */

const awsmobile = {
  dev: {
    aws_project_region: "eu-central-1",
    aws_appsync_graphqlEndpoint:
      "https://a2gdppwrc5echovcy2r765m5me.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-central-1",
    aws_appsync_authenticationType: "API_KEY",
    aws_appsync_apiKey: "da2-fzcxoleknbcm5azrpgsgvlookm",
    //    "aws_cognito_identity_pool_id": "eu-central-1:ca2cbce5-0d36-4049-94c6-94f0dae22849",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_1VJ1MCNqE",
    aws_user_pools_web_client_id: "7hqa9qqa8590kpund2ioq4jaut",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 6,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  },
  prod: {
    aws_project_region: "eu-central-1",
    aws_appsync_graphqlEndpoint:
      "https://lpu2lhvziveipcnteigsy4777a.appsync-api.eu-central-1.amazonaws.com/graphql",
    aws_appsync_region: "eu-central-1",
    aws_appsync_authenticationType: "API_KEY",
    aws_appsync_apiKey: "da2-jladk6odt5hxzfbqgwr7r3a5lq",
    //    "aws_cognito_identity_pool_id": "eu-central-1:ca2cbce5-0d36-4049-94c6-94f0dae22849",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_lE0aEQzie",
    aws_user_pools_web_client_id: "7m8ifprbpp9e4tb8nbehb9lp5b",
    oauth: {},
    aws_cognito_username_attributes: ["EMAIL"],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: ["EMAIL"],
    aws_cognito_mfa_configuration: "OFF",
    aws_cognito_mfa_types: ["SMS"],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 6,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ["EMAIL"],
  },
};

export default awsmobile;
