const themeOptions = {
  palette: {
    type: "dark",
    primary: {
      main: "#ffde00",
      contrastText: "#000000",
    },
    secondary: {
      main: "#000000",
      contrastText: "#ffde00",
    },
    background: {
      default: "#000000",
    },
    text: {
      primary: "#fff",
      secondary: "#ffde00",
      disabled: "#ffde00",
      hint: "#fff",
    },
    divider: "rgba(255,222,0,0.3)",
    info: {
      main: "#2196f3",
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#000",
        color: "#ffde00",
      },
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#000",
          "& .Mui-disabled": {
            color: "#fff !important",
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#000",
          "&:hover": {
            backgroundColor: "#efd428bf",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#000",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
  },

  props: {
    MuiAppBar: {
      color: "inherit",
    },
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
      color: "primary",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
      color: "primary",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 5,
  typography: {
    fontWeightBold: 900,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    fontWeightLight: 400,
    button: {
      textTransform: "capitalize",
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      '"Noto Sans"',
      '"Liberation Sans"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(","),
  },
};

export default themeOptions;
