import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

function Spinner() {
  return (
    <Grid container component="main" justifyContent={'center'} alignItems={'center'} sx={{ height: "100vh" }}>
      <CircularProgress />
    </Grid>
  );
}

export default Spinner;
