import { API } from "aws-amplify";
import * as types from "./types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";

/*Get dashboard statistics */
export const getDashboardDetails = () => async (dispatch) => {
  try {
    const listDashboardDetails = API.graphql({
      query: queries.adminDashboardQuery,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const dashDetails = await listDashboardDetails;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: dashDetails.data.me,
    });

    dispatch({
      type: types.GET_DASHBOARD_DETAILS,
      payload: dashDetails.data.generalStatistics,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};
