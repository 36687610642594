import React, { useEffect } from "react";
import GlobalTemplate from "../../templates/global";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CardMedia, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions";

function Support() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.defaultAction());
  }, [dispatch]);

  return (
    <GlobalTemplate
      bgBackground={Boolean(true)}
      title="Destek"
    >
      <Container
        disableGutters
        maxWidth="sm"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CardMedia
          component="img"
          image="/assets/client/images/destek.png"
          alt="Destek logo"
          height="auto"
          sx={{
            width: "55%",
          }}
        />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 10, mb: 1 }}
        >
          <Typography
            variant="h4"
            align="center"
            color="text.primary"
            component="h3"
          >
            +90 <span style={{ color: "#ffde00" }}>539 119 39 39</span>
          </Typography>
          <CardMedia
            component="img"
            image="/assets/client/images/WhatsApp_icon.png"
            alt="whatsapp logo"
            height="auto"
            sx={{
              width: "20%",
            }}
          />
        </Stack>

        <Typography
          variant="h4"
          align="center"
          color="text.primary"
          component="h4"
          sx={{ mb: 7, fontWeight: "normal" }}
        >
          info@bananativi.com
        </Typography>

        <Typography
          variant="h6"
          align="center"
          color="#ffffff75"
          component="p"
          sx={{ fontWeight: "normal" }}
        >
          Soru ve istekleriniz için bizlere ulaşabilirsiniz.
        </Typography>

        <Typography
          variant="h6"
          align="center"
          color="#ffffff75"
          component="p"
          sx={{ fontWeight: "normal", mb: 10 }}
        >
          Çalışma Saatleri{" "}
          <span style={{ color: "#ffde00", fontWeight: "bold" }}>
            09:00-18:00
          </span>
        </Typography>
      </Container>
    </GlobalTemplate>
  );
}

export default Support;
