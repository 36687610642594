import React from "react";
import {
  Backdrop,
  Typography,
  Button,
  Modal,
  Grid,
  Fade,
  CircularProgress,
  Box,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  CssFormControl,
  CssTextField,
  modalStyle,
} from "../../../../../../styles/DefaultComponents";

export default function SectionModal({
  name,
  open,
  modalClose,
  handleSubmit,
  handleChange,
  title,
  type,
}) {
  const buttonLoading = useSelector((state) => state.default.loading.button);
  return (
    <Modal
      aria-labelledby={`modal-label-${name}`}
      aria-describedby={`modal-description-${name}`}
      open={open}
      onClose={() => modalClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Typography id={`modal-label-${name}`} variant="h6" component="h2">
            {name == "create" ? "New section" : "Edit section"}
          </Typography>
          <Box
            id={`modal-description-${name}`}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  value={title}
                  label="Title"
                  onChange={(e) => handleChange(e.target.value, "title")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    value={type}
                    onChange={(e) => handleChange(e.target.value, "type")}
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={"SLIDER"}
                    >
                      Slider
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={"GRID"}
                    >
                      Grid
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value={"BANNER_SLIDER"}
                    >
                      Banner Slider
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Button
                id={`modal-button-${name}`}
                type="submit"
                variant={buttonLoading ? "outlined" : "contained"}
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                {buttonLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : name == "create" ? (
                  "Create"
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
