import React, { useEffect, useState } from "react";
import GlobalTemplate from "../../../templates/global";
import Slider from "./components/Slider";
import {
  Container,
  CardMedia,
  Box,
  Fade,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

function MainRaffle() {
  const [fadeEffect, setEffect] = useState(false);
  const raffleDetails = useSelector((state) => state.raffle.raffles);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.raffle.getRaffles());
  }, [dispatch]);

  return (
    <GlobalTemplate title="Çekiliş">
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          px: { xs: 2, md: 0 },
          pb: 10,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#ff760c",
            position: "relative",
            minHeight: { xs: "15vh", md: "25vh" },
            width: "100%",
            mb: { xs: 2, md: 0 },
            mt: { xs: 2, md: 0 },
          }}
        >
          <Fade in={fadeEffect}>
            <CardMedia
              component="img"
              image="/assets/client/images/raffle_banner.jpg"
              alt="main raffle"
              onLoad={() => setEffect(true)}
              sx={{
                height: { xs: "auto", md: "545px", lg: "690px", xl: "700px" },
                objectFit: "contain",
                backgroundSize: "contain",
              }}
            />
          </Fade>
        </Box>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "25vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Slider lists={raffleDetails} />
        )}
      </Container>
    </GlobalTemplate>
  );
}

export default MainRaffle;
