import React, { useEffect, useState } from "react";
import NonGlobalTemplate from "../../templates/non_global";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions";

function Paynet() {
  const [redirecting, setRedirecting] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.default.profile);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!redirecting) {
        dispatch(actions.subscribe.getPaynetURL());
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    const redirectionUrl = profile.subscription?.redirectionUrl;
    const status = profile.subscription?.status;

    if (status === "WAITING_USER_ACTION" && redirectionUrl !== null) {
      setRedirecting(true);
      window.location.href = redirectionUrl;
    }
  }, [profile]);

  return (
    <NonGlobalTemplate title="Ödeme">
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          color="#ffde00"
          component="h1"
          sx={{
            mb: 8,
            fontWeight: "bold",
            textShadow: "0px 5px #0000009e",
          }}
        >
          Kredi kartı bilgileri sayfasına yönlendiriliyorsunuz, lütfen
          bekleyiniz.
        </Typography>
        <CircularProgress color="primary" size={50} />
      </Container>
    </NonGlobalTemplate>
  );
}

export default Paynet;
