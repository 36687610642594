import * as types from "./types";

const initialState = {
  listAds: [],
  ad: {},
};

const adReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ADS:
      const { listPublishedAds } = action.payload;
      return {
        ...state,
        listAds: listPublishedAds.items,
      };

    case types.GET_ADMIN_ADS:
      const { adminListAds } = action.payload;
      return {
        ...state,
        listAds: adminListAds.items,
      };

    case types.SINGLE_AD:
      return {
        ...state,
        ad: action.payload,
      };

    default:
      return state;
  }
};

export default adReducer;
