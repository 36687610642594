import { useEffect } from "react";

const usePaynet = (paymentParams) => {
  useEffect(() => {
    const form = document.createElement("form");
    form.id = "purchase-form";
    form.method = "post";
    form.action = "";

    const script = document.createElement("script");
    script.className = "paynet-button";
    script.id = "purchase-script";
    script.type = "text/javascript";
    script.defer = true;
    script.setAttribute("data-key", paymentParams.paynetPublishableKey);
    script.setAttribute("data-button_label", "Ödeme Yap");
    script.setAttribute("data-save_card", "true");
    script.setAttribute("data-ratio_code", paymentParams.paynetRatioCode);
    script.setAttribute("data-installments", "0");
    script.setAttribute("data-no_instalment", "true");
    script.setAttribute("data-tds_required", "false");
    script.setAttribute("data-transaction_type", "1");

    if (paymentParams.paynetOwnerId) {
      script.setAttribute("data-card_owner_id", paymentParams.paynetOwnerId);
    } else {
      script.setAttribute(
        "data-user_unique_id",
        paymentParams.paynetUserUniqueId
      );
    }

    script.src = paymentParams.paynetFormScriptUrl;
    form.appendChild(script);
    document.body.appendChild(form);
    return () => {
      form.removeChild(script);
      document.body.removeChild(form);
    };
  }, [paymentParams]);
};

export default usePaynet;
