import { API } from "aws-amplify";
import * as types from "./types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get all users */
export const getUsers = (page, size) => async (dispatch) => {
  try {
    const listUsersQuery = API.graphql({
      query: queries.listUsers,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page, size } } },
    });

    const allUsers = await listUsersQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allUsers.data.me,
    });

    dispatch({
      type: dType.LIST_PAGINATION,
      payload: allUsers.data.listUsers.pagination,
    });

    dispatch({
      type: types.GET_USERS,
      payload: allUsers.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get all subscribed users */
export const getSubscribedUsers = (page, size) => async (dispatch) => {
  try {
    const listUsersQuery = API.graphql({
      query: queries.listSubscribers,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page, size } } },
    });

    const allUsers = await listUsersQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allUsers.data.me,
    });

    dispatch({
      type: dType.LIST_PAGINATION,
      payload: allUsers.data.listSubscribers.pagination,
    });

    dispatch({
      type: types.GET_SUBSCRIBERS,
      payload: allUsers.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get all raffle participants::user */
export const getRafffleParticipants =
  (raffleId, page, size) => async (dispatch) => {
    try {
      const listUsersQuery = API.graphql({
        query: queries.viewParticipants,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: { input: { pagination: { page, size }, raffleId } },
      });

      const allUsers = await listUsersQuery;
      dispatch({
        type: dType.PROFILE_DEFAULT,
        payload: allUsers.data.me,
      });

      dispatch({
        type: dType.LIST_PAGINATION,
        payload: allUsers.data.listRaffleParticipants.pagination,
      });

      dispatch({
        type: types.GET_RAFFLE_PARTICIPANTS,
        payload: allUsers.data,
      });

      dispatch({
        type: dType.UI_LOADING_DONE,
      });
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Give Bananas */
export const giveBananas = (userId, amount) => async (dispatch) => {
  try {
    const giveBananasQuery = API.graphql({
      query: mutations.giveBananas,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { amount, userId } },
    });

    await giveBananasQuery;

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });
  }
};
