import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Label from "../../../../../../components/admin/label";
import Moment from "react-moment";
import "moment/locale/tr";

const HistoryCard = ({ data }) => {
  return (
    <ListItem divider>
      <ListItemText
        secondary={
          <Moment
            locale="tr"
            element="span"
            format="DD MMMM YYYY"
            style={{ color: "#ffde00" }}
          >
            {data.timestamp}
          </Moment>
        }
      />
      <ListItemText primary={`${data.amount} 🍌`} />
      <ListItemText
        primary={
          <Label
            variant="filled"
            color={
              data.status == "FAILED"
                ? "error"
                : data.status == "RECEIVED"
                ? "primary"
                : "warning"
            }
          >
            {data.status == "FAILED"
              ? "ARIZALI"
              : data.status == "RECEIVED"
              ? "ALINAN"
              : "BEKLİYOR"}
          </Label>
        }
      />
      <ListItemText primary={`${data.price} ₺`} />
    </ListItem>
  );
};

export default HistoryCard;
