import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import usePaynet from "../../../../../../hooks/usePaynet.js";

// ----------------------------------------------------------------------

const ProgramImgStyle = styled("img")({
  width: "100%",
  ["@media (max-width: 767px)"]: {
    width: "90%",
  },
  ["@media (max-width: 1200px)"]: {
    width: "85%",
  },
});

// ----------------------------------------------------------------------

const BananaGrid = ({ data, paymentParams }) => {
  usePaynet(paymentParams);

  let images = [];

  const reorderedData = [...data].sort((a, b) => b.amount - a.amount);

  const purchaseHandler = (formUrl, price) => {
    document.getElementById("purchase-form").setAttribute("action", formUrl);

    // Banana package prices are float, but paynet expects it as multiplied by 100
    document
      .getElementById("purchase-script")
      .setAttribute("data-amount", price * 100);
    document.getElementsByClassName("paynetj-button")[0].click();
  };

  for (let i = 0; i < reorderedData.length; i++) {
    if (reorderedData.length == 0) {
      return (
        <Typography
          variant="h4"
          align="center"
          color="#ffffff75"
          sx={{ mt: 6, mb: 3, fontWeight: "normal" }}
        >
          Banana Paketleri
        </Typography>
      );
    }
    const mgridCount = parseInt(12 / parseInt(reorderedData.length / 2));
    const bgridCount = parseInt(12 / reorderedData.length);
    images.push(
      <Grid key={`icon image${i}`} item xs={6} md={mgridCount} lg={bgridCount}>
        <Box
          onClick={() =>
            purchaseHandler(
              reorderedData[i].purchaseFormActionUrl,
              reorderedData[i].price
            )
          }
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <ProgramImgStyle
            alt={reorderedData[i].id}
            src={reorderedData[i].iconUrl}
          />
        </Box>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={{ xs: 4 }} sx={{ px: { xs: 5 }, py: 5 }}>
        {images}
      </Grid>
    </>
  );
};

export default BananaGrid;
