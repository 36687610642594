import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Container, Typography, Button, Tab, Box } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import AdminTemplate from "../../../templates/global";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import MediaTabItem from "./components/MediaTabItem";
import { CssTextField } from "../../../../../styles/DefaultComponents";

const Media = () => {
  const [tabValue, setTabValue] = useState("image");
  const medias = useSelector((state) => state.media.medias);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.media.getMedias());
  }, [dispatch]);

  const handleSearchMedia = (e) => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.media.searchMedia(e.target.value));
  };

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const imageMedias = medias.filter((file) => file.type == "IMAGE");

  const videoMedias = medias.filter((file) => file.type == "VIDEO");

  return (
    <AdminTemplate title="Media">
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Media
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            target="_blank"
            to="/admin/media/upload"
            startIcon={<AddIcon />}
          >
            New media
          </Button>
        </Stack>

        <Container maxWidth="md" sx={{ pb: 5 }}>
          <CssTextField
            margin="normal"
            size="small"
            fullWidth
            variant="outlined"
            id="email"
            label="Search Media"
            name="email"
            onChange={handleSearchMedia}
            InputProps={{
              type: "search",
            }}
          />
        </Container>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange} aria-label="media files tabs">
                <Tab label="IMAGES" value="image" />
                <Tab label="VIDEOS" value="video" />
              </TabList>
            </Box>
            <TabPanel value="image">
              <MediaTabItem
                loading={uiLoading}
                data={imageMedias}
                type="image"
              />
            </TabPanel>
            <TabPanel value="video">
              <MediaTabItem
                loading={uiLoading}
                data={videoMedias}
                type="video"
              />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </AdminTemplate>
  );
};

export default Media;
