import React, { useEffect } from "react";
import NonGlobalTemplate from "../../templates/non_global";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

function SubscriptionCallback() {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.opener !== null) {
        window.opener.location.href = "/profile";
      }
      window.close();
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <NonGlobalTemplate title="Abonelik">
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          color="#ffde00"
          component="h1"
          sx={{
            mb: 8,
            fontWeight: "bold",
            textShadow: "0px 5px #0000009e",
          }}
        >
          Abonelik başvurunuz alınmıştır, teşekkür ederiz.
        </Typography>
        <CircularProgress color="primary" size={50} />
      </Container>
    </NonGlobalTemplate>
  );
}

export default SubscriptionCallback;
