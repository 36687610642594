import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  Grid,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import SearchNotFound from "./components/SearchNotFound";
import UserListHead from "./components/UserListHead";
import UserListToolbar from "./components/UserListToolbar";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "givenName", label: "Name", alignRight: false },
  { id: "familyName", label: "Surname", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "gsm", label: "Phone Number", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.givenName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const Users = () => {
  const users = useSelector((state) => state.user.users);
  const [paginationLoading, setTableLoading] = useState(true);
  const listPagination = useSelector((state) => state.default.pagination);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    dispatch(actions.user.getUsers(1, rowsPerPage));
    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setTableLoading(true);
    setPage(newPage);
    dispatch(actions.user.getUsers(newPage + 1, rowsPerPage));
    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  };

  const handleChangeRowsPerPage = (event) => {
    setTableLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(actions.user.getUsers(1, parseInt(event.target.value, 10)));
    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - listPagination?.totalItems) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <AdminTemplate title="All Users">
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Typography variant="h4" gutterBottom>
          All Users
        </Typography>

        {paginationLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Card sx={{ bgcolor: "#ffde004d" }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={listPagination?.totalItems}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredUsers.map((row) => {
                    const { familyName, givenName, email, gsm, id } = row;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">{givenName}</TableCell>
                        <TableCell align="left">{familyName}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{gsm}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound
                          searchQuery={filterName}
                          sx={{ bgcolor: "transparent", boxShadow: "none" }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={listPagination?.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default Users;
