import React from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import { Box, Card, Link, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Label from "../../../../../../components/admin/label";

import WatchLaterIcon from "@mui/icons-material/WatchLater";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PolicyIcon from "@mui/icons-material/Policy";
import RaffleMoreMenu from "./RaffleMoreMenu";

// ----------------------------------------------------------------------

const RaffleImgStyle = styled("img")({
  width: "100%",
});

// ----------------------------------------------------------------------

const RaffleCard = ({ raffle }) => {
  const {
    id,
    title,
    verticalPosterImageMediaUrl,
    isPublic,
    winnerTickets,
    endTime,
  } = raffle;
  return (
    <Card sx={{ bgcolor: "#ffde004d" }}>
      <Box>
        <RaffleImgStyle alt={title} src={verticalPosterImageMediaUrl} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
      <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
        {/* <Link to="#" color="inherit" underline="hover" component={RouterLink}> */}
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        {/* </Link> */}

        <RaffleMoreMenu name={title} id={id} />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {!moment(endTime).isAfter(moment.now()) && (
            <Label
              variant="filled"
              color="primary"
              startIcon={<EmojiEventsIcon />}
            >
              {winnerTickets.length}
            </Label>
          )}

          <Label
            variant={
              moment(endTime).isAfter(moment.now()) ? "outlined" : "filled"
            }
            color={
              moment(endTime).isAfter(moment.now()) ? "primary" : "secondary"
            }
            startIcon={<WatchLaterIcon />}
          >
            {!moment(endTime).isAfter(moment.now()) ? "Closed" : "Active"}
          </Label>

          <Label
            variant={!isPublic ? "outlined" : "filled"}
            color={"warning"}
            startIcon={<PolicyIcon />}
          >
            {!isPublic ? "Private" : "Public"}
          </Label>
        </Stack>
      </Stack>
    </Card>
  );
};

export default RaffleCard;
