import React, { useState } from "react";
import {
  List,
  Tooltip,
  Zoom,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import PreviewIcon from "@mui/icons-material/Preview";
import ConfirmDelete from "./ConfirmDelete";

export default function PackageArea({
  list,
  medias,
  handlePreview,
  handleEdit,
  handleDelete,
}) {
  const [fields, setFields] = useState({ modal: false, id: "", amount: "" });
  return (
    <>
      <ConfirmDelete
        name={fields["amount"]}
        open={fields["modal"]}
        id={fields["id"]}
        handleDelete={handleDelete}
        modalClose={() => {
          setFields({ ...fields, modal: false });
        }}
      />

      <List
        sx={{
          width: "100%",
          bgcolor: "#ffde0030 !important",
        }}
      >
        {list.map(({ id, amount, price, iconUrl }, index) => (
          <ListItem
            key={index}
            sx={{
              bgcolor: "#ffde004d",
              margin: 2,
              width: "auto",
              borderRadius: "5px",
            }}
            secondaryAction={
              <>
                <Tooltip
                  title="Preview package"
                  TransitionComponent={Zoom}
                  followCursor
                >
                  <IconButton
                    edge="start"
                    onClick={() => {
                      handlePreview(iconUrl);
                    }}
                    aria-label="preview"
                  >
                    <PreviewIcon sx={{ color: "#000" }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Edit" TransitionComponent={Zoom} followCursor>
                  <IconButton
                    aria-label="edit"
                    onClick={() => {
                      const iconPre = medias.filter(
                        (URL) => URL.url === iconUrl
                      );

                      handleEdit(amount, iconPre[0], price, id);
                    }}
                  >
                    <ModeEditOutlineIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete" TransitionComponent={Zoom} followCursor>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setFields({ modal: true, amount, id });
                    }}
                  >
                    <DeleteIcon sx={{ color: "#ed6c02" }} />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <ListItemText primary={`${amount} 🍌`} secondary={`${price} ₺`} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
