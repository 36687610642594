import { API } from "aws-amplify";
import * as types from "./types";
import * as tMedia from "../media/types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get all raffles */
export const getRaffles = () => async (dispatch) => {
  try {
    const listRafflesQuery = API.graphql({
      query: queries.listRaffles,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page: 1, size: 100 } } },
    });

    const allRaffles = await listRafflesQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allRaffles.data.me,
    });

    dispatch({
      type: types.GET_RAFFLES,
      payload: allRaffles.data,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get single raffle details */
export const getRaffle = (id) => async (dispatch) => {
  try {
    const getRaffleQuery = API.graphql({
      query: queries.getRaffle,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id } },
    });

    const singleRaffle = await getRaffleQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleRaffle.data.me,
    });

    dispatch({
      type: types.SINGLE_RAFFLE,
      payload: singleRaffle.data.getRaffle,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Join raffle  */
export const joinRaffle = (id, amount) => async (dispatch) => {
  try {
    const joinRaffleQuery = API.graphql({
      query: mutations.joinRaffle,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id, amount } },
    });

    const singleRaffle = await joinRaffleQuery;

    dispatch({
      type: types.SINGLE_RAFFLE,
      payload: singleRaffle.data.joinRaffle,
    });

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Create raffle */
export const createRaffle =
  (
    description,
    endTime,
    index,
    isPublic,
    isPublished,
    promoVideoMediaUrl,
    sponsorBannerMediaUrl,
    startTime,
    title,
    verticalPosterImageMediaUrl
  ) =>
  async (dispatch) => {
    try {
      const createRaffleQuery = API.graphql({
        query: mutations.createRaffle,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            description,
            endTime,
            index,
            isPublic,
            isPublished,
            promoVideoMediaUrl,
            sponsorBannerMediaUrl,
            startTime,
            title,
            verticalPosterImageMediaUrl,
          },
        },
      });

      await createRaffleQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace("/admin/raffle/display");
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Archive Raffle */
export const archiveRaffle = (id) => async (dispatch) => {
  try {
    const archiveRaffleQuery = API.graphql({
      query: mutations.updateRaffle,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
          isArchived: true,
        },
      },
    });

    await archiveRaffleQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Unarchive Raffle */
export const unArchiveRaffle = (id) => async (dispatch) => {
  try {
    const unArchiveRaffleQuery = API.graphql({
      query: mutations.updateRaffle,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
          isArchived: false,
        },
      },
    });

    await unArchiveRaffleQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get edit raffle details */
export const getEditRaffle = (id) => async (dispatch) => {
  try {
    const getEditRaffleQuery = API.graphql({
      query: queries.getEditRaffle,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        raffle: { id },
        media: {
          pagination: { page: 1, size: 500 },
          fileStatuses: ["DISTRIBUTED"],
          fileTypes: ["IMAGE", "VIDEO"],
        },
      },
    });

    const singleRaffle = await getEditRaffleQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleRaffle.data.me,
    });

    dispatch({
      type: tMedia.GET_MEDIAS,
      payload: singleRaffle.data,
    });

    dispatch({
      type: types.SINGLE_RAFFLE,
      payload: singleRaffle.data.getRaffle,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Update raffle */
export const updateRaffle =
  (
    id,
    description,
    endTime,
    isPublic,
    isArchived,
    promoVideoMediaUrl,
    sponsorBannerMediaUrl,
    title,
    verticalPosterImageMediaUrl
  ) =>
  async (dispatch) => {
    try {
      const updateRaffleQuery = API.graphql({
        query: mutations.updateRaffle,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            id,
            description,
            endTime,
            isPublic,
            isArchived,
            promoVideoMediaUrl,
            sponsorBannerMediaUrl,
            title,
            verticalPosterImageMediaUrl,
          },
        },
      });

      await updateRaffleQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace(`/admin/raffle/display`);
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Delete raffle */
export const deleteRaffle = (id) => async (dispatch) => {
  try {
    const deleteRaffleQuery = API.graphql({
      query: mutations.deleteRaffle,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await deleteRaffleQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};


/* Get all raffle participants::user */
export const getRafffleParticipants = (raffleId, page, size) => async (dispatch) => {
  try {
    const listUsersQuery = API.graphql({
      query: queries.viewParticipants,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page, size }, raffleId } },
    });

    const allUsers = await listUsersQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allUsers.data.me,
    });

    dispatch({
      type: dType.LIST_PAGINATION,
      payload: allUsers.data.listRaffleParticipants.pagination,
    });

    dispatch({
      type: types.GET_RAFFLE_PARTICIPANTS,
      payload: allUsers.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};
