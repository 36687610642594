import React from "react";
import { Typography } from "@mui/material";
import { CssTextField } from "../../../../../styles/DefaultComponents";

const Details = ({
  email,
  phone,
  given,
  family,
  fields,
  handleEdit,
  isEdit,
}) => {
  return (
    <>
      {/* Email address */}
      <Typography
        variant="h6"
        color="#d7d3f8"
        component="h2"
        sx={{ fontWeight: 600 }}
      >
        E-posta
      </Typography>
      <Typography
        variant="subtitle1"
        color="#ffffff61"
        component="h4"
        sx={{ mb: 3, fontWeight: "normal" }}
      >
        {email}
      </Typography>

      {/* Telephone Number */}
      <Typography
        variant="h6"
        color="#d7d3f8"
        component="h2"
        sx={{ fontWeight: 600 }}
      >
        Telefon
      </Typography>
      <Typography
        variant="subtitle1"
        color="#ffffff61"
        component="h4"
        sx={{ mb: 3, fontWeight: "normal" }}
      >
        {phone ? phone : "- - - - - - - - -"}
      </Typography>

      {/* Given Name */}
      <Typography
        variant="h6"
        color="#d7d3f8"
        component="h2"
        sx={{ fontWeight: 600 }}
      >
        İsim
      </Typography>
      {isEdit ? (
        <CssTextField
          margin="normal"
          size="small"
          required
          fullWidth
          variant="outlined"
          id="given"
          label="İsim"
          value={fields["given"]}
          name="given"
          onChange={(e) =>
            handleEdit({ ...fields, [e.target.name]: e.target.value })
          }
        />
      ) : (
        <Typography
          variant="subtitle1"
          color="#ffffff61"
          component="h4"
          sx={{ mb: 3, fontWeight: "normal" }}
        >
          {given}
        </Typography>
      )}

      {/* Family Name */}
      <Typography
        variant="h6"
        color="#d7d3f8"
        component="h2"
        sx={{ fontWeight: 600 }}
      >
        Soyisim
      </Typography>
      {isEdit ? (
        <CssTextField
          margin="normal"
          size="small"
          required
          fullWidth
          variant="outlined"
          id="family"
          label="Soyisim"
          value={fields["family"]}
          name="family"
          onChange={(e) =>
            handleEdit({ ...fields, [e.target.name]: e.target.value })
          }
        />
      ) : (
        <Typography
          variant="subtitle1"
          color="#ffffff61"
          component="h4"
          sx={{ mb: 2, fontWeight: "normal" }}
        >
          {family}
        </Typography>
      )}
    </>
  );
};

export default Details;
