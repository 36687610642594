import React from "react";
import Button from "@mui/material/Button";
import { Stack, CardMedia, Alert, Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const AuthTemplate = ({ children, status = { visible: false } }) => {
  return (
    <>
      <Collapse in={status.visible}>
        <Alert severity={status?.type} sx={{ borderRadius: 0 }}>
          {status.visible
            ? status?.type === "success"
              ? "Kullanıcı e-postası başarıyla onaylandı"
              : status?.type === "error"
              ? "E-posta onay bağlantısının süresi zaten doldu."
              : "E-posta adresi doğrulandı"
            : null}
        </Alert>
      </Collapse>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={12} md={4}>
          <Box
            sx={{
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <CardMedia
              component="img"
              image="/logo.png"
              alt="Bananativi logo"
              height="auto"
              sx={{ width: { xs: "40%", md: "60%" }, pb: 10 }}
            />
            {children}
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={false}
          md={8}
          sx={{
            backgroundImage: "url(/assets/client/images/bg.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            sx={{
              backgroundColor: "#ffde00",
              height: "6vh",
              px: 5,
              display: { xs: "none", md: "flex" },
            }}
          >
            <Grid
              item
              md={7}
              lg={8}
              xl={9}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <Typography component="h2" variant="body1" color="common.black">
                Kıbrıs'ın ilk Dijital Eğlence Platformu
              </Typography>
            </Grid>
            <Grid
              item
              md={5}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
                <Button variant="contained" fullWidth color="secondary" href="/auth/signup">
                  Üye Ol
                </Button>
                <Button variant="outlined" fullWidth color="secondary" href="/auth/signin">
                  Üye Girişi
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthTemplate;
