import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import { CardMedia, Stack, Paper, Divider } from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import TuneIcon from "@mui/icons-material/Tune";
import ListItemIcon from "@mui/material/ListItemIcon";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();

  const profile = useSelector((state) => state.default.profile);
  const firstWord = profile.family?.split("")[0];

  const pages = [
    { name: "ANA SAYFA", href: "/" },
    { name: "İÇERİKLER", href: "/discover" },
    { name: "ÇEKİLİŞLER", href: "/raffle" },
    { name: "BANANA KAZAN", href: "/watchads" },
    { name: "BANANA SATIN AL", href: "/purchase" },
    { name: "HAKKIMIZDA", href: "/about" },
    { name: "DESTEK", href: "/support" },
    { name: "SSS", href: "/faq" },
  ];
  const settings = [
    ...(profile.isAdmin
      ? [
          {
            name: "Kontrol Paneli",
            icon: <TuneIcon />,
            onClick: () => navigate("/admin/dashboard"),
          },
        ]
      : []),
    {
      name: "Profilim",
      icon: <PersonIcon />,
      onClick: () => navigate("/profile"),
    },
    {
      name: "Oturumu Kapat",
      icon: <Logout />,
      onClick: async () => {
        await Auth.signOut();
        navigate(0);
      },
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <CardMedia
            component="img"
            image="/logo.png"
            alt="Bananativi logo"
            height="auto"
            onClick={() => navigate("/")}
            sx={{
              width: { md: "12%", lg: "13%", xl: "15%" },
              display: { xs: "none", md: "flex" },
              cursor: "pointer",
            }}
          />

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  backgroundColor: "black",
                  border: "1px solid #ffde00",
                },
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    handleCloseNavMenu;
                    navigate(page.href);
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffde00",
                      color: "#000",
                    },
                  }}
                >
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <CardMedia
            component="img"
            image="/logo.png"
            alt="Bananativi logo"
            onClick={() => navigate("/")}
            height="auto"
            sx={{
              width: { xs: "40%", sm: "10%" },
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              cursor: "pointer",
            }}
          />

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              backgroundColor: "#ffde00",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  handleCloseNavMenu;
                  navigate(page.href);
                }}
                sx={{
                  my: { md: 3, lg: 3, xl: 4 },
                  mx: { md: 1, lg: 1, xl: 2 },
                  color: "black",
                  display: "block",
                  fontSize: { md: "1.25vw", lg: "1vw", xl: "15px" },
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", lg: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ alignItems: "flex-end" }}
            >
              <Avatar
                alt="user logo"
                src="/assets/client/images/profile.png"
                variant="square"
              />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
            >
              <Paper sx={{ backgroundColor: "transparent" }}>
                <Tooltip title="Banana">
                  <IconButton
                    sx={{
                      alignItems: "flex-end",
                      "&:hover": {
                        cursor: "auto",
                      },
                    }}
                  >
                    {profile.balance == "" ? (
                      <CircularProgress size={"15px"} />
                    ) : (
                      <Typography
                        sx={{ mx: 1, whiteSpace: "nowrap" }}
                        color="#fff"
                        variant="body1"
                      >
                        {profile.balance} x
                      </Typography>
                    )}

                    <Avatar
                      alt="banana token"
                      src="/assets/client/images/banana.png"
                    />
                  </IconButton>
                </Tooltip>
              </Paper>
              <Paper sx={{ backgroundColor: "transparent" }}>
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ alignItems: "flex-end" }}
                >
                  <Avatar
                    alt="user logo"
                    src="/assets/client/images/profile.png"
                    variant="square"
                  />
                  {profile.balance == "" ? (
                    <CircularProgress size={"20px"} />
                  ) : (
                    <Typography
                      sx={{
                        mx: 1,
                        whiteSpace: "nowrap",
                        display: { md: "none", lg: "block" },
                      }}
                      color="#ffde00"
                      variant="body2"
                    >
                      {profile?.given} {firstWord}.
                    </Typography>
                  )}
                </IconButton>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: "black",
                      border: "1px solid #ffde00",
                    },
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting.name}
                      onClick={() => {
                        handleCloseUserMenu;
                        setting.onClick();
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#ffde00",
                          color: "#000",
                        },
                        "&:hover .MuiListItemIcon-root": {
                          color: "#000",
                        },
                      }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>
                        {setting.icon}
                      </ListItemIcon>
                      <Typography textAlign="center">{setting.name}</Typography>
                    </MenuItem>
                  ))}
                  <Divider
                    sx={{
                      display: { xs: "flex", lg: "none" },
                    }}
                  />
                  <MenuItem
                    sx={{
                      display: { xs: "flex", lg: "none" },
                    }}
                  >
                    <Typography>Banana: </Typography>
                    <IconButton
                      sx={{
                        "&:hover": {
                          cursor: "auto",
                        },
                      }}
                    >
                      {profile.balance == "" ? (
                        <CircularProgress size={"10px"} />
                      ) : (
                        <Typography sx={{ mx: 1 }} color="#fff" variant="h4">
                          {profile.balance}{" "}
                          <span
                            style={{ fontSize: "19px", fontWeight: "normal" }}
                          >
                            x
                          </span>
                        </Typography>
                      )}

                      <Avatar
                        alt="banana token"
                        src="/assets/client/images/banana.png"
                      />
                    </IconButton>
                  </MenuItem>
                </Menu>
              </Paper>
            </Stack>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
