import * as types from "./types";

const initialState = {
  profile: {
    family: "",
    given: "",
    email: "",
    gsm: "",
    balance: 0,
    isAdmin: false,
    subscription: {},
    cards: [],
    purchases: [],
  },
  loading: {
    container: true,
    button: false,
  },
  pagination: {
    pageItems: 0,
    pageNumber: 0,
    totalItems: 0,
    totalPages: 0,
  },
};

const defaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PROFILE_DEFAULT:
      const me = action.payload.hasOwnProperty("me")
        ? action.payload.me
        : action.payload;

      return {
        ...state,
        profile: {
          family: me?.familyName,
          given: me?.givenName,
          balance: me?.balance?.toString(), //
          isAdmin: me?.isAdmin,
          subscription: me?.subscription,
          gsm: me?.gsm,
          email: me?.email,
          cards: action.payload?.savedCards,
          purchases: action.payload?.listBananaPurchases?.items,
        },
      };

    case types.REFRESH_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          given: action.payload?.givenName,
          family: action.payload?.familyName,
        },
      };

    case types.UI_LOADING_DONE:
      return { ...state, loading: { container: false } };

    case types.LOADING_BUTTON_DONE:
      return { ...state, loading: { button: false } };

    case types.UI_LOADING_REQUEST:
      return { ...state, loading: { container: true } };

    case types.LOADING_BUTTON_REQUEST:
      return { ...state, loading: { button: true } };

    case types.LIST_PAGINATION:
      return { ...state, pagination: action.payload };

    default:
      return state;
  }
};

export default defaultReducer;
