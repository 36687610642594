import {
  Divider,
  Backdrop,
  Modal,
  Box,
  CircularProgress,
  Typography,
  Grid,
  Button,
  Fade,
} from "@mui/material";
import { useSelector } from "react-redux";
import { modalStyle } from "../../../../../styles/DefaultComponents";

export default function ConfirmDelete({
  name,
  handleDelete,
  modalClose,
  open,
  id,
}) {
  const buttonLoading = useSelector((state) => state.default.loading.button);
  return (
    <Modal
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
      open={open}
      onClose={modalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Typography
            id="delete-modal-title"
            variant="h6"
            component="h2"
            sx={{ pb: 2 }}
          >
            Delete {name} 🍌
          </Typography>
          <Divider
            sx={{
              borderStyle: "groove",
              borderColor: "#ed6c02",
            }}
          />
          <Typography variant="h6" component="h4" sx={{ py: 4 }}>
            Are you sure you want to delete package with{" "}
            <span
              style={{
                color: "#ed6c02",
              }}
            >
              {name}
            </span>{" "}
            🍌 ?
          </Typography>
          <Grid container justifyContent={"flex-end"}>
            <Button
              id="delete-modal-button"
              onClick={() => handleDelete(id)}
              variant={buttonLoading ? "outlined" : "contained"}
              color={"warning"}
            >
              {buttonLoading ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                "Delete"
              )}
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
