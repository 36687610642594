import { API, Auth } from "aws-amplify";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get User Current Status */
export const getProfile = () => async (dispatch) => {
  try {
    const currentStatusQuery = API.graphql({
      query: queries.getUserCurrentStatus,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          pagination: { page: 1, size: 100 },
        },
      },
    });

    const currentProfile = await currentStatusQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: currentProfile.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
    await Auth.signOut();
    window.location.reload();
  }
};

/* Get Paynet URL */
export const getPaynetURL = () => async (dispatch) => {
  try {
    const currentStatusQuery = API.graphql({
      query: queries.subPaynetQuery,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          pagination: { page: 1, size: 100 },
        },
      },
    });

    const currentProfile = await currentStatusQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: currentProfile.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
    await Auth.signOut();
    window.location.reload();
  }
};


/* Start Subscription */
export const startSubscription = (gsm) => async () => {
  const startSubQuery = API.graphql({
    query: mutations.startSubscription,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: {
        gsm,
      },
    },
  });

  await startSubQuery;
};

/* Cancel Subscription */
export const cancelSubscription = (id) => async () => {
  const cancelSubQuery = API.graphql({
    query: mutations.cancelSubscription,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: {
        id,
      },
    },
  });

  await cancelSubQuery;
};

/* Delete saved card */
export const deleteCard = (id) => async () => {
  const deleteCardQuery = API.graphql({
    query: mutations.deleteSavedCard,
    authMode: "AMAZON_COGNITO_USER_POOLS",
    variables: {
      input: {
        id,
      },
    },
  });

  await deleteCardQuery;

  window.location.reload();
};
