import { useEffect, useState, useRef } from "react";
import "../config";
import { Auth } from "aws-amplify";

export const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [emailVerified, setVerified] = useState(true);
  const [isAdmin, setAdmin] = useState(false);
  const [isGsm, setGsm] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);
  const isMounted = useRef(true);

  const getUserAuthStatus = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      if (user) {
        const tokenLoad = user.signInUserSession.idToken.payload;
        const userAttr = user.attributes;
        if (tokenLoad["cognito:groups"]) {
          setAdmin(true);
        }
        if (!userAttr.email_verified || userAttr.email_verified == null) {
          setVerified(false);
        }
        if (userAttr["custom:sourceGSMOperator"]) {
          setGsm(true);
        }
        setLoggedIn(true);
        setCheckingStatus(false);
      }
    } catch (err) {
      setCheckingStatus(false);
      console.log("Error: ", err);
    }
  };

  useEffect(() => {
    if (isMounted) {
      getUserAuthStatus();
    }

    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return { loggedIn, checkingStatus, isAdmin, emailVerified, isGsm };
};
