import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { CssTextField } from "../../../../../../styles/DefaultComponents";
import { useFormik } from "formik";
import * as yup from "yup";

const CfRecoverUi = ({ submit, loading }) => {
  const schema = yup.object().shape({
    code: yup
      .string()
      .required("Lütfen doğrulama kodunu giriniz")
      .matches(/^(?=.{6,})/, "Minimum 6 karakter olmalı."),
    cfPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Parolalar eşleşmeli"),
    password: yup
      .string()
      .required("Lütfen yeni parolanızı giriniz")
      .matches(/^(?=.*[a-z])/, "En az 1 küçük harf içerir")
      .matches(/^(?=.*[A-Z])/, "En az 1 büyük harf içerir")
      .matches(/^(?=.*[0-9])/, "En az 1 sayı içerir")
      .matches(/^(?=.{6,})/, "Minimum 6 karakter olmalı."),
  });

  const formik = useFormik({
    initialValues: {
      code: "",
      cfPassword: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: submit,
  });

  return (
    <Box component="form" sx={{ pt: 7 }} onSubmit={formik.handleSubmit}>
      <CssTextField
        margin="normal"
        size="small"
        required
        fullWidth
        variant="outlined"
        id="code"
        label="Doğrulama Kodu"
        name="code"
        placeholder="Lütfen e-postanıza gelen kodu giriniz"
        value={formik.values.code}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.code)}
        helperText={formik.errors.code}
      />
      <CssTextField
        margin="normal"
        size="small"
        required
        fullWidth
        variant="outlined"
        id="password"
        label="Yeni Parola"
        type="password"
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.password)}
        helperText={formik.errors.password}
      />
      <CssTextField
        margin="normal"
        size="small"
        required
        fullWidth
        variant="outlined"
        name="cfPassword"
        label="Yeni Parola (Tekrar)"
        type="password"
        id="cfPassword"
        value={formik.values.cfPassword}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.cfPassword)}
        helperText={formik.errors.cfPassword}
      />
      <Button
        disabled={!formik.isValid}
        type="submit"
        fullWidth
        variant={loading ? "outlined" : "contained"}
        sx={{
          mt: 3,
          mb: 2,
          color: formik.isValid ? "#000" : "#ffde0075 !important",
          border: formik.isValid ? "inherit" : "1px solid #ffde0075 !important",
        }}
      >
        {loading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          "Parolamı Değiştir"
        )}
      </Button>
    </Box>
  );
};

export default CfRecoverUi;
