import React from 'react';
import { Popover } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const ArrowStyle = styled('span')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        top: -7,
        zIndex: 0,
        width: 12,
        right: 20,
        height: 12,
        content: "''",
        position: 'absolute',
        borderRadius: '50px 0 0 0',
        transform: 'rotate(-135deg)',
        background: '#ffdd00',
        borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
        borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    },
}));

// ----------------------------------------------------------------------


const NavPopover = ({ children, sx, ...other }) => {
    return (
        <Popover
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 200,
                    overflow: 'inherit',
                    zIndex: 1,
                    ...sx,
                },
            }}
            {...other}
        >
            <ArrowStyle className="arrow" />

            {children}
        </Popover>
    )
}

export default NavPopover