/***************CLIENT****************/

export const refreshMe = /* GraphQL */ `
  mutation RefreshMe {
    refreshMe {
      givenName
      familyName
    }
  }
`;

export const giveBananas = /* GraphQL */ `
  mutation giveBananas($input: GiveBananasInput!) {
    giveBananas(input: $input) {
      amount
    }
  }
`;

export const joinRaffle = /* GraphQL */ `
  mutation JoinRaffle($input: JoinRaffleInput!) {
    joinRaffle(input: $input) {
      id
      title
      endTime
      startTime
      sponsorBannerMediaUrl
      isArchived
      isPublic
      promoVideoMediaUrl
      resultTime
      winnerPrompt
      userTickets {
        id
      }
      winnerTickets {
        givenName
        familyName
      }
    }
  }
`;

export const startSubscription = /* GraphQL */ `
  mutation startSubscription($input: StartSubscriptionInput!) {
    startSubscription(input: $input) {
      id
    }
  }
`;

export const cancelSubscription = /* GraphQL */ `
  mutation cancelSubscription($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      id
    }
  }
`;

export const deleteSavedCard = /* GraphQL */ `
  mutation deleteSavedCard($input: DeleteSavedCardInput!) {
    deleteSavedCard(input: $input) {
      id
    }
  }
`;

export const answerAdQuiz = /* GraphQL */ `
  mutation answerAdQuiz($input: AnswerAdQuizInput!) {
    answerAdQuiz(input: $input) {
      rewardedBananaAmount
      answeredRight
    }
  }
`;

/***************ADMIN****************/

export const startMediaFileUpload = /* GraphQL */ `
  mutation startMediaFileUpload($input: StartMediaFileUploadInput!) {
    startMediaFileUpload(input: $input) {
      id
      status
      s3UploadParts {
        signedUrl
        sizeBytes
      }
    }
  }
`;

export const createProgram = /* GraphQL */ `
  mutation createProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      id
    }
  }
`;

export const updateProgram = /* GraphQL */ `
  mutation updateProgram($input: UpdateProgramInput!) {
    updateProgram(input: $input) {
      id
    }
  }
`;

export const deleteProgram = /* GraphQL */ `
  mutation deleteProgram($input: DeleteProgramInput!) {
    deleteProgram(input: $input) {
      id
    }
  }
`;

export const createProgramListing = /* GraphQL */ `
  mutation createProgramListing($input: CreateProgramListingInput!) {
    createProgramListing(input: $input) {
      id
    }
  }
`;

export const updateProgramListing = /* GraphQL */ `
  mutation updateProgramListing($input: UpdateProgramListingInput!) {
    updateProgramListing(input: $input) {
      items {
        id
        title
        bannerImageMediaUrl
        verticalPosterImageMediaUrl
        horizontalPosterImageMediaUrl
        categoryDisplayName
      }
    }
  }
`;

export const reOrderProgramListing = /* GraphQL */ `
  mutation reOrderProgramListing($input: ReorderProgramListingInput!) {
    reOrderProgramListing(input: $input) {
      id
    }
  }
`;

export const deleteProgramListing = /* GraphQL */ `
  mutation deleteProgramListing($input: DeleteProgramListingInput!) {
    deleteProgramListing(input: $input) {
      id
    }
  }
`;

export const createAffiliate = /* GraphQL */ `
  mutation createAffiliate($input: CreateAffiliateInput!) {
    createAffiliate(input: $input) {
      id
    }
  }
`;

export const updateAffiliate = /* GraphQL */ `
  mutation updateAffiliate($input: UpdateAffiliateInput!) {
    updateAffiliate(input: $input) {
      id
    }
  }
`;

export const deleteAffiliate = /* GraphQL */ `
  mutation deleteAffiliate($input: DeleteAffiliateInput!) {
    deleteAffiliate(input: $input) {
      id
    }
  }
`;

export const createRaffle = /* GraphQL */ `
  mutation createRaffle($input: CreateRaffleInput!) {
    createRaffle(input: $input) {
      id
    }
  }
`;

export const updateRaffle = /* GraphQL */ `
  mutation updateRaffle($input: UpdateRaffleInput!) {
    updateRaffle(input: $input) {
      id
    }
  }
`;

export const deleteRaffle = /* GraphQL */ `
  mutation deleteRaffle($input: DeleteRaffleInput!) {
    deleteRaffle(input: $input) {
      id
    }
  }
`;

export const createPackage = /* GraphQL */ `
  mutation createBananaPackage($input: CreateBananaPackageInput!) {
    createBananaPackage(input: $input) {
      id
    }
  }
`;

export const updatePackage = /* GraphQL */ `
  mutation updateBananaPackage($input: UpdateBananaPackageInput!) {
    updateBananaPackage(input: $input) {
      id
    }
  }
`;

export const deletePackage = /* GraphQL */ `
  mutation deleteBananaPackage($input: DeleteBananaPackageInput!) {
    deleteBananaPackage(input: $input) {
      id
    }
  }
`;

export const createEpisode = /* GraphQL */ `
  mutation createEpisode($input: CreateEpisodeInput!) {
    createEpisode(input: $input) {
      id
    }
  }
`;

export const updateEpisode = /* GraphQL */ `
  mutation updateEpisode($input: UpdateEpisodeInput!) {
    updateEpisode(input: $input) {
      id
    }
  }
`;

export const deleteEpisode = /* GraphQL */ `
  mutation deleteEpisode($input: DeleteEpisodeInput!) {
    deleteEpisode(input: $input) {
      id
    }
  }
`;

export const completeMediaFileUpload = /* GraphQL */ `
  mutation completeMediaFileUpload($input: CompleteMediaFileUploadInput!) {
    completeMediaFileUpload(input: $input) {
      id
      status
      fileName
      originUrl
      cdnUrl
    }
  }
`;

export const createAd = /* GraphQL */ `
  mutation createAd($input: CreateAdInput!) {
    createAd(input: $input) {
      id
    }
  }
`;

export const updateAd = /* GraphQL */ `
  mutation updateAd($input: UpdateAdInput!) {
    updateAd(input: $input) {
      id
    }
  }
`;

export const deleteAd = /* GraphQL */ `
  mutation deleteAd($input: DeleteAdInput!) {
    deleteAd(input: $input) {
      id
    }
  }
`;
