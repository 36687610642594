import * as types from "./types";

const initialState = {
  users: [],
  user: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USERS:
      const { listUsers } = action.payload;
      return {
        ...state,
        users: listUsers.items,
      };

    case types.GET_SUBSCRIBERS:
      const { listSubscribers } = action.payload;
      return {
        ...state,
        users: listSubscribers.items,
      };

    case types.SINGLE_USER:
      const { getUser } = action.payload;
      return {
        ...state,
        user: getUser,
      };

    default:
      return state;
  }
};

export default userReducer;
