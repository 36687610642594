import React, { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Stack,
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import AdminTemplate from "../../../../templates/global";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../store/actions";

const ProgramImgStyle = styled("img")({
  width: "100%",
});

const EpisodeDetails = () => {
  const episode = useSelector((state) => state.episode.episode);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const dispatch = useDispatch();

  let { episode_id } = useParams();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.episode.getEpisode(episode_id));
  }, [dispatch]);

  const renderMaturityIcons = (icons) => {
    let images = [];

    for (let i = 0; i < icons?.maturityIcons?.length; i++) {
      images.push(
        <Grid key={`icons${i}`} item xs={2} sm={1} md={2}>
          <Box>
            <ProgramImgStyle
              src={
                "/assets/client/images/" +
                icons.maturityIcons[i].toLowerCase() +
                ".png"
              }
              alt={icons.maturityIcons[i]}
            />
          </Box>
        </Grid>
      );
    }
    return images;
  };

  const handleDelete = () => {
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.episode.deleteEpisode(episode_id, episode.programId));
  };

  return (
    <AdminTemplate
      title={episode.title ? `${episode.title} - Episode` : "Yükleniyor..."}
    >
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
      <Button
          variant="text"
          component={RouterLink}
          to={`/admin/program/${episode.programId}/details`}
          startIcon={<ArrowBackIcon />}
        >
         Back to Program Details
        </Button>
        <Typography variant="h4" gutterBottom>
          Episode Details
        </Typography>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={3}
            pt={3}
            pb={8}
          >
            <Box
              sx={{
                position: "relative",
                minHeight: { xs: "5vh", md: "10vh" },
                width: "50%",
              }}
            >
              <CardMedia
                component="img"
                image={episode.videoThumbnailImageMediaUrl}
                alt="program image"
                sx={{
                  px: 1,
                }}
              />
            </Box>

            <Stack
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              pt={3}
            >
              <Typography variant="h5">{episode.title}</Typography>
              <Typography variant="h6" py={2}>
                Cast: {episode?.cast?.map((ct) => ct)}
              </Typography>
              <Grid container spacing={3}>
                {renderMaturityIcons(episode)}
              </Grid>
              <Typography variant="body2" pt={2}>
                {episode?.description}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={3}
                pt={3}
              >
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={`/admin/program/episode/${episode_id}/edit`}
                  startIcon={<ModeEditOutlineIcon />}
                >
                  Edit
                </Button>

                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  color="warning"
                  startIcon={<DeleteIcon />}
                  onClick={handleDelete}
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Delete"
                  )}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default EpisodeDetails;
