import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GlobalTemplate from "../../templates/global";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CardMedia, Stack, Box, Button, Fade } from "@mui/material";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions";

function Home() {
  const [imageA, setImageA] = useState(false);
  const [imageB, setImageB] = useState(false);
  const [imageC, setImageC] = useState(false);
  const [imageD, setImageD] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.defaultAction());
  }, [dispatch]);

  return (
    <GlobalTemplate title="Ana sayfa">
      <Container
        disableGutters
        maxWidth="xl"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          px: { xs: 2, md: 0 },
        }}
      >
        <Stack
          spacing={0}
          sx={{ flexDirection: { xs: "column", md: "row" }, width: "100%" }}
          justifyContent="center"
        >
          {/**Icerikler banner */}

          <Box
            sx={{
              backgroundColor: "#006ea8",
              position: "relative",
              minHeight: { xs: "17vh", md: "34vh" },
              width: "100%",
              mb: { xs: 2, md: 0 },
              mt: { xs: 2, md: 0 },
              "&:hover": {
                cursor: "pointer",
                ".MuiButton-contained": {
                  color: "#ffde00",
                  backgroundColor: "#000000",
                },
              },
            }}
          >
            <Fade in={imageA}>
              <Link to="/discover">
                <CardMedia
                  component="img"
                  image="/assets/client/images/icerikler.jpg"
                  alt="icerikler"
                  onLoad={() => setImageA(true)}
                />
              </Link>
            </Fade>
            <Box sx={{ position: "absolute", bottom: {xs: "25%", md: "35%"}, left: {xs: "5%", md: "10%"} }}>
              <Typography
                sx={{ fontWeight: "bold", mb: 5 }}
                color="text.secondary"
                variant={{xs: "h6", md: "h5"}}
              >
                İÇERİKLER
              </Typography>
              <Button
                fullWidth
                href="/discover"
                variant="contained"
                size="large"
                color="primary"
                sx={{ width: "75%" }}
              >
                İncele
              </Button>
            </Box>
          </Box>

          {/**Raffle banner */}
          <Box
            sx={{
              backgroundColor: "#fd740c",
              position: "relative",
              minHeight: { xs: "17vh", md: "34vh" },
              width: "100%",
              mb: { xs: 2, md: 0 },
              "&:hover": {
                cursor: "pointer",
                ".MuiButton-contained": {
                  color: "#ffde00",
                  backgroundColor: "#000000",
                },
              },
            }}
          >
            <Fade in={imageB}>
              <Link to="/raffle">
                <CardMedia
                  component="img"
                  image="/assets/client/images/raffle_poster.jpg"
                  alt="cekilisler"
                  onLoad={() => setImageB(true)}
                />
              </Link>
            </Fade>
            <Box sx={{ position: "absolute", bottom: "35%", left: "10%" }}>
              <Button
                fullWidth
                variant="contained"
                href="/raffle"
                size="large"
                color="primary"
                sx={{ width: "120%" }}
              >
                İncele
              </Button>
            </Box>
          </Box>
        </Stack>
        <Stack
          spacing={0}
          sx={{ flexDirection: { xs: "column", md: "row" }, width: "100%" }}
          justifyContent="center"
        >
          {/**Kazan banner */}
          <Box
            sx={{
              backgroundColor: "#570062",
              position: "relative",
              minHeight: { xs: "10vh", md: "20vh" },
              width: "100%",
              mb: { xs: 2, md: 0 },
              "&:hover": {
                cursor: "pointer",
                ".MuiButton-contained": {
                  color: "#ffde00",
                  backgroundColor: "#000000",
                },
              },
            }}
          >
            <Fade in={imageC}>
              <Link to="/profile">
                <CardMedia
                  component="img"
                  image="/assets/client/images/kazan.jpg"
                  alt="reklam izle"
                  onLoad={() => setImageC(true)}
                />
              </Link>
            </Fade>
          </Box>

          {/**Satin al banner */}
          <Box
            sx={{
              backgroundColor: "#1d357e",
              position: "relative",
              minHeight: { xs: "10vh", md: "20vh" },
              width: "100%",
              mb: { xs: 2, md: 0 },
              "&:hover": {
                cursor: "pointer",
                ".MuiButton-contained": {
                  color: "#ffde00",
                  backgroundColor: "#000000",
                },
              },
            }}
          >
            <Fade in={imageD}>
              <Link to="/plans">
                <CardMedia
                  component="img"
                  image="/assets/client/images/satinal.jpg"
                  alt="satin al"
                  onLoad={() => setImageD(true)}
                />
              </Link>
            </Fade>
            {/* <Box sx={{ position: "absolute", bottom: "5%", left: "5%" }}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                href="/plans"
                sx={{ width: "120%" }}
              >
                Premium Üye OI
              </Button>
            </Box> */}
          </Box>
        </Stack>
      </Container>
    </GlobalTemplate>
  );
}

export default Home;
