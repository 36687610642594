import React from "react";
import {
  Backdrop,
  Typography,
  Button,
  Modal,
  Grid,
  Fade,
  CircularProgress,
  Box,
} from "@mui/material";

export default function CardDeleteModal({
  open,
  loading,
  data,
  modalClose,
  modalStyle,
  deleteAction,
}) {
  return (
    <Modal
      aria-labelledby="transition-delete-title"
      aria-describedby="transition-delete-description"
      open={open}
      onClose={() => modalClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Typography id="transition-delte-title" variant="h6" component="h2">           
            <span style={{ color: "#ffed00" }}>{data.name}</span>
          </Typography>
          <Typography id="transition-delete-description" sx={{ mt: 2 }}>
            Bu kartı silmek istediğinizden emin misiniz?
          </Typography>
          <Grid container sx={{ mt: 2 }} spacing={5}>
            <Grid item xs={12} sm={6}>
              <Button
                id="transition-delete-button-1"
                fullWidth
                variant={"outlined"}
                onClick={(e) => {
                  e.preventDefault();
                  modalClose(false);
                }}
              >
                Vazgeç
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                id="transition-delete-button-2"
                fullWidth
                variant={"contained"}
                onClick={(e) => {
                  e.preventDefault();
                  deleteAction(data.id);
                }}
              >
                {loading ? <CircularProgress /> : "Sil"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
