import { applyMiddleware, createStore, compose } from "redux";
import allReducers from "./reducers";
import thunk from "redux-thunk";

const allowExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers = process.env.REACT_APP_STAGE == 'dev' ? allowExtension : compose;
export const myStore = createStore(
  allReducers,
  composeEnhancers(applyMiddleware(thunk))
);