import React from "react";
import Slider from "react-slick";
import { Link, CardMedia, Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { SliderSettings } from "../../../../../../styles/DefaultComponents";

const SliderList = ({ slider, title, keyIndex }) => {
  const renderImageBanner = () => {
    let images = [];

    for (let i = 0; i < slider.length; i++) {
      images.push(
        <Box
          key={`${keyIndex}slider${i}`}
          sx={{
            position: "relative",
            minHeight: { xs: "5vh", md: "10vh" },
            width: "100%",
            mb: { xs: 2, md: 0 },
            mt: { xs: 2, md: 0 },
          }}
        >
          <Link
            href={
              slider[i].episodesCount < 1
                ? "/coming"
                : `/program/${slider[i].id}/episode/0`
            }
          >
            <CardMedia
              component="img"
              image={slider[i].horizontalPosterImageMediaUrl}
              alt={slider[i].title}
              sx={{
                px: 1,
              }}
            />
          </Link>
        </Box>
      );
    }
    return images;
  };

  return (
    <>
      <Typography
        variant="h6"
        align="left"
        color="text.primary"
        component="h1"
        sx={{ mt: 5, mb: 2 }}
      >
        <CircleIcon color="primary" sx={{ fontSize: "12px" }} /> {title}
      </Typography>
      <Slider {...SliderSettings}>{renderImageBanner()}</Slider>
    </>
  );
};

export default SliderList;
