import * as types from "./types";

const initialState = {
  details: {},
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_DETAILS:
      return {
        ...state,
        details: action.payload,
      };

    default:
      return state;
  }
};

export default statisticsReducer;
