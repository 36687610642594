import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.default",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  ["@media (max-width:767px)"]: {
    width: "95%",
  },
};

export const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ffde00",
  },
  "& label": {
    color: "#fff",
  },
  "& label.MuiFormLabel-filled": {
    color: "#ffde00",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ffde00",
  },
  "& .MuiOutlinedInput-root": {
    input: {
      "&:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px #000 inset",
        "-webkit-text-fill-color": "#fff",
      },
    },
    "& fieldset": {
      borderColor: "#ffffffab",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&:hover.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffde00",
    },
  },
});

export const CssFormControl = styled(FormControl)({
  "& label.Mui-focused": {
    color: "#ffde00",
  },
  "& label": {
    color: "#fff",
  },
  "& svg": {
    color: "#fff",
  },
  "& label.MuiFormLabel-filled": {
    color: "#ffde00",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ffde00",
  },
  "& .MuiOutlinedInput-root": {
    input: {
      "&:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px #000 inset",
        "-webkit-text-fill-color": "#fff",
      },
    },
    "& fieldset": {
      borderColor: "#ffffffab",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&:hover.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffde00",
    },
  },
});

export const CssAutocomplete = styled(Autocomplete)({
  "& label.Mui-focused": {
    color: "#ffde00",
  },
  "& label": {
    color: "#fff",
  },
  "& svg": {
    color: "#fff",
  },
  "& label.MuiFormLabel-filled": {
    color: "#ffde00",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ffde00",
  },
  "& .MuiOutlinedInput-root": {
    input: {
      "&:-webkit-autofill": {
        "-webkit-box-shadow": "0 0 0 100px #000 inset",
        "-webkit-text-fill-color": "#fff",
      },
    },
    "& fieldset": {
      borderColor: "#ffffffab",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&:hover.Mui-error fieldset": {
      borderColor: "#d32f2f",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ffde00",
    },
  },
});

export const SliderSettings = {
  dots: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  lazyLoad: "progressive",
  className: "banana-slider",
  responsive: [
    {
      breakpoint: 1224,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};