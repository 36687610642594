import React, { useState } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import {
  Typography,
  List,
  Tooltip,
  Zoom,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import PreviewIcon from "@mui/icons-material/Preview";
import ConfirmDelete from "./ConfirmDelete";

export default function SectionAreaDrop({
  list,
  handlePreview,
  handleEdit,
  handleDelete,
  sectionId,
  resetPrograms,
}) {
  const [fields, setFields] = useState({ modal: false, id: "", title: "" });
  return (
    <>
      <ConfirmDelete
        name={fields["title"]}
        open={fields["modal"]}
        id={fields["id"]}
        handleDelete={handleDelete}
        modalClose={() => {
          setFields({ ...fields, modal: false });
        }}
      />
      <Droppable droppableId="sectionLists">
        {(provided) => (
          <List
            sx={{
              width: "100%",
              bgcolor: "#ffde0030 !important",
            }}
            component="nav"
            subheader={
              <Typography variant="h5" pl={2} pt={2}>
                Sections
              </Typography>
            }
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map(({ id, title, type, items }, index) => (
              <Draggable key={id} draggableId={id} index={index}>
                {(provided) => (
                  <ListItem
                    sx={{
                      bgcolor: "#ffde004d",
                      margin: 2,
                      width: "auto",
                      borderRadius: "5px",
                    }}
                    secondaryAction={
                      <>
                        <Tooltip
                          title="Preview programs"
                          TransitionComponent={Zoom}
                          followCursor
                        >
                          <IconButton
                            edge="start"
                            onClick={() => {
                              handlePreview(items);
                              sectionId({ id, title });
                              resetPrograms([], "programs");
                            }}
                            aria-label="preview"
                          >
                            <PreviewIcon sx={{ color: "#000" }} />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title="Edit"
                          TransitionComponent={Zoom}
                          followCursor
                        >
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handleEdit(title, type, id);
                            }}
                          >
                            <ModeEditOutlineIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          title="Delete"
                          TransitionComponent={Zoom}
                          followCursor
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => {
                              setFields({ modal: true, title, id });
                            }}
                          >
                            <DeleteIcon sx={{ color: "#ed6c02" }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ListItemText
                      primary={title}
                      secondary={type}
                      primaryTypographyProps={{
                        sx: {
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "60%",
                          textOverflow: "ellipsis",
                        },
                      }}
                    />
                  </ListItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </>
  );
}
