import TableChartIcon from "@mui/icons-material/TableChart";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import TheatersIcon from '@mui/icons-material/Theaters';

const NavConfig = [
  {
    title: "dashboard",
    path: "/admin/dashboard",
    icon: <TableChartIcon />,
  },
  {
    title: "media",
    path: "/admin/media",
    icon: <PermMediaIcon />,
  },
  {
    title: "program",
    path: "/admin/program/*",
    icon: <LiveTvIcon />,
    children: [
      {
        title: "view all",
        path: "/admin/program/all",
      },
      {
        title: "sections",
        path: "/admin/program/sections",
      },
    ],
  },
  {
    title: "raffle",
    icon: <ConfirmationNumberIcon />,
    path: "/admin/raffle/*",
    children: [
      {
        title: "in-display",
        path: "/admin/raffle/display",
      },
      {
        title: "archived",
        path: "/admin/raffle/archived",
      },
    ],
  },
  {
    title: "Watch Ads",
    path: "/admin/ads",
    icon: <TheatersIcon />,
  },
  {
    title: "Packages",
    path: "/admin/packages",
    icon:  <img src="/assets/client/images/banana.png" alt="Banana image" width='50%' />,
  },

  {
    title: "influencer",
    path: "/admin/influencer",
    icon: <RecordVoiceOverIcon />,
  },

  {
    title: "user",
    path: "/admin/user",
    icon: <PeopleAltIcon />,
    children: [
      {
        title: "view all",
        path: "/admin/user/all",
      },
      {
        title: "subscriber",
        path: "/admin/user/subscriber",
      },
    ],
  },
];

export default NavConfig;
