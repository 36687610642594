import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Label from "../../../../../../components/admin/label";

const columns = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  {
    id: "gsm",
    label: "Phone Number",
  },
  {
    id: "subscription",
    label: "Subscription",
  },
];

export default function TableSignedUsers({
  data,
  totalPages,
  page,
  rows,
  rowChange,
  pageChange,
}) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.id == "name") {
                      return (
                        <TableCell
                          key={column.id}
                        >{`${row["givenName"]} ${row["familyName"]}`}</TableCell>
                      );
                    }
                    if (column.id == "subscription") {
                      return (
                        <TableCell key={column.id}>
                          <Label
                            variant="filled"
                            color={value == null ? "warning" : "primary"}
                          >
                            {value == null ? "None" : "Purchased"}
                          </Label>
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell key={column.id}>
                        {value != null ? value : "+90 ---"}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={totalPages}
        rowsPerPage={rows}
        page={page}
        onPageChange={pageChange}
        onRowsPerPageChange={rowChange}
      />
    </Paper>
  );
}
