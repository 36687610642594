import React, { useEffect } from "react";
import { Grid, Container, Typography, CircularProgress } from "@mui/material";
import AdminTemplate from "../../templates/global";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions";
import { abbreviateNumber } from "../../../../hooks/useAbbreviation";
import WidgetSummary from "./components/WidgetSummary";
import WebsiteVisits from "./components/WebsiteVisits";
import PieChart from "../../../../components/admin/pieChart/PieChart";

import HubIcon from "@mui/icons-material/Hub";
import PaidIcon from "@mui/icons-material/Paid";
import GroupIcon from "@mui/icons-material/Group";
import LoginIcon from "@mui/icons-material/Login";

const Dashboard = () => {
  const details = useSelector((state) => state.statistics.details);
  const profile = useSelector((state) => state.default.profile);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.statistics.getDashboardDetails());
  }, [dispatch]);

  return (
    <AdminTemplate title="Dashboard">
      <Container maxWidth="false" sx={{ maxWidth: "1600px" }}>
        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Hi {profile?.given} {profile?.family}, Welcome back
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <WidgetSummary
                  title="Active Users"
                  total={abbreviateNumber(details?.loggedInUserCount)}
                  bgColor="#ffde004d"
                  icon={<LoginIcon width={24} height={24} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <WidgetSummary
                  title="Active Referrals"
                  total={abbreviateNumber(
                    details?.validUserCountWithReferralCount
                  )}
                  bgColor="#1769aa4f"
                  color="info"
                  icon={<HubIcon width={24} height={24} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <WidgetSummary
                  title="Subscriptions"
                  total={abbreviateNumber(details?.validSubCount)}
                  bgColor="#e6510040"
                  color="warning"
                  icon={<PaidIcon width={24} height={24} />}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <WidgetSummary
                  title="Total Users"
                  total={abbreviateNumber(details?.userCount)}
                  bgColor="#c6282859"
                  color="error"
                  icon={<GroupIcon width={24} height={24} />}
                />
              </Grid>

              {/* <Grid item xs={12} md={6} lg={8}>
                        <WebsiteVisits
                            title="Website Visits"
                            subheader="(+43%) than last year"
                            chartLabels={[
                                '01/01/2003',
                                '02/01/2003',
                                '03/01/2003',
                                '04/01/2003',
                                '05/01/2003',
                                '06/01/2003',
                                '07/01/2003',
                                '08/01/2003',
                                '09/01/2003',
                                '10/01/2003',
                                '11/01/2003',
                            ]}
                            chartData={[
                                {
                                    name: 'Team A',
                                    type: 'column',
                                    fill: 'solid',
                                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                                },
                                {
                                    name: 'Team B',
                                    type: 'area',
                                    fill: 'gradient',
                                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                                },
                                {
                                    name: 'Team C',
                                    type: 'line',
                                    fill: 'solid',
                                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                                },
                            ]}
                        />
                    </Grid> */}

              {/* <Grid item xs={12} md={6} lg={4}>
                        <PieChart
                            title="Traffic Sources"
                            chartData={[
                                { label: 'Facebook', value: 4344 },
                                { label: 'Google', value: 5435 },
                                { label: 'Twitter', value: 1443 },
                                { label: 'Instagram', value: 4443 },
                            ]}
                            chartColors={[
                                '#C0F2DC',
                                '#2D99FF',
                                '#826AF9',
                                '#FFE700',
                            ]}
                        />
                    </Grid> */}
            </Grid>
          </>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default Dashboard;
