import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Navbar from '../../../components/admin/navbar';
import Sidebar from '../../../components/admin/sidebar';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}));

// ----------------------------------------------------------------------


function AdminTemplate({ title, children }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        document.title = title ? `${title} | Bananativi Admin` : "Bananativi Admin";
    }, [title]);

    return (
        <RootStyle>
            <Navbar onOpenSidebar={() => setOpen(true)} />
            <Sidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle>
                {children}
            </MainStyle>
        </RootStyle>
    );
}

export default AdminTemplate;
