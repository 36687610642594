import React from "react";
import { Box, Card, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const ProgramImgStyle = styled("img")({
  width: "100%",
});

// ----------------------------------------------------------------------

const ProgramCard = ({ progr }) => {
  const { title, verticalPosterImageMediaUrl } = progr;
  return (
    <Card
      sx={{
        bgcolor: "#ffde004d",
      }}
    >
      <Box>
        <ProgramImgStyle alt={title} src={verticalPosterImageMediaUrl} />
      </Box>

      <Stack spacing={2} sx={{ p: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {title}
        </Typography>
      </Stack>
    </Card>
  );
};

export default ProgramCard;
