import React from "react";
import {
  Backdrop,
  Typography,
  Button,
  Modal,
  Grid,
  Fade,
  CircularProgress,
  Box,
} from "@mui/material";
import TableSignedUsers from "./TableSignedUsers";

export default function UserModal({
  open,
  loading,
  data,
  name,
  modalClose,
  modalStyle,
  page,
  totalPages,
  rows,
  rowChange,
  pageChange,
}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => modalClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ pb: 4 }}
          >
            Signed Users for <span style={{ color: "#ffed00" }}>{name}</span>
          </Typography>
          {loading ? (
            <Grid
              container
              component="main"
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ height: "50vh" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <TableSignedUsers
              data={data}
              totalPages={totalPages}
              page={page}
              rows={rows}
              rowChange={rowChange}
              pageChange={pageChange}
            />
          )}

          <Grid container justifyContent={"flex-end"}>
            <Button
              id="transition-modal-button"
              onClick={() => modalClose(false)}
            >
              Close
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
