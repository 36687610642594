import React from "react";
import {
  Backdrop,
  Typography,
  Button,
  Modal,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Fade,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  modalStyle,
  CssTextField,
} from "../../../../../../styles/DefaultComponents";

export default function BananaModal({
  open,
  buttonLoading,
  user,
  modalClose,
  setAmount,
  amount,
  handleSubmit,
}) {
  const min = 0;
  const max = 20;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => modalClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            sx={{ pb: 4 }}
          >
            User: {user?.givenName} {user?.familyName}
          </Typography>

          <CssTextField
            type="number"
            margin="normal"
            size="small"
            fullWidth
            variant="outlined"
            id="amount"
            inputProps={{ min, max }}
            label="Banana Amount"
            value={amount}
            onChange={(e) => {
              let value =
                e.target.value == "" ? min : parseInt(e.target.value, 10);

              if (value > max) value = max;
              if (value < min) value = min;

              setAmount(value);
            }}
            name="amount"
            helperText="Range [ 0 - 20 ]"
          />

          <Grid container sx={{ mt: 2 }} spacing={5}>
            <Grid item xs={12} sm={6}>
              <Button
                id="transition-modal-deny-button"
                fullWidth
                variant={"outlined"}
                onClick={(e) => {
                  e.preventDefault();
                  setAmount(min);
                  modalClose(false);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                id="transition-modal-aggre-button"
                fullWidth
                variant={buttonLoading ? "outlined" : "contained"}
                onClick={handleSubmit}
              >
                {buttonLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Add Bananas"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}
