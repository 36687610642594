import React from "react";
import { Link } from "react-router-dom";
import { Typography, CardMedia, Box, Button } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Moment from "react-moment";
import "moment/locale/tr";
import Slider from "react-slick";
import { SliderSettings } from "../../../../../../styles/DefaultComponents";

const RaffleSlider = ({ lists }) => {
  const toUpperCaseFilter = (d) => {
    return d.toUpperCase();
  };

  const renderRaffle = () => {
    let raffleItems = [];

    for (let i = 0; i < lists.length; i++) {
      const date = new Date(lists[i].endTime);
      const dateNow = Date.now();

      if (!lists[i].isArchived) {
        raffleItems.push(
          <Box
            key={`raffle-item${i}`}
            sx={{
              position: "relative",
              minHeight: { xs: "15vh", md: "30vh" },
              width: "100%",
              px: 2,
              cursor: "pointer",
            }}
          >
            <Link
              to={`/raffle/details/${lists[i].id}`}
              style={{ textDecoration: "none" }}
            >
              <CardMedia
                component="img"
                image={lists[i].verticalPosterImageMediaUrl}
                alt={lists[i].title}
              />

              {dateNow > date ? (
                <>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      my: 2,
                      textDecoration: "none",
                      borderColor: "transparent",
                      boxShadow: "rgb(255 255 255 / 44%) 0px 0px 2px 0px",
                      "&:hover": {
                        borderColor: "transparent",
                      },
                    }}
                  >
                    ÇEKİLİŞ SONA ERMİŞTİR
                  </Button>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      mb: 2,
                      borderColor: "transparent",
                      boxShadow: "rgb(255 255 255 / 44%) 0px 0px 2px 0px",
                      "&:hover": {
                        borderColor: "transparent",
                      },
                    }}
                  >
                    ÇEKİLİŞ SONUÇLARI
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      my: 2,
                    }}
                  >
                    ÇEKİLİŞ TARİHİ
                    <Moment
                      locale="tr"
                      filter={toUpperCaseFilter}
                      element="span"
                      format="DD MMMM"
                      style={{ marginLeft: "5px" }}
                    >
                      {date}
                    </Moment>
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{
                      mb: 2,
                    }}
                  >
                    DETAYLARI INCELE
                  </Button>
                </>
              )}
            </Link>
          </Box>
        );
      }
    }
    return raffleItems;
  };

  return (
    <>
      <Typography
        variant="h6"
        align="left"
        color="text.primary"
        component="h1"
        sx={{ mt: 5, mb: 2 }}
      >
        <CircleIcon color="primary" sx={{ fontSize: "12px" }} /> Çekilişler
      </Typography>

      <Slider {...SliderSettings}>{renderRaffle()}</Slider>
    </>
  );
};

export default RaffleSlider;
