import React, { useEffect, useState } from "react";
import {
  Typography,
  Container,
  Stack,
  Box,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import {
  CssTextField,
  CssAutocomplete,
} from "../../../../../styles/DefaultComponents";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

//---------------DUMMY DATA START-------------------//

const categories = [
  { label: "REALITY" },
  { label: "COMEDY" },
  { label: "COMPETITION" },
  { label: "MUSIC" },
  { label: "SERIES" },
  { label: "DOCUMENTARY" },
  { label: "KIDS" },
  { label: "VLOG" },
  { label: "NOSTALGIA" },
  { label: "MOVIE" },
];

//---------------DUMMY DATA END-------------------//

const NewProgram = () => {
  const [fields, setFields] = useState({
    title: "",
    category: "",
    bannerUrl: "",
    verticalUrl: "",
    horizontalUrl: "",
  });
  const uiLoading = useSelector((state) => state.default.loading.container);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const medias = useSelector((state) => state.media.medias);
  const dispatch = useDispatch();

  const imageURLS = medias.map((media) => {
    return { label: media.fileName, url: media.cdnUrl };
  });

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.media.getMedias(["IMAGE"], ["DISTRIBUTED"]));
  }, [dispatch]);

  const handleChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.program.createProgram(
        fields["bannerUrl"].url,
        fields["category"].label,
        fields["horizontalUrl"].url,
        fields["title"],
        fields["verticalUrl"].url
      )
    );
  };

  return (
    <AdminTemplate title="New Program">
      <Container
        maxWidth="false"
        sx={{ maxWidth: "1950px" }}
        className="custom-popper"
      >
        <Typography variant="h4" gutterBottom>
          New Program
        </Typography>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <CssTextField
              margin="normal"
              size="small"
              required
              fullWidth
              variant="outlined"
              id="title"
              label="Title"
              name="title"
              onChange={(e) =>
                setFields({ ...fields, [e.target.name]: e.target.value })
              }
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={categories}
                  onChange={(_, value) => handleChange(value, "category")}
                  value={fields["category"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Category"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  onChange={(_, value) => handleChange(value, "bannerUrl")}
                  value={fields["bannerUrl"]}
                  options={imageURLS}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      required
                      size="small"
                      fullWidth
                      label="Banner Image URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  onChange={(_, value) => handleChange(value, "verticalUrl")}
                  value={fields["verticalUrl"]}
                  options={imageURLS}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      required
                      size="small"
                      fullWidth
                      label="Vertical Image URL"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={imageURLS}
                  onChange={(_, value) => handleChange(value, "horizontalUrl")}
                  value={fields["horizontalUrl"]}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      required
                      size="small"
                      fullWidth
                      label="Horizontal Image URL"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={8}
              lg={4}
              mt={5}
              xl={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-end" },
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "20%" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  href="/admin/program/all"
                >
                  Cancel
                </Button>

                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  fullWidth
                  type="submit"
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Stack>
            </Grid>
          </Box>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default NewProgram;
