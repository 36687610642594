import * as React from "react";
import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import AppRoutes from "./router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { myStore } from "./store";
import themeOptions from "./styles/theme";
import * as serviceWorker from "./serviceWorker";

//Styles
import "./styles/index.css";
import "./styles/animate.css";

const container = document.getElementById("root");
const root = createRoot(container);
const theme = createTheme(themeOptions);

root.render(
  <React.Fragment>
    <BrowserRouter>
      <Provider store={myStore}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
