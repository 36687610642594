import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import GlobalTemplate from "../../../templates/global";
import RafflePlayer from "./components/rafflePlayer";
import {
  Container,
  CardMedia,
  Typography,
  Box,
  Skeleton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ButtonGroup,
  Button,
  Grid,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Moment from "react-moment";
import "moment/locale/tr";
import { useCountdown } from "../../../../../hooks/useCountdown";
import * as actions from "../../../../../store/actions";

const DetailRaffle = () => {
  const [bananas, setBananas] = useState(0);
  const [isVideoEnd, setVideoEnd] = useState(true);
  const [countdownTime, setCountdownTime] = useState(moment());
  const [hours, minutes, seconds] = useCountdown(countdownTime);
  const [isRaffleValid, setValidity] = useState(false);

  const raffle = useSelector((state) => state.raffle.raffle);
  const curBalance = useSelector((state) => state.default.profile.balance);
  const sub = useSelector((state) => state.default.profile.subscription);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const dispatch = useDispatch();

  let { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.raffle.getRaffle(id));
  }, [dispatch]);

  useEffect(() => {
    setCountdownTime(raffle.endTime);
    if (
      moment(raffle.startTime).isValid() &&
      moment(raffle.endTime).isValid() &&
      moment(raffle.startTime).isBefore(moment.now()) &&
      moment(raffle.endTime).isAfter(moment.now())
    ) {
      setValidity(true);
      if (raffle?.userTickets.length < 1) {
        setVideoEnd(false);
      }
    }
  }, [raffle]);

  const toUpperCaseFilter = (d) => {
    return d.toUpperCase();
  };

  const enterRaffle = async () => {
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.raffle.joinRaffle(raffle?.id, bananas));
    dispatch(actions.dActions.defaultAction());
  };

  return (
    <GlobalTemplate title={raffle.title ? raffle.title : "Yükleniyor..."}>
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "100%",
          px: { xs: 2, md: 0 },
          pb: { md: 10 },
        }}
      >
        <Box
          sx={{
            minHeight: { xs: "15vh", md: "30vh" },
            my: { xs: 4, md: 6, lg: 10 },
          }}
        >
          {raffle.promoVideoMediaUrl ? (
            <RafflePlayer
              video={raffle.promoVideoMediaUrl}
              className="banana-player"
              color="ffde00"
              height="564"
              onEnd={() => setVideoEnd(true)}
            />
          ) : (
            <Skeleton
              sx={{
                bgcolor: "#101010",
                width: { xs: "95vw", md: "90vw", xl: "80vw" },
                height: { xs: "30vh", sm: "45vh", md: "50vh", lg: "60vh" },
              }}
              variant="rectangular"
            />
          )}
        </Box>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "22px" }}
          >
            <CircularProgress />
          </Grid>
        ) : isVideoEnd ? (
          <>
            <Typography variant="h5" component="h3">
              {!moment(raffle?.startTime).isBefore(moment.now()) ? (
                <>
                  ÇEKİLİŞE KATILIM{" "}
                  <Moment
                    locale="tr"
                    filter={toUpperCaseFilter}
                    element="span"
                    format="DD MMMM YYYY"
                    style={{ color: "#ffde00" }}
                  >
                    {raffle.startTime}
                  </Moment>{" "}
                  TARİHİNDE BAŞLAYACAKTIR
                </>
              ) : moment(raffle.endTime).isAfter(moment.now()) ? (
                moment.duration(moment(raffle.endTime).diff(moment.now())).asHours() < 72 ? (
                  <>
                    ÇEKİLİŞİN BİTMESİNE{" "}
                    <span style={{ color: "#ffde00" }}>
                      {hours + ":" + minutes + ":" + seconds}
                    </span>
                  </>
                ) : null
              ) : (
                "ÇEKİLİŞE KATILIM SONA ERDİ"
              )}
            </Typography>

            <Box>
              <CardMedia
                component="img"
                image={raffle?.sponsorBannerMediaUrl}
                alt={raffle?.title}
                sx={{
                  height: "10vh",
                }}
              />
            </Box>

            {raffle.winnerTickets?.length > 0 ? (
              <>
                <List
                  subheader={
                    <ListSubheader
                      disableSticky={Boolean(true)}
                      sx={{ bgcolor: "transparent" }}
                    >
                      KAZANANLAR
                    </ListSubheader>
                  }
                >
                  {Object.entries(raffle?.winnerTickets).map(([key, value]) => (
                    <ListItem disablePadding key={key}>
                      <ListItemText
                        primary={`${value.givenName} ${value.familyName}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            ) : null}

            {moment(raffle.endTime).isAfter(moment.now()) ? (
              <>
                {raffle?.userTickets?.length > 0 ? (
                  <Typography variant="subtitle1" component="h3" sx={{ pt: 3 }}>
                    ÇEKİLİŞE
                    <span style={{ color: "#ffde00" }}>
                      {" "}
                      {raffle?.userTickets?.length} BANANA{" "}
                    </span>
                    İLE KATILDINIZ, ÇOK BANANA - ÇOK ŞANS
                  </Typography>
                ) : null}

                <ButtonGroup
                  size="large"
                  variant="contained"
                  sx={{
                    py: 3,
                    filter: isRaffleValid ? "none" : "saturate(0.2)",
                  }}
                >
                  <Button
                    onClick={
                      isRaffleValid && bananas > 0
                        ? (e) => {
                            e.preventDefault();
                            if (bananas == 0) {
                              return;
                            }
                            setBananas(bananas - 1);
                          }
                        : null
                    }
                    variant={bananas == 0 ? "outlined" : "contained"}
                    disableRipple={!isRaffleValid || bananas == 0}
                    sx={{
                      cursor:
                        isRaffleValid && bananas > 0
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#101010 !important",
                      color: "#ffde00 !important",
                    }}
                    disabled
                    endIcon={
                      <CardMedia
                        component="img"
                        image={"/assets/client/images/banana.png"}
                        alt={"banana image"}
                        sx={{
                          height: "22px",
                        }}
                      />
                    }
                  >
                    {bananas}
                  </Button>
                  <Button
                    onClick={
                      isRaffleValid && bananas < curBalance
                        ? (e) => {
                            e.preventDefault();
                            if (bananas >= curBalance) {
                              return;
                            }
                            setBananas(bananas + 1);
                          }
                        : null
                    }
                    variant={bananas == curBalance ? "outlined" : "contained"}
                    disableRipple={!isRaffleValid || bananas == curBalance}
                    sx={{
                      cursor:
                        isRaffleValid && bananas < curBalance
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    onClick={
                      isRaffleValid && bananas > 0
                        ? (e) => {
                            e.preventDefault();
                            if (bananas == 0) {
                              return;
                            }
                            if (
                              !raffle?.isPublic &&
                              moment(sub?.validBefore).isValid() &&
                              moment(sub?.validBefore).isBefore(moment.now())
                            ) {
                              navigate("/plans");
                            } else if (
                              !raffle?.isPublic &&
                              (sub == null || sub?.validBefore == null)
                            ) {
                              navigate("/plans");
                            } else {
                              enterRaffle();
                            }
                          }
                        : null
                    }
                    disableRipple={!isRaffleValid || bananas < 1}
                    variant={
                      bananas <= 0 || buttonLoading ? "outlined" : "contained"
                    }
                    sx={{
                      cursor:
                        isRaffleValid && bananas > 0
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    {buttonLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Çekilişe Katıl"
                    )}
                  </Button>
                </ButtonGroup>
              </>
            ) : null}
          </>
        ) : (
          <Typography variant="h5" component="h3">
            VİDEOYU SONUNA KADAR İZLE, ÇEKILIŞ BUTONU AÇILSIN! :)
          </Typography>
        )}
      </Container>
    </GlobalTemplate>
  );
};

export default DetailRaffle;
