import { API } from "aws-amplify";
import * as types from "./types";
import * as tMedia from "../media/types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get banana packages */
export const getBananaPackages = () => async (dispatch) => {
  try {
    const bananaPackagesQuery = API.graphql({
      query: queries.listAdminPackages,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          pagination: { page: 1, size: 200 },
          fileStatuses: ["DISTRIBUTED"],
          fileTypes: ["IMAGE"],
        },
      },
    });

    const bananaPackages = await bananaPackagesQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: bananaPackages.data.me,
    });

    dispatch({
      type: tMedia.GET_MEDIAS,
      payload: bananaPackages.data,
    });

    dispatch({
      type: types.BANANA_PACKAGES,
      payload: bananaPackages.data.bananaPackages,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* List purchase history */
export const getPurchaseHistory = () => async (dispatch) => {
  try {
    const purchaseHistoryQuery = API.graphql({
      query: queries.listHistory,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          pagination: { page: 1, size: 100 },
        },
      },
    });

    const purchaseHistory = await purchaseHistoryQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: purchaseHistory.data.me,
    });
    dispatch({
      type: types.PURCHASE_HISTORY,
      payload: purchaseHistory.data.listBananaPurchases.items,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get payment params and banana packages */
export const getPaymentParamsAndBananaPackages = () => async (dispatch) => {
  try {
    const paymentParamsAndBananaPackagesQuery = API.graphql({
      query: queries.paymentParamsAndBananaPackages,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {},
    });

    const paymentParamsAndBananaPackages =
      await paymentParamsAndBananaPackagesQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: paymentParamsAndBananaPackages.data.me,
    });
    dispatch({
      type: types.BANANA_PACKAGES,
      payload: paymentParamsAndBananaPackages.data.bananaPackages,
    });
    dispatch({
      type: types.PAYMENT_PARAMS,
      payload: paymentParamsAndBananaPackages.data.paymentParams,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Create banana package */
export const createBananaPackage =
  (amount, price, iconUrl) => async (dispatch) => {
    try {
      const createPackageQuery = API.graphql({
        query: mutations.createPackage,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            amount,
            price,
            iconUrl,
          },
        },
      });

      await createPackageQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.reload();
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* update banana package  */
export const updateBananaPackage =
  (amount, iconUrl, price, id) => async (dispatch) => {
    try {
      const updatePackageQuery = API.graphql({
        query: mutations.updatePackage,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            amount,
            price,
            iconUrl,
            id,
          },
        },
      });

      await updatePackageQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.reload();
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* delete banana package  */
export const deleteBananaPackage = (id) => async (dispatch) => {
  try {
    const deletePackageQuery = API.graphql({
      query: mutations.deletePackage,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await deletePackageQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};
