import React from "react";
import {
  Chip,
  Zoom,
  Button,
  Tooltip,
  Grid,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import {
  CssAutocomplete,
  CssTextField,
} from "../../../../../../styles/DefaultComponents";

export default function ProgramSearch({
  id,
  handleChange,
  handleAddPrograms,
  selectedPrograms,
}) {
  const programs = useSelector((state) => state.program.programs);
  const buttonLoading = useSelector((state) => state.default.loading.button);

  const programLabels = programs.map((progr) => {
    return { label: progr.title, id: progr.id };
  });

  return (
    <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
      <Grid item xs={9} sm={9} md={9}>
        <CssAutocomplete
          multiple
          disabled={id == null}
          options={programLabels}
          value={selectedPrograms}
          isOptionEqualToValue={(option, value) => option.label === value.label}
          filterSelectedOptions
          onChange={(_, value) => handleChange(value, "programs")}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                color="primary"
                variant="outlined"
                label={option.label}
                {...getTagProps({
                  index,
                })}
              />
            ))
          }
          renderInput={(params) => (
            <CssTextField
              {...params}
              label="New Programs"
              placeholder="Add to section"
              margin="normal"
              size="small"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        <Tooltip
          title="To add programs to section, first preview the specific section"
          TransitionComponent={Zoom}
          disableHoverListener={id != null}
          followCursor
        >
          <Button
            variant={id != null && !buttonLoading ? "contained" : "outlined"}
            onClick={id != null ? handleAddPrograms : null}
            disableRipple={id == null}
            startIcon={<AddIcon />}
            sx={{
              mt: 2,
              "&:hover": {
                cursor: id == null ? "default" : "pointer",
              },
            }}
          >
            {buttonLoading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              "Add"
            )}
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
