import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

//Analytics
import * as ga from "../config/analytics";

//private component
import PrivateRoute from "../components/routing/PrivateRoute";

/*----------- CLIENT SIDE-----------*/
// Auth routes
import SignIn from "../pages/client/views/auth/signin";
import SignUp from "../pages/client/views/auth/signup";
import Recover from "../pages/client/views/auth/recover";
import Verify from "../pages/client/views/auth/verify";

import EmailVerify from "../pages/client/templates/email_verify";
import EmailCode from "../pages/client/templates/email_code";

// View routes
import Home from "../pages/client/views/home";
import About from "../pages/client/views/about";
import Support from "../pages/client/views/support";
import Faq from "../pages/client/views/faq";
import Coming from "../pages/client/views/coming";
import Profile from "../pages/client/views/profile";
import Plans from "../pages/client/views/subscription/plans";
import MainRaffle from "../pages/client/views/raffle/main";
import DetailRaffle from "../pages/client/views/raffle/details";
import Discover from "../pages/client/views/discover/main";
import Program from "../pages/client/views/discover/program";
import NotFound from "../pages/404";

// watchads routes
import WatchAdsMain from "../pages/client/views/watchAds/main";
import WatchAdDetails from "../pages/client/views/watchAds/details";

// Purchase routes
import PurchaseMain from "../pages/client/views/purchase/main";
import PurchaseHistory from "../pages/client/views/purchase/history";
import PurchaseError from "../pages/client/views/purchase/error";

//Subscription routes
import Paynet from "../pages/client/views/subscription/paynet";
import SubscriptionCallback from "../pages/client/views/subscription/callback";

/*----------- ADMIN SIDE-----------*/
// View routes
import Dashboard from "../pages/admin/views/dashboard";
import Media from "../pages/admin/views/media/main";
import MediaUpload from "../pages/admin/views/media/upload";
import AdminProgram from "../pages/admin/views/program/main";
import AdminRaffle from "../pages/admin/views/raffle/display";
import RaffleArchived from "../pages/admin/views/raffle/archived";
import Users from "../pages/admin/views/users/main";
import Subscribers from "../pages/admin/views/users/subscriber";
import Influencer from "../pages/admin/views/influencer/main";
import NewProgram from "../pages/admin/views/program/new";
import ProgramDetails from "../pages/admin/views/program/details";
import EditProgram from "../pages/admin/views/program/edit";
import EpisodeDetails from "../pages/admin/views/program/episode/details";
import ParticipantsRaffle from "../pages/admin/views/raffle/participants";
import NewRaffle from "../pages/admin/views/raffle/new";
import EditRaffle from "../pages/admin/views/raffle/edit";
import NewInfluencer from "../pages/admin/views/influencer/new";
import EditInfluencer from "../pages/admin/views/influencer/edit";
import NewEpisode from "../pages/admin/views/program/episode/new";
import EditEpisode from "../pages/admin/views/program/episode/edit";
import ProgramSections from "../pages/admin/views/program/section";

// Package route
import AdminPackages from "../pages/admin/views/packages";

// watchads route
import AdminWatchAd from "../pages/admin/views/watchAds/main";
import NewWatchAd from "../pages/admin/views/watchAds/new";
import EditWatchAd from "../pages/admin/views/watchAds/edit";

const AppRoutes = () => {
  let location = useLocation();

  useEffect(() => {
    ga.pageview(location.pathname);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="email-verify" element={<EmailVerify />} />
        <Route path="verifyEmailCode" element={<EmailCode />} />
        <Route path="about" element={<About />} />
        <Route path="support" element={<Support />} />
        <Route path="faq" element={<Faq />} />
        <Route path="coming" element={<Coming />} />
        <Route path="profile" element={<Profile />} />
        <Route path="plans" element={<Plans />} />
        <Route path="raffle" element={<MainRaffle />} />
        <Route path="raffle/details/:id" element={<DetailRaffle />} />
        <Route path="discover" element={<Discover />} />

        <Route path="watchads" element={<WatchAdsMain />} />
        <Route path="watchad/details/:id" element={<WatchAdDetails />} />

        <Route path="purchase" element={<PurchaseMain />} />
        <Route path="purchase/history" element={<PurchaseHistory />} />
        <Route path="purchase/error" element={<PurchaseError />} />

        <Route
          path="program/:program_id/episode/:episode_id"
          element={<Program />}
        />
      </Route>

      <Route path="/auth" element={<PrivateRoute redirect="auth" />}>
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="recover" element={<Recover />} />
        <Route path="verify" element={<Verify />} />
        <Route path="*" element={<NotFound redirect="/auth/signin" />} />
      </Route>

      <Route path="/admin" element={<PrivateRoute redirect="admin" />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="media" element={<Media />} />
        <Route path="media/upload" element={<MediaUpload />} />
        <Route path="program/all" element={<AdminProgram />} />
        <Route path="raffle/display" element={<AdminRaffle />} />
        <Route path="raffle/archived" element={<RaffleArchived />} />
        <Route path="influencer" element={<Influencer />} />
        <Route path="user/all" element={<Users />} />
        <Route path="user/subscriber" element={<Subscribers />} />
        <Route path="program/new" element={<NewProgram />} />
        <Route
          path="program/:program_id/details"
          element={<ProgramDetails />}
        />
        <Route path="program/:program_id/edit" element={<EditProgram />} />
        <Route
          path="program/:program_id/episode/new"
          element={<NewEpisode />}
        />
        <Route
          path="program/episode/:episode_id/details"
          element={<EpisodeDetails />}
        />
        <Route
          path="program/episode/:episode_id/edit"
          element={<EditEpisode />}
        />
        <Route path="raffle/new" element={<NewRaffle />} />
        <Route
          path="raffle/:raffle_id/participants"
          element={<ParticipantsRaffle />}
        />
        <Route path="raffle/:raffle_id/edit" element={<EditRaffle />} />
        <Route path="influencer/new" element={<NewInfluencer />} />
        <Route
          path="influencer/:influencer_id/edit"
          element={<EditInfluencer />}
        />
        <Route path="program/sections" element={<ProgramSections />} />

        <Route path="packages" element={<AdminPackages />} />

        <Route path="ads" element={<AdminWatchAd />} />
        <Route path="watchad/new" element={<NewWatchAd />} />
        <Route path="watchad/:ad_id/edit" element={<EditWatchAd />} />

        <Route path="*" element={<NotFound redirect="/admin/dashboard" />} />
      </Route>

      <Route path="paynet" element={<Paynet />} />
      <Route path="subscription-callback" element={<SubscriptionCallback />} />

      <Route path="*" element={<NotFound redirect="/" />} />
    </Routes>
  );
};

export default AppRoutes;
