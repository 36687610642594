import * as types from "./types";

const initialState = {
  paymentParams: {},
  bananaPackages: [],
  purchaseHistory: [],
};

const purchaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENT_PARAMS:
      return {
        ...state,
        paymentParams: action.payload,
      };

    case types.BANANA_PACKAGES:
      return {
        ...state,
        bananaPackages: action.payload,
      };

    case types.PURCHASE_HISTORY:
      return {
        ...state,
        purchaseHistory: action.payload,
      };

    default:
      return state;
  }
};

export default purchaseReducer;
