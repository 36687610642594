import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Backdrop,
  Modal,
  Box,
  CircularProgress,
  Typography,
  Grid,
  Button,
  Fade,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import PublishIcon from "@mui/icons-material/Publish";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../store/actions";
import { modalStyle } from "../../../../../../styles/DefaultComponents";

export default function AdMoreMenu({ id, name, published }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalShow, setModalShow] = useState({ show: false, type: "" });
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const dispatch = useDispatch();

  const handleArchive = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    if (published) {
      dispatch(actions.watchAd.unPublishAd(id));
    } else {
      dispatch(actions.watchAd.publishAd(id));
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.watchAd.deleteAd(id));
  };

  return (
    <>
      {/* Modal Section */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalShow["show"]}
        onClose={() => setModalShow({ ...modalShow, show: false })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalShow["show"]}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ pb: 2 }}
            >
              {modalShow["type"] == "archive"
                ? published
                  ? "Unpublish"
                  : "Publish"
                : "Delete"}{" "}
              Ad with reward(s) of {name}🍌
            </Typography>
            <Divider
              sx={{
                borderStyle: "groove",
                borderColor:
                  modalShow["type"] == "archive" ? "#ffde00" : "#ed6c02",
              }}
            />
            <Typography variant="h6" component="h4" sx={{ py: 4 }}>
              Are you sure you want to{" "}
              {modalShow["type"] == "archive"
                ? published
                  ? "unpublish"
                  : "publish"
                : "delete"}{" "}
              Ad with reward(s) of{" "}
              <span
                style={{
                  color: modalShow["type"] == "archive" ? "#ffde00" : "#ed6c02",
                }}
              >
                {name}🍌
              </span>
              ?
            </Typography>
            <Grid container justifyContent={"flex-end"}>
              <Button
                id="transition-modal-button"
                onClick={
                  modalShow["type"] == "archive" ? handleArchive : handleDelete
                }
                variant={buttonLoading ? "outlined" : "contained"}
                color={modalShow["type"] == "archive" ? "primary" : "warning"}
              >
                {buttonLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : modalShow["type"] == "archive" ? (
                  published ? (
                    "Unpublish"
                  ) : (
                    "Publish"
                  )
                ) : (
                  "Delete"
                )}
              </Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      {/* End Modal Section */}

      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon sx={{ color: "text.primary" }} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 120, maxWidth: "100%", bgcolor: "#000" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to={`/admin/watchad/${id}/edit`}
          sx={{ color: "text.primary", "&:hover": { bgcolor: "#ffde004d" } }}
        >
          <ListItemIcon>
            <ModeEditOutlineIcon
              sx={{ color: "text.secondary" }}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          onClick={() => setModalShow({ show: true, type: "archive" })}
          sx={{ color: "text.primary", "&:hover": { bgcolor: "#ffde004d" } }}
        >
          <ListItemIcon>
            {published ? (
              <UnpublishedIcon
                sx={{ color: "text.secondary" }}
                width={24}
                height={24}
              />
            ) : (
              <PublishIcon
                sx={{ color: "text.secondary" }}
                width={24}
                height={24}
              />
            )}
          </ListItemIcon>
          <ListItemText
            primary={published ? "Unpublish" : "Publish"}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <Divider sx={{ borderStyle: "dashed", borderColor: "#ed6c02" }} />

        <MenuItem
          onClick={() => setModalShow({ show: true, type: "delete" })}
          sx={{ color: "text.primary", "&:hover": { bgcolor: "#ffde004d" } }}
        >
          <ListItemIcon>
            <DeleteIcon sx={{ color: "#ed6c02" }} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2", color: "#ed6c02" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
