import * as types from "./types";

const initialState = {
  influencers: [],
  influencer: {},
};

const influencerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INFLUENCERS:
      const { listAffiliates } = action.payload;
      return {
        ...state,
        influencers: listAffiliates.items,
      };

      case types.SINGLE_INFLUENCER:
        const { getAffiliate } = action.payload;
        return {
          ...state,
          influencer: getAffiliate,
        };

    default:
      return state;
  }
};

export default influencerReducer;
