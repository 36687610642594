import React from "react";
import { Link, Grid, Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const ProgramImgStyle = styled("img")({
  width: "100%",
});

// ----------------------------------------------------------------------

const GridList = ({ title, grid, keyIndex }) => {
  const renderImageBanner = () => {
    let images = [];

    for (let i = 0; i < grid.length; i++) {
      images.push(
        <Grid key={`${keyIndex}grid${i}`} item xs={6} sm={3} md={2}>
          <Box>
            <Link
              href={
                grid[i].episodesCount < 1
                  ? "/coming"
                  : `/program/${grid[i].id}/episode/0`
              }
            >
              <ProgramImgStyle
                alt={grid[i].title}
                src={grid[i].verticalPosterImageMediaUrl}
              />
            </Link>
          </Box>
        </Grid>
      );
    }
    return images;
  };

  return (
    <>
      <Typography
        variant="h6"
        align="left"
        color="text.primary"
        component="h1"
        sx={{ mt: 5, mb: 2 }}
      >
        <CircleIcon color="primary" sx={{ fontSize: "12px" }} /> {title}
      </Typography>
      <Grid container spacing={2}>
        {renderImageBanner()}
      </Grid>
    </>
  );
};

export default GridList;
