import React, { useState } from "react";
import {
  CardMedia,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../store/actions";

const QuizContainer = ({ id, qtn, choices }) => {
  const dispatch = useDispatch();
  const buttonLoading = useSelector((state) => state.default.loading.button);

  const [fromRes, setRes] = useState({
    isRight: null,
    reward: 0,
    isAnswered: false,
  });

  const updateUI = (isRight, reward) => {
    setRes({ isRight, reward, isAnswered: true });
  };

  const handleAnswerQuiz = (title, value) => {
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.watchAd.answerAdQuiz(id, title, value, updateUI));
  };

  return buttonLoading ? (
    <Grid
      container
      component="div"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ height: "220px" }}
    >
      <CircularProgress />
    </Grid>
  ) : !fromRes["isAnswered"] ? (
    <>
      <Typography
        variant="h4"
        align="center"
        color="#f2bf25"
        component="h1"
        sx={{ mb: 1, fontWeight: "700", textShadow: "0px 5px #401212" }}
      >
        {qtn}
      </Typography>

      <List
        sx={{ width: "75%", bgcolor: "#000000bf !important", margin: "0 auto" }}
      >
        {choices?.map((choice, i) => (
          <ListItem key={i}>
            <ListItemButton
              onClick={() => handleAnswerQuiz(choice.title, choice.value)}
              sx={{ "&:hover": { bgcolor: "#ffde004d" } }}
            >
              <ListItemText primary={choice.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  ) : (
    <Grid
      container
      component="div"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "280px",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h6"
        align="center"
        color={fromRes["isRight"] ? "text.secondary" : "#ed6c02"}
        component="h1"
        sx={{
          fontWeight: "700",
          zIndex: "3",
        }}
      >
        {fromRes["isRight"]
          ? ` Tebrikler, ${fromRes["reward"]} Banana Kazandınız!`
          : "Üzgünüz, cevabınız yanlış."}
      </Typography>
      <video
        loop
        muted
        autoPlay
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: "1",
        }}
      >
        <source
          src={`/assets/client/video/${
            fromRes["isRight"] ? "winner_prompt" : "failed_prompt"
          }.mp4`}
          type="video/mp4"
        />
      </video>
    </Grid>
  );
};

export default QuizContainer;
