import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Card, Link, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const ProgramImgStyle = styled("img")({
  width: "100%",
});

// ----------------------------------------------------------------------

const ProgramCard = ({ progr }) => {
  const { id, title, verticalPosterImageMediaUrl, episodesCount, category } = progr;
  return (
    <Link
      to={`/admin/program/${id}/details`}
      color="inherit"
      underline="none"
      component={RouterLink}
    >
      <Card
        sx={{
          bgcolor: "#ffde004d",
          transition: "0.5s",
          "&:hover": {
            bgcolor: "#ffde0087",
            boxShadow: "0px 4px 9px 5px #ffde009c",
            borderRadius: 0,
          },
        }}
      >
        <Box>
          <ProgramImgStyle alt={title} src={verticalPosterImageMediaUrl} />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography component="span" variant="body1">
              {category}
            </Typography>
            <Typography variant="subtitle1">
              {episodesCount} ep(s)
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Link>
  );
};

export default ProgramCard;
