import { API } from "aws-amplify";
import * as types from "./types";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

export const refreshMeQuery = () => async (dispatch) => {
  try {
    const meQuery = API.graphql({
      query: mutations.refreshMe,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const res = await meQuery;

    dispatch({
      type: types.REFRESH_PROFILE,
      payload: res.data.refreshMe,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

export const defaultAction = () => async (dispatch) => {
  try {
    const currentQuery = API.graphql({
      query: queries.defaultProfile,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const currentProfile = await currentQuery;

    dispatch({
      type: types.PROFILE_DEFAULT,
      payload: currentProfile.data.me,
    });
    dispatch({
      type: types.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

export const uiLoading = () => {
  return {
    type: types.UI_LOADING_REQUEST,
  };
};

export const buttonLoading = () => {
  return {
    type: types.LOADING_BUTTON_REQUEST,
  };
};
