import React from "react";
import { Box, Card, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Label from "../../../../../../components/admin/label";

import WatchLaterIcon from "@mui/icons-material/WatchLater";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PolicyIcon from "@mui/icons-material/Policy";
import AdMoreMenu from "./AdMoreMenu";

// ----------------------------------------------------------------------

const RaffleImgStyle = styled("img")({
  width: "90%",
});

// ----------------------------------------------------------------------

const AdCard = ({ data }) => {
  const { id, bananaAmount, thumbnailMediaUrl, isPublished } = data;
  return (
    <Card>
      <Box sx={{ textAlign: "center" }}>
        <RaffleImgStyle
          alt={`banana-${bananaAmount}`}
          src={thumbnailMediaUrl}
        />
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 2, bgcolor: "#ffde004d" }}
      >
        <Typography variant="subtitle2" noWrap>
          Watch Ad, Earn {bananaAmount}🍌
        </Typography>

        <AdMoreMenu published={isPublished} name={bananaAmount} id={id} />
      </Stack>
    </Card>
  );
};

export default AdCard;
