import React, { useEffect } from "react";
import GlobalTemplate from "../../../templates/global";
import { CircularProgress, CardMedia, Container } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../store/actions";
import BananaGrid from "./components/bananaGrid";

const PurchaseMain = () => {
  const paymentParams = useSelector((state) => state.purchase.paymentParams);
  const bananaPackages = useSelector((state) => state.purchase.bananaPackages);

  const uiLoading = useSelector((state) => state.default.loading.container);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.purchase.getPaymentParamsAndBananaPackages());
  }, [dispatch]);

  return (
    <GlobalTemplate bgLight={Boolean(true)} bgBackground={Boolean(true)} title="Banana Satın Al">
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CardMedia
          component="img"
          image="/assets/client/images/satinal-page.png"
          alt="Banana satin al logo"
          height="auto"
          sx={{
            width: "60%",
          }}
        />
        <CardMedia
          component="img"
          image="/assets/client/images/increase-logo.png"
          alt="Çekilişlerde logo"
          height="auto"
          sx={{
            width: "40%",
            pt: 3,
          }}
        />
        {uiLoading ? (
          <CircularProgress />
        ) : (
          <BananaGrid data={bananaPackages} paymentParams={paymentParams} />
        )}
      </Container>
    </GlobalTemplate>
  );
};

export default PurchaseMain;
