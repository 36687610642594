import React, { useEffect } from "react";
import GlobalTemplate from "../../../templates/global";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CardMedia } from "@mui/material";
import { useDispatch } from "react-redux";
import * as actions from "../../../../../store/actions";

const PurchaseError = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.defaultAction());
  }, [dispatch]);

  return (
    <GlobalTemplate bgBackground={Boolean(true)} title="Hata!">
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <CardMedia
          component="img"
          image="/logo.png"
          alt="Bananativi logo"
          height="auto"
          sx={{
            width: "50%",
          }}
        />
        <Typography
          variant="h5"
          align="center"
          color="#ffffff75"
          component="h2"
          sx={{ mt: 6, mb: 10, fontWeight: "normal" }}
        >
          Bir hata oluştu, lütfen tekrar deneyiniz.
        </Typography>
      </Container>
    </GlobalTemplate>
  );
};

export default PurchaseError;
