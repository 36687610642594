import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import AuthTemplate from "../../../templates/auth";
import SigninUi from "./components/signin_ui";
import NewpassUi from "./components/newpass_ui";
import { Typography } from "@mui/material";
import "../../../../../config";
import { API_URL } from "../../../../../config";
import { Auth } from "aws-amplify";

function SignIn() {
  const [signed_user, setUser] = useState("");
  const [alertConfig, setAlertConfig] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [uiTemplate, setUiTemplate] = useState("signin");

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleVisible = () => {
    if (searchParams.get("confirmationStatus")) {
      const status = searchParams.get("confirmationStatus");
      let alertType = "success";
      if (status === "notauthorized") {
        alertType = "warning";
      }
      if (status === "codemismatch") {
        alertType = "error";
      }
      setAlertConfig({ type: alertType, visible: true });
      setTimeout(() => {
        setAlertConfig({ ...alertConfig, visible: false });
      }, 10000);
    }
  };

  useEffect(() => {
    handleVisible();
  }, []);

  const onSubmit = async (values, { _, setFieldError, __ }) => {
    setLoading(true);
    const isValidEmail = /\S+@\S+\.\S+/.test(values.email);
    if (!isValidEmail && values.email != null) {
      try {
        let headers = new Headers();

        headers.append("Content-Type", "application/json");

        const gsmLookupRequest = await fetch(API_URL, {
          headers,
          method: "POST",
          body: JSON.stringify({ gsm: values.email }),
        });
        const data = await gsmLookupRequest.json();

        if (gsmLookupRequest.status == 400) {
          setFieldError("email", "Telefon numaranız +90 ile başlamalıdır…");
          console.error("Error", data.message);
          setLoading(false);
          return;
        }

        awsAuthSignIn(data.userId, values.password, setFieldError);
      } catch (e) {
        setFieldError("email", "Kullanıcı bulunamadı.");
        setLoading(false);
        console.error("Error", e);
      }

      return;
    }
    awsAuthSignIn(values.email, values.password, setFieldError);
  };

  const awsAuthSignIn = async (email, pass, setFieldError) => {
    try {
      if (uiTemplate === "signin") {
        const user = await Auth.signIn(email.toLowerCase(), pass);
        setUser(user);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setUiTemplate("newpass");
        } else {
          if (searchParams.get("code")) {
            window.location.replace(
              `/verifyEmailCode?code=${searchParams.get("code")}`
            );
          } else {
            navigate(0);
          }
        }
      } else {
        await Auth.completeNewPassword(signed_user, pass);
        if (searchParams.get("code")) {
          window.location.replace(
            `/verifyEmailCode?code=${searchParams.get("code")}`
          );
        } else {
          navigate(0);
        }
      }
    } catch (err) {
      if (err.name === "UserNotConfirmedException") {
        await Auth.resendSignUp(email);
        navigate(`/auth/verify?id=${email}`);
      }

      if (err.name === "NotAuthorizedException")
        setFieldError("email", "Hatalı e-mail ya da parola.");

      if (err.name === "UserNotFoundException")
        setFieldError("email", "Kullanıcı bulunamadı.");

      if (err.name === "InvalidParameterException")
        setFieldError("email", "Alan doğrulama hatası.");

      if (err.name === "LimitExceededException")
        setFieldError(
          "email",
          "Deneme limiti aşıldı, lütfen bir süre sonra tekrar deneyin."
        );

      if (err.name === "PasswordResetRequiredException")
        setFieldError("email", "Kullanıcı için parola sıfırlama gerekli.");

      console.error("Error", err);
    }
    setLoading(false);
  };

  return (
    <AuthTemplate status={alertConfig}>
      <Typography component="h1" variant="h5">
        {uiTemplate === "signin" ? "Hesabına Giriş Yap" : "Yeni parola gerekli"}
      </Typography>

      {uiTemplate === "signin" ? (
        <SigninUi submit={onSubmit} loading={isLoading} />
      ) : (
        <NewpassUi submit={onSubmit} loading={isLoading} />
      )}
    </AuthTemplate>
  );
}

export default SignIn;
