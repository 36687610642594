import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Button,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ImageIcon from "@mui/icons-material/Image";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import {
  CssTextField,
  CssFormControl,
} from "../../../../../styles/DefaultComponents";

const MediaImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
  position: "absolute",
});
//----------------------------------//

const MediaUpload = () => {
  const [fields, setFields] = useState({
    fileType: "",
    fileName: "",
    fileExtension: "",
  });
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const uploadLoading = useSelector((state) => state.media.uploadLoading);
  const progressValue = useSelector((state) => state.media.progressValue);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "Media Upload | Bananativi Admin";
  });

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name == "mediaFile") {
      const file = e.target.files[0];
      const fileExtension = file.type.split("/")[1];
      if (
        (fields["fileType"] == "IMAGE" && file.type.match("image.*")) ||
        (fields["fileType"] == "VIDEO" && file.type.match("video.*"))
      ) {
        setFields({ ...fields, [e.target.name]: file, fileExtension });
      } else {
        alert("Please choose the appropriate file format");
      }
    } else {
      setFields({ ...fields, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.media.startUpload(
        fields["fileName"] + "." + fields["fileExtension"],
        fields["fileType"],
        fields["mediaFile"].size,
        fields["mediaFile"]
      )
    );
  };

  useEffect(() => {
    if (progressValue >= 100) {
      setFields({ fileType: "", fileName: "", fileExtension: "" });
    }
  }, [progressValue]);

  return (
    <Grid
      container
      component="main"
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        minHeight: "100vh",
        backgroundImage: "url(/assets/client/images/bg.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        backgroundPosition: "top",
        ["@media (min-width:2000px)"]: {
          backgroundRepeat: "round",
          backgroundPosition: "center",
        },
      }}
    >
      <Container
        maxWidth="false"
        sx={{
          background: "rgb(0, 0, 0)",
          boxShadow: "rgb(0 0 0) 0px 0px 5px 5px",
          borderRadius: "5px",
          p: 5,
          my: 10,
          mx: { xs: 7, md: 2 },
          height: "fit-content",
          maxWidth: { xs: "1200px", xl: "1400px" },
        }}
      >
        <Typography variant="h4">Media Upload</Typography>
        <Typography
          variant="subtitle1"
          color="#ffffff7d"
          component="h4"
          sx={{ mb: 3, pl: 1, fontWeight: "normal" }}
        >
          Do not close or refresh this tab when uploading a file
        </Typography>

        {uploadLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{ height: "30vh" }}
          >
            {progressValue >= 100 ? (
              <CloudDoneIcon fontSize="large" color="primary" />
            ) : (
              <CircularProgress size={45} />
            )}

            <Typography
              variant="body1"
              color="#ffffffad"
              component="h4"
              sx={{ pt: 5 }}
            >
              {progressValue >= 100
                ? "Media file was uploaded successfully"
                : "Please wait while the media file is uploading"}
            </Typography>

            {progressValue < 100 && (
              <Box sx={{ width: "50%" }}>
                <LinearProgressWithLabel value={progressValue} />
              </Box>
            )}
          </Grid>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  id="given"
                  label="File name"
                  value={fields["fileName"]}
                  name="fileName"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel id="media-file-select-label">
                    File type
                  </InputLabel>
                  <Select
                    labelId="media-file-select-label"
                    id="media-file-select"
                    label="File type"
                    value={fields["fileType"]}
                    name="fileType"
                    onChange={handleChange}
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value="IMAGE"
                    >
                      Image
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value="VIDEO"
                    >
                      Video
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
            </Grid>

            <Button
              variant="contained"
              disabled={!fields["fileType"] != ""}
              component="label"
              sx={{ float: "right", my: 1 }}
            >
              Choose file
              <input
                hidden
                onChange={handleChange}
                name="mediaFile"
                accept={fields["fileType"] == "VIDEO" ? "video/*" : "image/*"}
                type="file"
              />
            </Button>

            <Typography component="h6" variant="h6" sx={{ my: 2 }}>
              File Preview
            </Typography>
            <Grid
              container
              component="div"
              justifyContent={"center"}
              alignItems={"center"}
              sx={{
                border: "1px solid #ffffff61",
                height: "50vh",
                position: "relative",
              }}
            >
              {!fields["mediaFile"] ? (
                <Typography sx={{ fontSize: "10rem" }} color="#ffffff61">
                  <ImageIcon fontSize="inherit" color="inherit" />
                </Typography>
              ) : fields["fileType"] == "IMAGE" ? (
                <MediaImgStyle
                  alt="Media preview image"
                  src={URL.createObjectURL(fields["mediaFile"])}
                />
              ) : (
                <video width="100%" height="450" controls>
                  <source src={URL.createObjectURL(fields["mediaFile"])} />
                </video>
              )}
            </Grid>
            <Button
              variant={buttonLoading ? "outlined" : "contained"}
              disabled={!fields["mediaFile"]}
              type="submit"
              startIcon={buttonLoading ? null : <FileUploadIcon />}
              sx={{ float: "right", mt: 3 }}
            >
              {buttonLoading ? (
                <CircularProgress color="inherit" size={30} />
              ) : (
                "Upload"
              )}
            </Button>
          </Box>
        )}
      </Container>
    </Grid>
  );
};

export default MediaUpload;
