import React, { useState } from "react";
import {
  Backdrop,
  Typography,
  InputAdornment,
  CircularProgress,
  IconButton,
  Button,
  Modal,
  Grid,
  Fade,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  modalStyle,
  CssTextField,
} from "../../../../../styles/DefaultComponents";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordChange = ({ submit, modalShow, setModal, buttonLoading }) => {
  const [oldpasswordShown, setOldPasswordShown] = useState(false);
  const [newpasswordShown, setNewPasswordShown] = useState(false);
  const schema = yup.object().shape({
    oldpassword: yup.string().required("Lütfen parolanızı girin"),
    newpassword: yup
      .string()
      .required("Lütfen parolanızı girin")
      .matches(/^(?=.{6,})/, "Minimum 6 karakter olmalı."),
  });

  const formik = useFormik({
    initialValues: {
      oldpassword: "",
      newpassword: "",
    },
    validationSchema: schema,
    onSubmit: submit,
  });

  const toggleOldPassword = () => {
    setOldPasswordShown(!oldpasswordShown);
  };

  const toggleNewPassword = () => {
    setNewPasswordShown(!newpasswordShown);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      aria-labelledby="password-modal-title"
      aria-describedby="password-modal-description"
      open={modalShow}
      onClose={() => setModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={modalShow}>
        <Box sx={modalStyle}>
          <Typography id="password-modal-title" variant="h6" component="h2">
            Şifre değiştir
          </Typography>
          <Box
            id="password-modal-description"
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <CssTextField
              margin="normal"
              size="small"
              required
              fullWidth
              variant="outlined"
              name="oldpassword"
              label="Eski Şifre"
              type={oldpasswordShown ? "text" : "password"}
              id="oldpassword"
              value={formik.values.oldpassword}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.oldpassword)}
              helperText={formik.errors.oldpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: "#fff" }}
                    >
                      {oldpasswordShown ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CssTextField
              margin="normal"
              size="small"
              required
              fullWidth
              variant="outlined"
              name="newpassword"
              label="Yeni Şifre"
              type={newpasswordShown ? "text" : "password"}
              id="newpassword"
              value={formik.values.newpassword}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.newpassword)}
              helperText={formik.errors.newpassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle newpassword visibility"
                      onClick={toggleNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{ color: "#fff" }}
                    >
                      {newpasswordShown ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid container justifyContent={"flex-end"}>
              <Button
                id="transition-modal-button"
                disabled={!formik.isValid}
                type="submit"
                variant={"contained"}
                sx={{
                  mt: 3,
                  mb: 2,
                  color: formik.isValid ? "#000" : "#ffde0075 !important",
                  border: formik.isValid
                    ? "inherit"
                    : "1px solid #ffde0075 !important",
                }}
              >
                {buttonLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Güncelle"
                )}
              </Button>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PasswordChange;
