import * as types from "./types";

const initialState = {
  raffles: [],
  subscription: {},
  raffle: {},
  participants: [],
  totalTickets: 0,
};

const raffleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RAFFLES:
      const { listRaffles } = action.payload;
      return {
        ...state,
        raffles: listRaffles.items,
      };

    case types.SINGLE_RAFFLE:
      return {
        ...state,
        raffle: action.payload,
      };

    case types.GET_RAFFLE_PARTICIPANTS:
      const { listRaffleParticipants } = action.payload;
      return {
        ...state,
        participants: listRaffleParticipants.items,
        raffle: listRaffleParticipants.raffle,
        totalTickets: listRaffleParticipants.totalTicketCount,
      };

    default:
      return state;
  }
};

export default raffleReducer;
