//Get all medias
export const GET_MEDIAS = "GET_MEDIAS";

//Update progress value
export const PROGRESS_VALUE_UPDATE = "PROGRESS_VALUE_UPDATE";

//Reset progress value
export const PROGRESS_VALUE_RESET = "PROGRESS_VALUE_RESET";

//Request Load UI Upload
export const LOADING_UPLOAD_REQUEST = "LOADING_UPLOAD_REQUEST";

//Load UI Upload Done
export const LOADING_UPLOAD_DONE = "LOADING_UPLOAD_DONE";

//Start Media Upload
export const START_MEDIA_UPLOAD = "START_MEDIA_UPLOAD";