import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Typography,
  Container,
  Stack,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  Button,
  Grid,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import {
  CssTextField,
  CssFormControl,
} from "../../../../../styles/DefaultComponents";

const EditInfluencer = () => {
  const [fields, setFields] = useState({ loading: true });
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const influencer = useSelector((state) => state.influencer.influencer);

  const dispatch = useDispatch();

  let { influencer_id } = useParams();

  useEffect(() => {
    dispatch(actions.influencer.getInfluencer(influencer_id));
  }, [dispatch]);

  useEffect(() => {
    if (influencer?.name) {
      setFields({
        code: influencer.code,
        email: influencer.email,
        loading: false,
        type: influencer.type,
        name: influencer.name,
        gsm: influencer.gsm,
      });
    }
  }, [influencer]);

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.influencer.updateInfluencer(
        influencer_id,
        fields["email"],
        fields["gsm"],
        fields["given"],
        fields["type"]
      )
    );
  };

  return (
    <AdminTemplate
      title={influencer.code ? `Edit ${influencer.code}` : "Yükleniyor..."}
    >
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Typography variant="h4" gutterBottom>
          Edit {influencer.code}
        </Typography>

        {fields["loading"] ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box component="form" onSubmit={handleSubmit}>
            <CssTextField
              margin="normal"
              size="small"
              fullWidth
              required
              variant="outlined"
              label="Name"
              name="name"
              onChange={handleChange}
              value={fields["name"]}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  variant="outlined"
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  value={fields["email"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  fullWidth
                  required
                  variant="outlined"
                  label="Phone Number"
                  name="gsm"
                  onChange={handleChange}
                  value={fields["gsm"]}
                  inputProps={{
                    maxLength: 10,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+90</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssFormControl
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    value={fields["type"]}
                    name="type"
                    onChange={handleChange}
                  >
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value="VENUE"
                    >
                      VENUE
                    </MenuItem>
                    <MenuItem
                      sx={{
                        color: "text.primary",
                        "&:hover": { bgcolor: "#ffde004d" },
                        "&.Mui-selected": {
                          bgcolor: "#ffde006e",
                          "&:hover": { bgcolor: "#ffde006e" },
                        },
                      }}
                      value="INFLUENCER"
                    >
                      INFLUENCER
                    </MenuItem>
                  </Select>
                </CssFormControl>
              </Grid>
              <Grid item xs={12} sm={6} mt={1}>
                <CssTextField
                  margin="normal"
                  size="small"
                  fullWidth
                  variant="outlined"
                  label="Referral Code"
                  name="code"
                  disabled
                  value={fields["code"]}
                />
              </Grid>
            </Grid>

            <Grid
              item
              md={8}
              lg={4}
              mt={5}
              xl={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-end" },
              }}
            >
              <Stack direction="row" spacing={2} sx={{ width: "20%" }}>
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  href="/admin/influencer"
                >
                  Cancel
                </Button>

                <Button
                  variant={buttonLoading ? "outlined" : "contained"}
                  fullWidth
                  type="submit"
                >
                  {buttonLoading ? (
                    <CircularProgress color="inherit" size={30} />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Stack>
            </Grid>
          </Box>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default EditInfluencer;
