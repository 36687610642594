import React from "react";
import { Link } from "react-router-dom";
import { CardMedia, Box } from "@mui/material";
import "moment/locale/tr";
import Slider from "react-slick";
import { SliderSettings } from "../../../../../../styles/DefaultComponents";

const AdSlider = ({ lists }) => {
  const renderAd = () => {
    let adItems = [];

    for (let i = 0; i < lists.length; i++) {
      adItems.push(
        <Box
          key={`ad-item${i}`}
          sx={{
            position: "relative",
            minHeight: { xs: "15vh", md: "30vh" },
            width: "100%",
            px: { xs: 10, md: 5 },
            cursor: !lists[i].isAnswerable ? "not-allowed" : "pointer",
          }}
        >
          <Link
            to={`/watchad/details/${lists[i].id}`}
            style={{
              textDecoration: "none",
              pointerEvents: !lists[i].isAnswerable ? "none" : "auto",
            }}
          >
            <CardMedia
              component="img"
              image={lists[i].thumbnailMediaUrl}
              alt={`ad ${i}`}
            />

            {lists[i].isAnswerable ? (
              <CardMedia
                component="img"
                image="/assets/client/images/kazan-active.png"
                alt="button-link"
                sx={{
                  mt: 5,
                }}
              />
            ) : (
              <CardMedia
                component="img"
                image="/assets/client/images/kazan-disabled.png"
                alt="button-disabled"
                sx={{
                  mt: { xs: 2, md: 4 },
                }}
              />
            )}
          </Link>
        </Box>
      );
    }
    return adItems;
  };

  return <Slider {...SliderSettings}>{renderAd()}</Slider>;
};

export default AdSlider;
