import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GlobalTemplate from "../../templates/global";
import { CardMedia, Container, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions";

function Plans() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.dActions.defaultAction());
  }, [dispatch]);

  return (
    <GlobalTemplate
      bgLight={Boolean(true)}
      bgBackground={Boolean(true)}
      title="Üyelik"
    >
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "fit-content",
        }}
      >
        <CardMedia
          component="img"
          image="/assets/client/images/premium_subscription_plan.png"
          alt="premium uyelik"
          height="auto"
          sx={{
            width: { xs: "100%", md: "50%", lg: "70%" },
          }}
        />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => navigate("/profile")}
          sx={{
            mb: 5,
          }}
        >
          Premium Üye OI
        </Button>
      </Container>
    </GlobalTemplate>
  );
}

export default Plans;
