import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { CssTextField } from "../../../../../../styles/DefaultComponents";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as yup from "yup";

const SigninUi = ({ submit, loading }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const schema = yup.object().shape({
    email: yup.string().required("E-posta / Tel.(+90...) alanı gerekli"),
    password: yup
      .string()
      .required("Lütfen parolanızı girin")
      .matches(/^(?=.{6,})/, "Minimum 6 karakter olmalı."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: submit,
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box component="form" sx={{ pt: 7 }} onSubmit={formik.handleSubmit}>
      <CssTextField
        margin="normal"
        size="small"
        required
        fullWidth
        variant="outlined"
        id="email"
        label="E-posta / Tel."
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.email)}
        helperText={formik.errors.email ? formik.errors.email : 'Tel.(+90...)'}
      />
      <CssTextField
        margin="normal"
        size="small"
        required
        fullWidth
        variant="outlined"
        name="password"
        label="Parola"
        type={passwordShown ? "text" : "password"}
        id="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={Boolean(formik.errors.password)}
        helperText={formik.errors.password}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={togglePassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{ color: "#fff" }}
              >
                {passwordShown ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Link
        href="/auth/recover"
        variant="body2"
        color={"#fff"}
        underline="hover"
      >
        Parolanızı mı unuttunuz?
      </Link>
      <Button
        disabled={!formik.isValid}
        type="submit"
        fullWidth
        variant={loading ? "outlined" : "contained"}
        sx={{
          mt: 3,
          mb: 2,
          color: formik.isValid ? "#000" : "#ffde0075 !important",
          border: formik.isValid ? "inherit" : "1px solid #ffde0075 !important",
        }}
      >
        {loading ? <CircularProgress color="inherit" size={30} /> : "Giriş Yap"}
      </Button>
      <Grid container justifyContent={"center"}>
        <Link href="/auth/signup" variant="subtitle1" underline="hover">
          Hesabınız yok mu? Üye ol
        </Link>
      </Grid>
    </Box>
  );
};

export default SigninUi;
