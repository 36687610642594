import * as types from "./types";

const initialState = {
  listings: [],
  programs: [],
  program: {},
};

const raffleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LISTINGS:
      const { programListings } = action.payload;
      return {
        ...state,
        listings: programListings,
      };

      case types.GET_PROGRAMS:
        const { listPrograms } = action.payload;
        return {
          ...state,
          programs: listPrograms.items,
        };

    case types.SINGLE_PROGRAM:
      const { getProgram } = action.payload;
      return {
        ...state,
        program: getProgram,
      };

    default:
      return state;
  }
};

export default raffleReducer;
