import { API } from "aws-amplify";
import * as types from "./types";
import * as tMedia from "../media/types";
import * as tEp from "../episode/types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get all listings */
export const getListings = () => async (dispatch) => {
  try {
    const listListingsQuery = API.graphql({
      query: queries.programListings,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page: 1, size: 500 } } },
    });

    const allListings = await listListingsQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allListings.data.me,
    });

    dispatch({
      type: types.GET_LISTINGS,
      payload: allListings.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get all programs */
export const getPrograms = () => async (dispatch) => {
  try {
    const listProgramsQuery = API.graphql({
      query: queries.listPrograms,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page: 1, size: 500 } } },
    });

    const allPrograms = await listProgramsQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allPrograms.data.me,
    });

    dispatch({
      type: types.GET_PROGRAMS,
      payload: allPrograms.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get single client program details */
export const getProgram = (id) => async (dispatch) => {
  try {
    const getProgramQuery = API.graphql({
      query: queries.getProgram,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id } },
    });

    const singleProgram = await getProgramQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT, 
      payload: singleProgram.data.me,
    });

    dispatch({
      type: types.SINGLE_PROGRAM,
      payload: singleProgram.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
    
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get single admin program details */
export const getAdminProgram = (id) => async (dispatch) => {
  try {
    const getProgramQuery = API.graphql({
      query: queries.getAdminProgram,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        program: { id },
        episode: { programId: id, pagination: { page: 1, size: 500 } },
      },
    });

    const singleProgram = await getProgramQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleProgram.data.me,
    });

    dispatch({
      type: types.SINGLE_PROGRAM,
      payload: singleProgram.data,
    });

    dispatch({
      type: tEp.GET_EPISODES,
      payload: singleProgram.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Create program */
export const createProgram =
  (
    bannerImageMediaUrl,
    category,
    horizontalPosterImageMediaUrl,
    title,
    verticalPosterImageMediaUrl
  ) =>
  async (dispatch) => {
    try {
      const createProgramQuery = API.graphql({
        query: mutations.createProgram,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            bannerImageMediaUrl,
            category,
            horizontalPosterImageMediaUrl,
            title,
            verticalPosterImageMediaUrl,
          },
        },
      });

      await createProgramQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace("/admin/program/all");
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Get edit program details */
export const getEditProgram = (id) => async (dispatch) => {
  try {
    const getEditProgramQuery = API.graphql({
      query: queries.getEditProgram,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        program: { id },
        media: {
          pagination: { page: 1, size: 500 },
          fileStatuses: ["DISTRIBUTED"],
          fileTypes: ["IMAGE"],
        },
      },
    });

    const singleProgram = await getEditProgramQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleProgram.data.me,
    });

    dispatch({
      type: tMedia.GET_MEDIAS,
      payload: singleProgram.data,
    });

    dispatch({
      type: types.SINGLE_PROGRAM,
      payload: singleProgram.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Update program */
export const updateProgram =
  (
    id,
    bannerImageMediaUrl,
    category,
    horizontalPosterImageMediaUrl,
    title,
    verticalPosterImageMediaUrl
  ) =>
  async (dispatch) => {
    try {
      const updateProgramQuery = API.graphql({
        query: mutations.updateProgram,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            id,
            bannerImageMediaUrl,
            category,
            horizontalPosterImageMediaUrl,
            title,
            verticalPosterImageMediaUrl,
          },
        },
      });

      await updateProgramQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace(`/admin/program/${id}/details`);
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Delete program */
export const deleteProgram = (id) => async (dispatch) => {
  try {
    const deleteProgramQuery = API.graphql({
      query: mutations.deleteProgram,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await deleteProgramQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.replace("/admin/program/all");
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get all sections */
export const getSections = () => async (dispatch) => {
  try {
    const listSectionsQuery = API.graphql({
      query: queries.programSections,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page: 1, size: 500 } } },
    });

    const allSections = await listSectionsQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allSections.data.me,
    });

    dispatch({
      type: types.GET_LISTINGS,
      payload: allSections.data,
    });

    dispatch({
      type: types.GET_PROGRAMS,
      payload: allSections.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Create program section */
export const createProgramSection =
  (title, type, index) => async (dispatch) => {
    try {
      const createProgramQuery = API.graphql({
        query: mutations.createProgramListing,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            title,
            type,
            index,
            programIds: [],
          },
        },
      });

      await createProgramQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.reload();
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Update program section */
export const updateProgramSection = (title, type, id) => async (dispatch) => {
  try {
    const updateProgramQuery = API.graphql({
      query: mutations.updateProgramListing,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          title,
          type,
          id,
        },
      },
    });

    await updateProgramQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Reorder program section */
export const reorderProgramSection = (programListingIds) => async () => {
  try {
    const reorderProgramQuery = API.graphql({
      query: mutations.reOrderProgramListing,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          programListingIds,
        },
      },
    });

    await reorderProgramQuery;
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Add new programs to section */
export const addProgramsSection = (id, programIds) => async (dispatch) => {
  try {
    const updateProgramQuery = API.graphql({
      query: mutations.updateProgramListing,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          programIds,
          id,
        },
      },
    });

    const allSections = await updateProgramQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    return allSections.data.updateProgramListing;
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Reorder programs in section */
export const reorderProgramsInSection = (id, programIds) => async () => {
  try {
    const reorderProgramsQuery = API.graphql({
      query: mutations.updateProgramListing,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
          programIds,
        },
      },
    });

    await reorderProgramsQuery;
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Delete program section */
export const deleteProgramSection = (id) => async (dispatch) => {
  try {
    const deleteProgramSectionQuery = API.graphql({
      query: mutations.deleteProgramListing,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await deleteProgramSectionQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};
