import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";

export default function NonGlobalTemplate({ children, title }) {
  const bgSettings = {
    backgroundImage: `url(/assets/client/images/bg.jpg)`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#000",
    backgroundSize: "auto",
    backgroundPosition: "top",
    ["@media (min-width:2000px)"]: {
      backgroundRepeat: "round",
      backgroundPosition: "center",
    },
  };

  useEffect(() => {
    document.title = title ? `Bananativi | ${title}` : "Bananativi";
  }, [title]);

  return (
    <>
      <Grid
        container
        component="main"
        sx={{
          minHeight: "100vh",
          ...bgSettings,
        }}
      >
        {children}
      </Grid>
    </>
  );
}
