import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  CardMedia,
  Grid,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import "../../../config";
import { Auth } from "aws-amplify";

const bgSettings = {
  backgroundImage: `url(/assets/client/images/BG.png)`,
  backgroundRepeat: "no-repeat",
  backgroundColor: "#000",
  backgroundSize: "auto",
  backgroundPosition: "top",
  ["@media (min-width:2000px)"]: {
    backgroundRepeat: "round",
    backgroundPosition: "center",
  },
};

export default function EmailCode() {
  const [isLoading, setLoading] = useState(true);
  const [isresponseValid, setResponse] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    confirmEmail();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, []);

  const confirmEmail = async () => {
    try {
      const user = await Auth.verifyCurrentUserAttributeSubmit(
        "email",
        searchParams.get("code")
      );
      if (user) {
        setResponse(true);
      }
    } catch (err) {
      if (err.name === "InvalidParameterException") {
        navigate("/");
      }
      console.error("Error", err);
    }
    setLoading(false);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh", ...bgSettings }}>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardMedia
          component="img"
          image="/logo.png"
          alt="Bananativi logo"
          height="auto"
          sx={{
            width: "35%",
          }}
        />

        {isLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Typography
              sx={{ fontSize: "12rem" }}
              color={isresponseValid ? "#ffed00" : "#ed6c02"}
              lineHeight={0}
            >
              {isresponseValid ? (
                <MarkEmailReadIcon fontSize="inherit" color="inherit" />
              ) : (
                <UnsubscribeIcon fontSize="inherit" color="inherit" />
              )}
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color={isresponseValid ? "#fff" : "#ed6c02"}
              component="h3"
              sx={{ fontWeight: "bold", mb: 6 }}
            >
              {isresponseValid
                ? "E-posta adresinizi onayladığınız için teşekkür ederiz! Ana sayfaya yönlendiriliyorsunuz..."
                : " E-postanız onaylanırken bir hata oluştu, lütfen tekrar bir doğrulama kodu isteyin!"}
            </Typography>

            <CircularProgress
              size={80}
              color={isresponseValid ? "primary" : "warning"}
            />
          </>
        )}
      </Container>
    </Grid>
  );
}
