import React, { useEffect, useState } from "react";
import {
  Stack,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AdminTemplate from "../../templates/global";
import AddIcon from "@mui/icons-material/Add";
import WebAssetOffIcon from "@mui/icons-material/WebAssetOff";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions";
import PackageArea from "./components/PackageArea";
import PackageModal from "./components/PackageModal";

//----------------------------------//
const MediaImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
  position: "absolute",
});
//----------------------------------//

const AdminPackages = () => {
  const uiLoading = useSelector((state) => state.default.loading.container);
  const bananaPackages = useSelector((state) => state.purchase.bananaPackages);
  const medias = useSelector((state) => state.media.medias);
  const [fields, setFields] = useState({
    amount: "",
    iconUrl: "",
    price: "",
  });
  const [previewIcon, setPreviewIcon] = useState("");
  const [newModal, setNewModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.purchase.getBananaPackages());
  }, [dispatch]);

  const imageURLS = medias.map((media) => {
    return { label: media.fileName, url: media.cdnUrl };
  });

  const handleChange = (value, name) => {
    setFields({ ...fields, [name]: value });
  };

  const handleNewSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.purchase.createBananaPackage(
        fields["amount"],
        fields["price"],
        fields["iconUrl"].url
      )
    );
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.purchase.updateBananaPackage(
        fields["amount"],
        fields["iconUrl"].url,
        fields["price"],
        fields["id"]
      )
    );
  };

  const handlePackageEdit = (amount, iconUrl, price, id) => {
    setFields({ ...fields, amount, iconUrl, price, id });
    setEditModal(true);
  };

  const handleDelete = (id) => {
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.purchase.deleteBananaPackage(id));
  };

  return (
    <AdminTemplate title="Banana Packages">
      <PackageModal
        name="create"
        open={newModal}
        medias={imageURLS}
        modalClose={setNewModal}
        handleChange={handleChange}
        handleSubmit={handleNewSubmit}
        amount={fields["amount"]}
        iconUrl={fields["iconUrl"]}
        price={fields["price"]}
      />
      <PackageModal
        name="edit"
        medias={imageURLS}
        open={editModal}
        modalClose={setEditModal}
        handleChange={handleChange}
        handleSubmit={handleEditSubmit}
        amount={fields["amount"]}
        iconUrl={fields["iconUrl"]}
        price={fields["price"]}
      />

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          Banana Packages
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            setFields({ amount: "", iconUrl: "", price: "" });
            setNewModal(true);
          }}
          startIcon={<AddIcon />}
        >
          New Package
        </Button>
      </Stack>

      <Container maxWidth="lg">
        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={5}>
              <PackageArea
                list={bananaPackages}
                medias={imageURLS}
                handlePreview={setPreviewIcon}
                handleEdit={handlePackageEdit}
                handleDelete={handleDelete}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <Card
                sx={{
                  width: "100%",
                  bgcolor: "#ffde0030",
                }}
              >
                <CardContent>
                  <Grid
                    container
                    component="div"
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ height: "40vh", position: "relative" }}
                  >
                    {previewIcon != "" ? (
                      <MediaImgStyle
                        alt="icon package icon"
                        src={previewIcon}
                      />
                    ) : (
                      <>
                        <Typography sx={{ fontSize: "5rem" }} color="#ffffff61">
                          <WebAssetOffIcon fontSize="inherit" color="inherit" />
                        </Typography>

                        <Typography variant="h6">
                          Nothing to preview!
                        </Typography>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default AdminPackages;
