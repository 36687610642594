import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { CssTextField } from "../../../../../styles/DefaultComponents";
import AuthTemplate from "../../../templates/auth";
import { useCountdown } from "../../../../../hooks/useCountdown";
import "../../../../../config";

export default function Verify() {
  const [countdownTime, setCountdownTime] = useState(moment());
  const [retryCount, setRetryCount] = useState(1);
  const [_, minutes, seconds] = useCountdown(countdownTime);
  const [isLoading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const userId = searchParams.get("id");

  useEffect(() => {
    dateCountdownTrigger(5);
  }, []);

  const dateCountdownTrigger = (min) => {
    let newDateObj = moment().add(min, "m").toDate();
    setCountdownTime(newDateObj);
  };

  const confirmPhone = async (e) => {
    e.preventDefault();
    dateCountdownTrigger(5 * retryCount);
    setRetryCount(retryCount + 1);
    try {
      await Auth.resendSignUp(userId);
    } catch (err) {
      if (err.name === "InvalidParameterException") {
        navigate("/auth/signin");
      }
      console.error("Error", err);
    }
  };

  const schema = yup.object().shape({
    code: yup
      .string()
      .required("Alan gereklidir.")
      .matches(/^(?=.{6,})/, "Minimum 6 karakter olmalı."),
  });

  const onSubmit = async ({ code }, { _, setFieldError, __ }) => {
    setLoading(true);
    try {
      const user = await Auth.confirmSignUp(userId, code);

      if (user) {
        navigate(`/auth/signin`);
      }
    } catch (err) {
      if (err.name === "CodeMismatchException")
        setFieldError("code", "Geçersiz doğrulama kodu");

      console.error("Error", err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: schema,
    onSubmit: onSubmit,
  });

  return (
    <AuthTemplate>
      <Typography component="h1" variant="h5">
        Telefon numarasını doğrula
      </Typography>

      <Box component="form" sx={{ pt: 7 }} onSubmit={formik.handleSubmit}>
        <CssTextField
          margin="normal"
          size="small"
          required
          fullWidth
          variant="outlined"
          id="code"
          label="Doğrulama kodu"
          name="code"
          value={formik.values.code}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.code)}
          helperText={formik.errors.code}
        />

        <Button
          disabled={!formik.isValid}
          type="submit"
          fullWidth
          variant={isLoading ? "outlined" : "contained"}
          sx={{
            mt: 3,
            mb: 2,
            color: formik.isValid ? "#000" : "#ffde0075 !important",
            border: formik.isValid
              ? "inherit"
              : "1px solid #ffde0075 !important",
          }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            "Gönder"
          )}
        </Button>
        <Button
          disabled={moment(countdownTime).isAfter(moment.now())}
          fullWidth
          variant="outlined"
          onClick={confirmPhone}
          sx={{
            mt: 3,
            mb: 2,
            color: !moment(countdownTime).isAfter(moment.now())
              ? "#ffde00"
              : "#ffde0075 !important",
            border: !moment(countdownTime).isAfter(moment.now())
              ? "1px solid #ffde00 !important"
              : "1px solid #ffde0075 !important",
          }}
        >
          {moment(countdownTime).isAfter(moment.now())
            ? `${minutes}:${seconds}  `
            : "00:00  "}
          Kodu Tekrar Gönder
        </Button>
      </Box>
    </AuthTemplate>
  );
}
