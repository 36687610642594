import { API } from "aws-amplify";
import * as types from "./types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get all media */
export const getMedias =
  (
    fileTypes = null,
    fileStatuses = ["DISTRIBUTING", "DISTRIBUTED", "FAILED"]
  ) =>
  async (dispatch) => {
    try {
      const listMediasQuery = API.graphql({
        query: queries.defaultListMedias,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            pagination: { page: 1, size: 500 },
            fileStatuses,
            fileTypes,
          },
        },
      });

      const allMedias = await listMediasQuery;
      dispatch({
        type: dType.PROFILE_DEFAULT,
        payload: allMedias.data.me,
      });

      dispatch({
        type: types.GET_MEDIAS,
        payload: allMedias.data,
      });

      dispatch({
        type: dType.UI_LOADING_DONE,
      });
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Search media */
export const searchMedia = (fileNameStartsWith) => async (dispatch) => {
  try {
    const listMediasQuery = API.graphql({
      query: queries.defaultListMedias,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          pagination: { page: 1, size: 500 },
          fileNameStartsWith,
          fileStatuses: ["DISTRIBUTING", "DISTRIBUTED", "FAILED"],
        },
      },
    });

    const allMedias = await listMediasQuery;
    dispatch({
      type: types.GET_MEDIAS,
      payload: allMedias.data,
    });
    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Start media upload */
export const startUpload =
  (fileName, fileType, fileSizeBytes, mediaFile) => async (dispatch) => {
    try {
      const startUploadQuery = API.graphql({
        query: mutations.startMediaFileUpload,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            fileName,
            fileSizeBytes,
            fileType,
          },
        },
      });

      const startedUrls = await startUploadQuery;

      dispatch({
        type: types.LOADING_UPLOAD_REQUEST,
      });

      const mediaData = startedUrls.data.startMediaFileUpload;

      let progressArray = {};
      const callBackUpload = (id, uploadBytes) => {
        progressArray[id] = uploadBytes;
        const sumProgressArray = Object.values({ ...progressArray }).reduce(
          (a, b) => a + b,
          0
        );
        const progressParts = (sumProgressArray / fileSizeBytes) * 100;
        dispatch({
          type: types.PROGRESS_VALUE_UPDATE,
          payload: progressParts,
        });
      };

      const uploadDone = () => {
        dispatch({
          type: types.LOADING_UPLOAD_DONE,
        });
        dispatch({
          type: types.PROGRESS_VALUE_RESET,
        });
      };

      fileChunksUpload(
        mediaData.id,
        mediaData.s3UploadParts,
        mediaFile,
        callBackUpload,
        uploadDone
      );

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Upload file chunks to respective signed URLs */
const fileChunksUpload = async (
  fileId,
  uploadUrls,
  mediaFile,
  callBack,
  uploadDone
) => {
  try {
    let start = 0;
    const uploadingLinks = [];
    for (let i = 0; i < uploadUrls.length; i++) {
      const mediaChunk = mediaFile.slice(
        start,
        start + uploadUrls[i].sizeBytes
      );

      uploadingLinks.push(
        uploadXMLRequest(uploadUrls[i].signedUrl, mediaChunk, i, callBack)
      );

      start += uploadUrls[i].sizeBytes;
    }
    await Promise.all(uploadingLinks).then((_) =>
      completeUpload(fileId, uploadDone)
    );
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Upload Request Fetch */
const uploadXMLRequest = (url, body, id, callBack) => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();

    xhr.upload.onprogress = function (event) {
      callBack(id, event.loaded);
    };

    xhr.onloadend = function () {
      if (xhr.status == 200) {
        resolve();
      }
    };

    xhr.open("PUT", url);
    xhr.onerror = reject;
    xhr.send(body);
  });
};

/* Complete media upload */
const completeUpload = async (id, uploadDone) => {
  try {
    const completeUploadQuery = API.graphql({
      query: mutations.completeMediaFileUpload,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await completeUploadQuery;

    setTimeout(uploadDone, 3000);
  } catch (err) {
    console.error("error: ", err);
  }
};
