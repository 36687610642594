import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
import NavPopover from "./popover";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Homepage",
    linkTo: "/",
  },
  // {
  //     label: 'Profile',
  //     linkTo: '#',
  // },
  // {
  //     label: 'Settings',
  //     linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const admin = useSelector((state) => state.default.profile);
  const lastWord = admin.family?.split("")[0];
  const firstWord = admin.given?.split("")[0];

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    handleClose();
    await Auth.signOut();
    navigate(0);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar sx={{ bgcolor: "#efd428bf" }}>{firstWord}{lastWord}</Avatar>
      </IconButton>

      <NavPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          backgroundColor: "#000",
          border: "solid 1px #ffdd00cc",
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
            "&:hover": {
              backgroundColor: "#ffdd00cc",
              color: "#000",
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {admin.given} {admin.family}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {admin.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {/* <Divider sx={{ borderStyle: "dashed" }} /> */}

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </NavPopover>
    </>
  );
};

export default AccountPopover;
