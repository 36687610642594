import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GlobalTemplate from "../../../templates/global";
import AdPlayer from "./components/adPlayer";
import { Container, Box, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";
import QuizContainer from "./components/quizContainer";

const WatchAdDetails = () => {
  const [isVideoEnd, setVideoEnd] = useState(false);

  const watchAd = useSelector((state) => state.ad.ad);
  const dispatch = useDispatch();

  let { id } = useParams();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.watchAd.getAd(id));
  }, [dispatch]);

  useEffect(() => {
    if (watchAd.isAnswerable != undefined && !watchAd.isAnswerable) {
      window.location.replace(`/`);
    }
  }, [watchAd]);

  return (
    <GlobalTemplate
      bgLight={Boolean(true)}
      bgBackground={Boolean(true)}
      title="Muz Kazan"
    >
      <Container
        disableGutters
        maxWidth="lg"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "100%",
        }}
      >
        <Box
          sx={{
            minHeight: { xs: "80vh", md: "30vh" },
            my: { xs: 4, md: 6, lg: 10 },
          }}
        >
          {watchAd.videoMediaUrl ? (
            <AdPlayer
              video={watchAd.videoMediaUrl}
              className="banana-player"
              color="ffde00"
              height="564"
              onEnd={() => setVideoEnd(true)}
            />
          ) : (
            <Skeleton
              sx={{
                bgcolor: "#101010",
                width: { xs: "95vw", md: "90vw", xl: "80vw" },
                height: { xs: "30vh", sm: "45vh", md: "50vh", lg: "60vh" },
              }}
              variant="rectangular"
            />
          )}
          {isVideoEnd && (
            <QuizContainer
              id={watchAd.id}
              qtn={watchAd.question}
              choices={watchAd.choices}
            />
          )}
        </Box>
      </Container>
    </GlobalTemplate>
  );
};

export default WatchAdDetails;
