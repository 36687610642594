import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardMedia,
  Grid,
  CircularProgress,
  Container,
  Typography,
  Button,
} from "@mui/material";
import "../../../config";
import { Auth } from "aws-amplify";

const bgSettings = {
  backgroundImage: `url(/assets/client/images/bg.jpg)`,
  backgroundRepeat: "no-repeat",
  backgroundColor: "#000",
  backgroundSize: "auto",
  backgroundPosition: "top",
  ["@media (min-width:2000px)"]: {
    backgroundRepeat: "round",
    backgroundPosition: "center",
  },
};

export default function EmailVerify() {
  const timeout = 60;
  const [timer, setTimer] = useState(timeout);
  const [retryCount, setRetryCount] = useState(2);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timeOut = () =>
      setTimeout(() => {
        if (timer > 0) setTimer(timer - 1);
      }, 1000);
    const timerId = timeOut();
    return () => {
      clearTimeout(timerId);
    };
  });

  const confirmEmail = async () => {
    setLoading(true);
    setTimer(timeout * retryCount);
    setRetryCount(retryCount + 1);
    try {
      await Auth.verifyCurrentUserAttribute("email");
    } catch (err) {
      if (err.name === "InvalidParameterException") {
        navigate("/");
      }
      console.error("Error", err);
    }
    setLoading(false);
  };

  return (
    <Grid container component="main" sx={{ height: "100vh", ...bgSettings }}>
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardMedia
          component="img"
          image="/logo.png"
          alt="Bananativi logo"
          height="auto"
          sx={{
            width: "35%",
          }}
        />
        <Typography
          variant="h5"
          align="center"
          color="#ffde00"
          component="h1"
          sx={{
            mt: 3,
            mb: 8,
            fontWeight: "bold",
            textShadow: "0px 5px #0000009e",
          }}
        >
          Lütfen e-posta adresinize gönderilen bağlantıya tıklayarak kaydınızı
          tamamlayınız.
        </Typography>
        <Typography
          variant="h6"
          align="center"
          color="#d7d3f8"
          component="h3"
          sx={{ mb: 8, fontWeight: "bold" }}
        >
          Lütfen gelen kutunuzla birlikte spam(önemsiz) klasörünü de kontrol
          ediniz.
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="#ffde00"
          component="h5"
          sx={{ mb: 5, fontWeight: "bold" }}
        >
          {timer > 0
            ? `E-posta ulaşmadı ise ${timer} saniye içerisinde tekrar
            gönderebilirsiniz.`
            : "E-posta ulaşmadı ise tekrar gönderebilirsiniz."}
        </Typography>
        {timer < 1 ? (
          <Button
            variant={isLoading ? "outlined" : "contained"}
            onClick={confirmEmail}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              "Tekrar Gönder"
            )}
          </Button>
        ) : (
          <CircularProgress color="primary" size={45} />
        )}
      </Container>
    </Grid>
  );
}
