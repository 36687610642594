import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

function NotFound({ redirect }) {
  useEffect(() => {
    document.title = "404 | Bananativi";
  }, []);

  return (
    <Grid
      container
      component="main"
      sx={{
        minHeight: "100vh",
      }}
    >
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardMedia
          component="img"
          image="/assets/client/images/404.png"
          alt="404 illustration"
          height="auto"
          sx={{
            width: "60%",
          }}
        />
        <Typography
          variant="h2"
          align="center"
          color="#FFFFFF"
          component="h2"
          sx={{ mt: 3, mb: 2, fontWeight: "900" }}
        >
          Ooops...
        </Typography>
        <Typography
          variant="h3"
          align="center"
          color="#FFFFFF"
          component="h3"
          sx={{ mt: 0, mb: 2, fontWeight: "300" }}
        >
          Sayfa bulunamadı
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="#FFFFFF"
          component="p"
          sx={{ mt: 0, mb: 5, fontWeight: "300" }}
        >
          Ana sayfaya geri dönmek için aşağıdaki düğmeye basınız.
        </Typography>

        <Button sx={{ width: "30%" }} variant={"contained"} href={redirect}>
          Geri Dön
        </Button>
      </Container>
    </Grid>
  );
}

export default NotFound;
