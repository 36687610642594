import React, { useEffect } from "react";
import GlobalTemplate from "../../templates/global";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useDispatch } from "react-redux";
import * as actions from "../../../../store/actions";

function Faq() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.dActions.defaultAction());
  }, [dispatch]);

  return (
    <GlobalTemplate bgBackground={Boolean(true)} title="SSS">
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Grid
          container
          wrap="nowrap"
          spacing={2}
          sx={{ pb: 5, borderBottom: "1px solid rgba(255, 222, 0, 0.3)" }}
        >
          <Grid item>
            <CircleIcon sx={{ fontSize: "9px", color: "#ffde00", mt: 2 }} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h5"
              color="text.primary"
              component="h2"
              noWrap
              sx={{ mb: 1 }}
            >
              Banana<span style={{ color: "#ffde00" }}>tivi</span> nedir ?
            </Typography>
            <Typography
              variant="h6"
              color="#69bae0a1"
              component="h2"
              sx={{ fontWeight: "normal" }}
            >
              Birbirinden eğlenceli video içerikleri ve çekilişleri tek bir
              platformda toplayan, Kıbrıs’ın ilk dijital eğlence platformudur.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          wrap="nowrap"
          spacing={2}
          sx={{ py: 5, borderBottom: "1px solid rgba(255, 222, 0, 0.3)" }}
        >
          <Grid item>
            <CircleIcon sx={{ fontSize: "9px", color: "#ffde00", mt: 2 }} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h5"
              color="text.primary"
              component="h2"
              noWrap
              sx={{ mb: 1 }}
            >
              Banana<span style={{ color: "#ffde00" }}>tivi</span> üyelik ücreti
              ne kadardır ?
            </Typography>
            <Typography
              variant="h6"
              color="#69bae0a1"
              component="h2"
              sx={{ fontWeight: "normal" }}
            >
              Platformumuzda bulunan tüm içeriklere ve çekilişlere
              erişebileceğiniz Premium Üyelik aylık ücreti 39.90 TL’dir. Bu
              ücrete her ay hesabınıza otomatik olarak eklenecek 10 çekiliş
              bananası dahildir.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          wrap="nowrap"
          spacing={2}
          sx={{ py: 5, borderBottom: "1px solid rgba(255, 222, 0, 0.3)" }}
        >
          <Grid item>
            <CircleIcon sx={{ fontSize: "9px", color: "#ffde00", mt: 2 }} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h5"
              color="text.primary"
              component="h2"
              noWrap
              sx={{ mb: 1 }}
            >
              Banana<span style={{ color: "#ffde00" }}>tivi</span> ‘de ne gibi
              içerikler var ?
            </Typography>
            <Typography
              variant="h6"
              color="#69bae0a1"
              component="h2"
              sx={{ fontWeight: "normal" }}
            >
              Bananativi’de birbirinden farklı eğlence, müzik, çocuk, yarışma,
              belgesel içeriklerini izleyebilirsiniz.
            </Typography>
          </Grid>
        </Grid>

        <Grid container wrap="nowrap" spacing={2} sx={{ py: 5 }}>
          <Grid item>
            <CircleIcon sx={{ fontSize: "9px", color: "#ffde00", mt: 2 }} />
          </Grid>
          <Grid item xs zeroMinWidth>
            <Typography
              variant="h5"
              color="text.primary"
              component="h2"
              noWrap
              sx={{ mb: 1 }}
            >
              Bananalar (
              <img
                src="/assets/client/images/banana.png"
                alt="banana image"
                style={{ height: "22px" }}
              />
              ) çekilişlerde ne işe yarar ?
            </Typography>
            <Typography
              variant="h6"
              color="#69bae0a1"
              component="h2"
              sx={{ fontWeight: "normal" }}
            >
              Her ay gerçekleştirilen büyük marka çekilişlerinde
              kullanabileceğiniz çekiliş bileti yerine geçer. Her Banana bir
              çekiliş bileti anlamına gelmektedir. Ne kadar fazla Banana, o
              kadar fazla şans!
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </GlobalTemplate>
  );
}

export default Faq;
