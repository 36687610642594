import React from "react";
import Label from "../../../../../../components/admin/label";
import { Box, Card, Link, Typography, Stack } from "@mui/material";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageIcon from "@mui/icons-material/Image";
import MovieCreationIcon from "@mui/icons-material/MovieCreation";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const MediaImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

// ----------------------------------------------------------------------

const MediaCard = ({ media }) => {
  const { fileName, type, status, cdnUrl, thumbnailImageMediaUrl } = media;
  return (
    <Card sx={{ bgcolor: "#ffde004d" }}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ minHeight: "200px", position: "relative", bgcolor: "#00000059" }}
      >
        {status == "DISTRIBUTING" ||
        (type == "VIDEO" && thumbnailImageMediaUrl == "") ? (
          <Typography sx={{ fontSize: "5rem" }} color="#ffffff61">
            {type == "VIDEO" ? (
              <MovieCreationIcon fontSize="inherit" color="inherit" />
            ) : (
              <ImageIcon fontSize="inherit" color="inherit" />
            )}
          </Typography>
        ) : (
          <MediaImgStyle
            alt={fileName}
            src={type == "VIDEO" ? thumbnailImageMediaUrl : cdnUrl}
          />
        )}
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link
          href={cdnUrl}
          color="inherit"
          underline="hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography variant="subtitle2" noWrap>
            {fileName}
          </Typography>
        </Link>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="span" variant="body1">
            {type}
          </Typography>

          <Label
            variant={status == "DISTRIBUTED" ? "filled" : "outlined"}
            color={status == "FAILED" ? "warning" : "primary"}
            startIcon={
              status == "DISTRIBUTING" ? (
                <QueryBuilderIcon />
              ) : status == "FAILED" ? (
                <CancelIcon />
              ) : (
                <CheckCircleIcon />
              )
            }
          >
            {status}
          </Label>
        </Stack>
      </Stack>
    </Card>
  );
};

export default MediaCard;
