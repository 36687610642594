import React from "react";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";

const defaultLogo =
  "https://www.freeiconspng.com/thumbs/credit-card-icon-png/credit-card-black-png-0.png";

const CardDetails = ({ data, deleteAction, setData }) => {
  return (
    <ListItem
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => {
            setData({ id: data.id, name: data.maskedNo });
            deleteAction(true);
          }}
        >
          <DeleteIcon sx={{ color: "#ed6c02" }} />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar
          src={data.logoUrl != null ? data.logoUrl : defaultLogo}
          alt="Card Logo"
          sx={{
            bgcolor: "#ffdc0012",
            "& img": {
              objectFit: "contain",
            },
          }}
        />
      </ListItemAvatar>
      <ListItemText primary={data.maskedNo} secondary={data.holder} />
    </ListItem>
  );
};

export default CardDetails;
