import { useRef, useState } from "react";
import { API } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Backdrop,
  Modal,
  Box,
  CircularProgress,
  Typography,
  Grid,
  Button,
  Fade,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import PreviewIcon from "@mui/icons-material/Preview";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../../store/actions";
import * as queries from "../../../../../../graphql/queries";
import UserModal from "./UserModal";
import { modalStyle } from "../../../../../../styles/DefaultComponents";

export default function UserMoreMenu({ id, name, count }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [tableModal, setTableModal] = useState(false);
  const [totalPages, setPages] = useState(0);
  const [signedUsers, setSignedUsers] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const dispatch = useDispatch();

  const handleDelete = (e) => {
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.influencer.deleteInfluencer(id));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSignedUsers(id, newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getSignedUsers(id, 1, parseInt(event.target.value, 10));
  };

  const getSignedUsers = async (affiliateId, page, size) => {
    setTableLoading(true);
    try {
      const signeUsersQuery = API.graphql({
        query: queries.listSignedUsers,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            conversionType: "SIGNED_UP",
            affiliateId,
            pagination: { page, size },
          },
        },
      });

      const allUsers = await signeUsersQuery;
      setSignedUsers(allUsers.data.getConvertedUsers.items);
      setPages(allUsers.data.getConvertedUsers.pagination.totalItems);
      setTableLoading(false);
    } catch (err) {
      console.error("error: ", err);
    }
  };

  return (
    <>
      {/* Modal Section */}
      <UserModal
        open={tableModal}
        name={name}
        modalClose={setTableModal}
        modalStyle={modalStyle}
        loading={tableLoading}
        data={signedUsers}
        rowChange={handleChangeRowsPerPage}
        totalPages={totalPages}
        pageChange={handleChangePage}
        page={page}
        rows={rowsPerPage}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalShow}
        onClose={() => setModalShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalShow}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ pb: 2 }}
            >
              Delete {name}
            </Typography>
            <Divider
              sx={{
                borderStyle: "groove",
                borderColor: "#ed6c02",
              }}
            />
            <Typography variant="h6" component="h4" sx={{ py: 4 }}>
              Are you sure you want to delete{" "}
              <span
                style={{
                  color: "#ed6c02",
                }}
              >
                {name}
              </span>
              ?
            </Typography>
            <Grid container justifyContent={"flex-end"}>
              <Button
                id="transition-modal-button"
                onClick={handleDelete}
                variant={buttonLoading ? "outlined" : "contained"}
                color={"warning"}
              >
                {buttonLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Delete"
                )}
              </Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>
      {/* End Modal Section */}

      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon sx={{ color: "text.primary" }} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 160, maxWidth: "100%", bgcolor: "#000" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to={`/admin/influencer/${id}/edit`}
          sx={{ color: "text.primary", "&:hover": { bgcolor: "#ffde004d" } }}
        >
          <ListItemIcon>
            <ModeEditOutlineIcon
              sx={{ color: "text.secondary" }}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        {count > 0 && (
          <MenuItem
            onClick={() => {
              getSignedUsers(id, 1, 50);
              setTableModal(true);
            }}
            sx={{ color: "text.primary", "&:hover": { bgcolor: "#ffde004d" } }}
          >
            <ListItemIcon>
              <PreviewIcon
                sx={{ color: "text.secondary" }}
                width={24}
                height={24}
              />
            </ListItemIcon>
            <ListItemText
              primary="View Users"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        <Divider sx={{ borderStyle: "dashed", borderColor: "#ed6c02" }} />

        <MenuItem
          onClick={() => setModalShow(true)}
          sx={{ color: "text.primary", "&:hover": { bgcolor: "#ffde004d" } }}
        >
          <ListItemIcon>
            <DeleteIcon sx={{ color: "#ed6c02" }} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2", color: "#ed6c02" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
