import { API } from "aws-amplify";
import * as types from "./types";
import * as tMedia from "../media/types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get single episode details */
export const getEpisode = (id) => async (dispatch) => {
  try {
    const getEpisodeQuery = API.graphql({
      query: queries.getEpisode,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id } },
    });

    const singleEpisode = await getEpisodeQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleEpisode.data.me,
    });

    dispatch({
      type: types.SINGLE_EPISODE,
      payload: singleEpisode.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Create episode */
export const createEpisode =
  (
    cast,
    description,
    index,
    isPublic,
    isTrailer,
    maturityIcons,
    premiereTime,
    programId,
    season,
    title,
    videoMediaUrl,
    videoThumbnailImageMediaUrl
  ) =>
  async (dispatch) => {
    try {
      const createEpisodeQuery = API.graphql({
        query: mutations.createEpisode,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            cast,
            description,
            index,
            isPublic,
            isTrailer,
            maturityIcons,
            premiereTime,
            programId,
            season,
            title,
            videoMediaUrl,
            videoThumbnailImageMediaUrl,
          },
        },
      });

      await createEpisodeQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace(`/admin/program/${programId}/details`);
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Get edit episode details */
export const getEditEpisode = (id) => async (dispatch) => {
  try {
    const getEditEpisodeQuery = API.graphql({
      query: queries.getEditEpisode,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        episode: { id },
        media: {
          pagination: { page: 1, size: 500 },
          fileStatuses: ["DISTRIBUTED"],
          fileTypes: ["IMAGE", "VIDEO"],
        },
      },
    });

    const singleEpisode = await getEditEpisodeQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleEpisode.data.me,
    });

    dispatch({
      type: tMedia.GET_MEDIAS,
      payload: singleEpisode.data,
    });

    dispatch({
      type: types.SINGLE_EPISODE,
      payload: singleEpisode.data,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Update episode */
export const updateEpisode =
  (
    id,
    cast,
    description,
    index,
    isPublic,
    isTrailer,
    maturityIcons,
    premiereTime,
    season,
    title,
    videoMediaUrl,
    videoThumbnailImageMediaUrl
  ) =>
  async (dispatch) => {
    try {
      const updateEpisodeQuery = API.graphql({
        query: mutations.updateEpisode,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            id,
            cast,
            description,
            index,
            isPublic,
            isTrailer,
            maturityIcons,
            premiereTime,
            season,
            title,
            videoMediaUrl,
            videoThumbnailImageMediaUrl,
          },
        },
      });

      await updateEpisodeQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace(`/admin/program/episode/${id}/details`);
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Delete episode */
export const deleteEpisode = (id, program_id) => async (dispatch) => {
  try {
    const deleteEpisodeQuery = API.graphql({
      query: mutations.deleteEpisode,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await deleteEpisodeQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.replace(`/admin/program/${program_id}/details`);
  } catch (err) {
    console.error("error: ", err);
  }
};
