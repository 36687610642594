import React, { useState } from "react";
import {
  Typography,
  Container,
  Stack,
  Box,
  CircularProgress,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  Button,
  Grid,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import { useDispatch, useSelector } from "react-redux";
import {
  CssTextField,
  CssFormControl,
} from "../../../../../styles/DefaultComponents";
import * as actions from "../../../../../store/actions";

const NewInfluencer = () => {
  const [fields, setFields] = useState({
    given: "",
    family: "",
    code: "",
    gsm: "",
    email: "",
    type: "",
  });
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    const name = `${fields["given"]} ${fields["family"]}`;
    e.preventDefault();
    dispatch(actions.dActions.buttonLoading());
    dispatch(
      actions.influencer.createInfluencer(
        fields["code"],
        fields["email"],
        fields["gsm"],
        name,
        fields["type"]
      )
    );
  };

  return (
    <AdminTemplate title="New Influencer">
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Typography variant="h4" gutterBottom>
          New Influencer
        </Typography>

        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} mt={1}>
              <CssTextField
                margin="normal"
                size="small"
                fullWidth
                required
                variant="outlined"
                label="Given Name"
                name="given"
                onChange={handleChange}
                value={fields["given"]}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={1}>
              <CssTextField
                margin="normal"
                size="small"
                fullWidth
                variant="outlined"
                label="Family Name"
                name="family"
                onChange={handleChange}
                value={fields["family"]}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={1}>
              <CssTextField
                margin="normal"
                size="small"
                fullWidth
                required
                variant="outlined"
                label="Email"
                name="email"
                onChange={handleChange}
                value={fields["email"]}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={1}>
              <CssTextField
                margin="normal"
                size="small"
                fullWidth
                required
                variant="outlined"
                label="Phone Number"
                name="gsm"
                onChange={handleChange}
                value={fields["gsm"]}
                inputProps={{
                  maxLength: 10,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+90</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} mt={1}>
              <CssFormControl
                margin="normal"
                size="small"
                required
                fullWidth
                variant="outlined"
              >
                <InputLabel>Type</InputLabel>
                <Select
                  label="Type"
                  value={fields["type"]}
                  name="type"
                  onChange={handleChange}
                >
                  <MenuItem
                    sx={{
                      color: "text.primary",
                      "&:hover": { bgcolor: "#ffde004d" },
                      "&.Mui-selected": {
                        bgcolor: "#ffde006e",
                        "&:hover": { bgcolor: "#ffde006e" },
                      },
                    }}
                    value="VENUE"
                  >
                    VENUE
                  </MenuItem>
                  <MenuItem
                    sx={{
                      color: "text.primary",
                      "&:hover": { bgcolor: "#ffde004d" },
                      "&.Mui-selected": {
                        bgcolor: "#ffde006e",
                        "&:hover": { bgcolor: "#ffde006e" },
                      },
                    }}
                    value="INFLUENCER"
                  >
                    INFLUENCER
                  </MenuItem>
                </Select>
              </CssFormControl>
            </Grid>
            <Grid item xs={12} sm={6} mt={1}>
              <CssTextField
                margin="normal"
                size="small"
                fullWidth
                variant="outlined"
                label="Referral Code"
                required
                name="code"
                onChange={handleChange}
                value={fields["code"]}
              />
            </Grid>
          </Grid>

          <Grid
            item
            md={8}
            lg={4}
            mt={5}
            xl={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-end" },
            }}
          >
            <Stack direction="row" spacing={2} sx={{ width: "20%" }}>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                href="/admin/influencer"
              >
                Cancel
              </Button>

              <Button
                variant={buttonLoading ? "outlined" : "contained"}
                fullWidth
                type="submit"
              >
                {buttonLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : (
                  "Save"
                )}
              </Button>
            </Stack>
          </Grid>
        </Box>
      </Container>
    </AdminTemplate>
  );
};

export default NewInfluencer;
