export const pageview = (url) => {
    if (process.env.REACT_APP_STAGE === "prod") {
        if (window && window.gtag) {
            window.gtag('config', 'G-87EZCBXM6W', {
                page_path: url,
            })
        }
    }
}

export const event = ({ action, params }) => {
    window.gtag('event', action, params)
}