import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import * as yup from "yup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  CssTextField,
  modalStyle,
} from "../../../../../styles/DefaultComponents";
import AuthTemplate from "../../../templates/auth";
import "../../../../../config";

function SignUp() {
  const [isLoading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [refCode, setCode] = useState("");
  const [refRead, setRead] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("ref")) {
      setCode(searchParams.get("ref"));
      setRead(true);
    }
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const schema = yup.object().shape({
    family: yup.string().required("Soyad zorunlu bir alandır"),
    given: yup.string().required("Ad zorunlu bir alandır"),
    phone: yup
      .string()
      .required("Bir telefon numarası gerekli")
      .matches(/^5([0-9]){9}$/, "Geçerli telefon numarası girin"),
    email: yup
      .string()
      .email("Geçersiz e-posta adresi")
      .required("E-posta alanı gerekli"),
    password: yup
      .string()
      .required("Lütfen parolanızı girin")
      .matches(/^(?=.*[a-z])/, "En az 1 küçük harf içerir")
      .matches(/^(?=.*[A-Z])/, "En az 1 büyük harf içerir")
      .matches(/^(?=.*[0-9])/, "En az 1 sayı içerir")
      .matches(/^(?=.{6,})/, "Minimum 6 karakter olmalı."),
    code: yup.string(),
    condition: yup.boolean().oneOf([true], "Message"),
  });

  const onSubmit = async (
    { family, given, email, password, code, phone },
    { _, setFieldError, __ }
  ) => {
    setLoading(true);
    try {
      const user = await Auth.signUp({
        username: email.toLowerCase(),
        password,
        attributes: {
          given_name: given,
          family_name: family,
          phone_number: "+90" + phone,
          "custom:referralCode": code,
        },
      });
      if (user) {
        navigate(`/auth/verify?id=${user.userSub}`);
      }
    } catch (err) {
      if (err.name === "UserLambdaValidationException")
        setFieldError(
          "phone",
          `Bu telefon numarasına (+90${phone}) sahip bir kullanıcı kayıtlıdır.`
        );

      if (err.name === "InvalidParameterException")
        setFieldError("email", "Alan doğrulama hatası.");

      if (err.name === "UsernameExistsException")
        setFieldError("email", "Bu Hesap Zaten Mevcut. Oturum açmayı deneyin.");

      if (err.name === "LimitExceededException")
        setFieldError(
          "email",
          "Deneme limiti aşıldı, lütfen bir süre sonra tekrar deneyin."
        );

      console.error("Error", err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      family: "",
      given: "",
      email: "",
      phone: "",
      password: "",
      code: refCode,
      condition: false,
    },
    validationSchema: schema,
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  return (
    <AuthTemplate>
      <Typography component="h1" variant="h5">
        Hesap Oluştur
      </Typography>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalShow}
        onClose={() => setModalShow(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalShow}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              sx={{ pb: 4 }}
            >
              Kullanım koşullarını
            </Typography>
            <iframe
              src="https://bananatv-uploads.s3.eu-central-1.amazonaws.com/doc/kullanimKosullari.html"
              width="100%"
              height="450px"
              style={{ backgroundColor: "#fff" }}
            />
            <Grid container justifyContent={"flex-end"}>
              <Button
                id="transition-modal-button"
                onClick={() => setModalShow(false)}
              >
                Kapat
              </Button>
            </Grid>
          </Box>
        </Fade>
      </Modal>

      <Box component="form" sx={{ pt: 7 }} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CssTextField
              margin="normal"
              size="small"
              required
              fullWidth
              variant="outlined"
              id="given"
              label="Ad"
              name="given"
              value={formik.values.given}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.given)}
              helperText={formik.errors.given}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              margin="normal"
              size="small"
              required
              fullWidth
              variant="outlined"
              id="family"
              label="Soyad"
              name="family"
              value={formik.values.family}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.family)}
              helperText={formik.errors.family}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CssTextField
              margin="normal"
              size="small"
              required
              fullWidth
              variant="outlined"
              id="email"
              label="E-Posta Adresi"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CssTextField
              margin="normal"
              size="small"
              required
              fullWidth
              variant="outlined"
              id="phone"
              label="Telefon numarası"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.phone)}
              helperText={formik.errors.phone}
              inputProps={{
                maxLength: 10,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+90</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <CssTextField
          margin="normal"
          size="small"
          required
          fullWidth
          variant="outlined"
          name="password"
          label="Parola Oluştur"
          type={passwordShown ? "text" : "password"}
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.password)}
          helperText={formik.errors.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={togglePassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  sx={{ color: "#fff" }}
                >
                  {passwordShown ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <CssTextField
          margin="normal"
          size="small"
          fullWidth
          variant="outlined"
          name="code"
          label="Var İse Referans Kodu Giriniz"
          id="code"
          value={formik.values.code}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.code)}
          helperText={formik.errors.code}
          InputProps={{
            readOnly: refRead,
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              name="condition"
              color="primary"
              onChange={formik.handleChange}
            />
          }
          label={
            <>
              <span>
                <a
                  style={{ color: "#ffde00" }}
                  onClick={() => setModalShow(true)}
                >
                  Kullanım koşullarını
                </a>{" "}
                kabul ediyorum.
              </span>
              {formik.errors.condition && (
                <h6 style={{ margin: 0, color: "#d32f2f" }}>
                  Şartlar ve koşulları kabul edin
                </h6>
              )}
            </>
          }
          color="textPrimary"
        />
        <Button
          disabled={!formik.isValid}
          type="submit"
          fullWidth
          variant={isLoading ? "outlined" : "contained"}
          sx={{
            mt: 3,
            mb: 2,
            color: formik.isValid ? "#000" : "#ffde0075 !important",
            border: formik.isValid
              ? "inherit"
              : "1px solid #ffde0075 !important",
          }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            "Kaydol"
          )}
        </Button>
        <Grid container justifyContent={"center"}>
          <Link href="/auth/signin" variant="subtitle1" underline="hover">
            Bir hesabınız var mı? Giriş yap
          </Link>
        </Grid>
      </Box>
    </AuthTemplate>
  );
}

export default SignUp;
