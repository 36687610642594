import React from "react";
import {
  Backdrop,
  Typography,
  Button,
  Modal,
  Grid,
  Fade,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  CssTextField,
  CssAutocomplete,
  modalStyle,
} from "../../../../../styles/DefaultComponents";

export default function PackageModal({
  name,
  medias,
  open,
  modalClose,
  handleSubmit,
  handleChange,
  amount,
  iconUrl,
  price,
}) {
  const buttonLoading = useSelector((state) => state.default.loading.button);
  return (
    <Modal
      aria-labelledby={`modal-label-${name}`}
      aria-describedby={`modal-description-${name}`}
      open={open}
      onClose={() => modalClose(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <Typography id={`modal-label-${name}`} variant="h6" component="h2">
            {name == "create" ? "New package" : "Edit package"}
          </Typography>
          <Box
            id={`modal-description-${name}`}
            component="form"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CssAutocomplete
                  disablePortal
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.label
                  }
                  options={medias}
                  onChange={(_, value) => handleChange(value, "iconUrl")}
                  value={iconUrl || null}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      margin="normal"
                      size="small"
                      fullWidth
                      required
                      label="Package Icon"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  value={amount}
                  label="Amount"
                  onChange={(e) => handleChange(e.target.value, "amount")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  variant="outlined"
                  value={price}
                  label="Price"
                  onChange={(e) => handleChange(e.target.value, "price")}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Button
                id={`modal-button-${name}`}
                type="submit"
                variant={buttonLoading ? "outlined" : "contained"}
                sx={{
                  mt: 3,
                  mb: 2,
                }}
              >
                {buttonLoading ? (
                  <CircularProgress color="inherit" size={30} />
                ) : name == "create" ? (
                  "Create"
                ) : (
                  "Update"
                )}
              </Button>
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
