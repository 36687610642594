import React, { useEffect } from "react";
import GlobalTemplate from "../../../templates/global";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CircularProgress, List } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../../../store/actions";
import HistoryCard from "./components/historyCard";

const PurchaseHistory = () => {
  const dispatch = useDispatch();

  const transactions = useSelector((state) => state.purchase.purchaseHistory);
  const uiLoading = useSelector((state) => state.default.loading.container);

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.purchase.getPurchaseHistory());
  }, [dispatch]);

  return (
    <GlobalTemplate
      bgBackground={Boolean(true)}
      title="Banana Satın Alma Geçmişi"
    >
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {uiLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Typography
              variant="h4"
              align="center"
              color="#ffffff75"
              sx={{ mt: 6, mb: 3, fontWeight: "normal" }}
            >
              Satın Alım Geçmişi
            </Typography>
            <List
              sx={{ width: "100%", maxWidth: 460, bgcolor: "background.paper" }}
            >
              {transactions?.map((transaction, i) => (
                <HistoryCard data={transaction} key={i} />
              ))}
            </List>
          </>
        )}
      </Container>
    </GlobalTemplate>
  );
};

export default PurchaseHistory;
