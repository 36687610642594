import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { useAuthStatus } from "../../hooks/useAuthStatus";
import Spinner from "./Spinner";

const PrivateRoute = ({ redirect }) => {
  const { loggedIn, checkingStatus, isAdmin, emailVerified, isGsm } =
    useAuthStatus();
  const [searchParams] = useSearchParams();

  if (checkingStatus) {
    return <Spinner />;
  }

  if (redirect == "auth") {
    return loggedIn ? <Navigate to="/" /> : <Outlet />;
  }

  if (redirect == "admin") {
    return isAdmin ? <Outlet /> : <Navigate to="/" />;
  }

  return loggedIn ? (
    <Outlet context={[emailVerified, isGsm]} />
  ) : (
    <Navigate
      to={`/auth/signin${
        searchParams.get("code") ? "?code=" + searchParams.get("code") : ""
      }`}
    />
  );
};

export default PrivateRoute;
