import React from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";
import MediaCard from "./MediaCard";

export default function MediaTabItem({ loading, data, type }) {
  return (
    <>
      {loading ? (
        <Grid
          container
          component="main"
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "50vh" }}
        >
          <CircularProgress />
        </Grid>
      ) : data.length > 0 ? (
        <Grid container spacing={3}>
          {data.map((media) => (
            <Grid key={media.cdnUrl} item xs={12} sm={6} md={3}>
              <MediaCard media={media} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          component="main"
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ height: "50vh" }}
        >
          <Typography
            variant="h6"
            color="#ffffff61"
            component="h2"
            sx={{ fontWeight: "normal" }}
          >
            No {type} file found
          </Typography>
        </Grid>
      )}
    </>
  );
}
