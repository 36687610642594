import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Card, Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const EpisodeImgStyle = styled("img")({
  width: "100%",
});

// ----------------------------------------------------------------------

const EpisodeCard = ({ ep }) => {
  const { id, title, videoThumbnailImageMediaUrl, isTrailer } = ep;
  return (
    <Link
      to={`/admin/program/episode/${id}/details`}
      color="inherit"
      underline="none"
      component={RouterLink}
    >
      <Card
        sx={{
          bgcolor: "transparent",
          transition: "0.5s",
          "&:hover": {
            boxShadow: "0px 0px 7px 4px #ffde009c",
            borderRadius: 0,
          },
        }}
      >
        <Box>
          <EpisodeImgStyle alt={title} src={videoThumbnailImageMediaUrl} />
        </Box>

        <Typography variant="subtitle2" noWrap sx={{ pl: 1 }}>
          {isTrailer ? "Trailer" : title}
        </Typography>
      </Card>
    </Link>
  );
};

export default EpisodeCard;
