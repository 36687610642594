import { API } from "aws-amplify";
import * as types from "./types";
import * as dType from "../../default/types";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

/* Get all influencers */
export const getInfluencers = (page, size) => async (dispatch) => {
  try {
    const listInfluencersQuery = API.graphql({
      query: queries.listAffiliates,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { pagination: { page, size } } },
    });

    const allInfluencers = await listInfluencersQuery;
    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: allInfluencers.data.me,
    });

    dispatch({
      type: dType.LIST_PAGINATION,
      payload: allInfluencers.data.listAffiliates.pagination,
    });

    dispatch({
      type: types.GET_INFLUENCERS,
      payload: allInfluencers.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Get single influencer details */
export const getInfluencer = (id) => async (dispatch) => {
  try {
    const getInfluencerQuery = API.graphql({
      query: queries.getAffiliate,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: { input: { id } },
    });

    const singleInfluencer = await getInfluencerQuery;

    dispatch({
      type: dType.PROFILE_DEFAULT,
      payload: singleInfluencer.data.me,
    });

    dispatch({
      type: types.SINGLE_INFLUENCER,
      payload: singleInfluencer.data,
    });

    dispatch({
      type: dType.UI_LOADING_DONE,
    });
  } catch (err) {
    console.error("error: ", err);
  }
};

/* Create influencer */
export const createInfluencer =
  (code, email, gsm, name, type) => async (dispatch) => {
    try {
      const createInfluencerQuery = API.graphql({
        query: mutations.createAffiliate,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            code,
            email,
            gsm,
            name,
            type,
          },
        },
      });

      await createInfluencerQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });

      window.location.replace("/admin/influencer");
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Update influencer */
export const updateInfluencer =
  (id, email, gsm, name, type) => async (dispatch) => {
    try {
      const updateInfluencerQuery = API.graphql({
        query: mutations.updateAffiliate,
        authMode: "AMAZON_COGNITO_USER_POOLS",
        variables: {
          input: {
            id,
            email,
            gsm,
            name,
            type,
          },
        },
      });

      await updateInfluencerQuery;

      dispatch({
        type: dType.LOADING_BUTTON_DONE,
      });
      window.location.replace(`/admin/influencer`);
    } catch (err) {
      console.error("error: ", err);
    }
  };

/* Delete influencer */
export const deleteInfluencer = (id) => async (dispatch) => {
  try {
    const deleteInfluencerQuery = API.graphql({
      query: mutations.deleteAffiliate,
      authMode: "AMAZON_COGNITO_USER_POOLS",
      variables: {
        input: {
          id,
        },
      },
    });

    await deleteInfluencerQuery;

    dispatch({
      type: dType.LOADING_BUTTON_DONE,
    });

    window.location.reload();
  } catch (err) {
    console.error("error: ", err);
  }
};
