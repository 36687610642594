import React, { useEffect, useState } from "react";
import { filter } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  Button,
  Checkbox,
  TableRow,
  Grid,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import Label from "../../../../../components/admin/label";
import SearchNotFound from "./components/SearchNotFound";
import UserListHead from "./components/UserListHead";
import UserListToolbar from "./components/UserListToolbar";
import UserMoreMenu from "./components/UserMoreMenu";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "gsm", label: "Phone Number", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "signedUpsCount", label: "Referral Users Count", alignRight: false },
  { id: "code", label: "Referral Code", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const Influencer = () => {
  const users = useSelector((state) => state.influencer.influencers);
  const [paginationLoading, setTableLoading] = useState(true);
  const listPagination = useSelector((state) => state.default.pagination);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    dispatch(actions.influencer.getInfluencers(1, rowsPerPage));
    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setTableLoading(true);
    setPage(newPage);
    dispatch(actions.influencer.getInfluencers(newPage + 1, rowsPerPage));
    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  };

  const handleChangeRowsPerPage = (event) => {
    setTableLoading(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(
      actions.influencer.getInfluencers(1, parseInt(event.target.value, 10))
    );
    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0 ? Math.max(0, page * rowsPerPage - listPagination?.totalItems) : 0;

  const filteredUsers = applySortFilter(
    users,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <AdminTemplate title="Influencer">
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Influencers
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/admin/influencer/new"
            startIcon={<AddIcon />}
          >
            New Influencer
          </Button>
        </Stack>
        {paginationLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Card sx={{ bgcolor: "#ffde004d" }}>
            <UserListToolbar
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <TableContainer>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={listPagination?.totalItems}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.map((row) => {
                    const { id, name, code, email, gsm, type, signedUpsCount } =
                      row;
                    const isItemSelected = selected.indexOf(name) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, name)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{gsm}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="filled"
                            color={type == "VENUE" ? "warning" : "primary"}
                          >
                            {type}
                          </Label>
                        </TableCell>
                        <TableCell align="left">{signedUpsCount}</TableCell>
                        <TableCell align="left">{code}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            id={id}
                            name={email}
                            count={signedUpsCount}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound
                          searchQuery={filterName}
                          sx={{ bgcolor: "transparent", boxShadow: "none" }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={listPagination?.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default Influencer;
