/***************CLIENT****************/

export const defaultProfile = /* GraphQL */ `
  query Me {
    me {
      balance
      familyName
      givenName
      isAdmin
    }
  }
`;

export const subPaynetQuery = /* GraphQL */ `
  query PaynetQuery {
    me {
      subscription {
        validBefore
        status
        id
        redirectionUrl
      }
    }
  }
`;

export const getUserCurrentStatus = /* GraphQL */ `
  query meProfileUser($input: ListBananaPurchasesInput!) {
    me {
      balance
      familyName
      givenName
      isAdmin
      email
      gsm
      subscription {
        validBefore
        status
        id
        redirectionUrl
        paymentType
      }
    }

    savedCards {
      id
      holder
      maskedNo
      logoUrl
    }

    listBananaPurchases(input: $input) {
      items {
        id
        amount
        price
        timestamp
        status
      }
    }
  }
`;

export const listRaffles = /* GraphQL */ `
  query ListRaffles($input: ListRafflesInput!) {
    me {
      balance
      familyName
      isAdmin
      givenName
      email
    }
    listRaffles(input: $input) {
      items {
        isArchived
        isPublic
        startTime
        endTime
        title
        id
        verticalPosterImageMediaUrl
        winnerTickets {
          familyName
        }
      }
    }
  }
`;

export const getRaffle = /* GraphQL */ `
  query GetRaffle($input: GetRaffleInput!) {
    me {
      balance
      familyName
      givenName
      isAdmin
      subscription {
        validBefore
      }
    }
    getRaffle(input: $input) {
      id
      title
      endTime
      startTime
      sponsorBannerMediaUrl
      isArchived
      isPublic
      promoVideoMediaUrl
      resultTime
      winnerPrompt
      userTickets {
        id
      }
      winnerTickets {
        givenName
        familyName
      }
    }
  }
`;

export const programListings = /* GraphQL */ `
  query programListings {
    me {
      balance
      familyName
      isAdmin
      givenName
    }
    programListings {
      title
      index
      type
      items {
        id
        title
        bannerImageMediaUrl
        verticalPosterImageMediaUrl
        horizontalPosterImageMediaUrl
        categoryDisplayName
        episodesCount
      }
    }
  }
`;

export const getProgram = /* GraphQL */ `
  query getProgram($input: GetProgramInput!) {
    me {
      balance
      familyName
      isAdmin
      givenName
      subscription {
        validBefore
      }
    }
    getProgram(input: $input) {
      id
      title
      categoryDisplayName
      episodes {
        id
        maturityIcons
        description
        cast
        title
        videoMediaUrl
        videoThumbnailImageMediaUrl
        isPublic
        isTrailer
        maturityIcons
      }
    }
  }
`;

export const getAdminProgram = /* GraphQL */ `
  query getAdminProgram(
    $episode: ListEpisodesInput!
    $program: GetProgramInput!
  ) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    getProgram(input: $program) {
      id
      title
      categoryDisplayName
      bannerImageMediaUrl
      category
      horizontalPosterImageMediaUrl
      verticalPosterImageMediaUrl
    }
    listEpisodes(input: $episode) {
      items {
        id
        maturityIcons
        description
        cast
        title
        videoMediaUrl
        videoThumbnailImageMediaUrl
        isPublic
        isTrailer
        maturityIcons
      }
    }
  }
`;

export const listHistory = /* GraphQL */ `
  query listHistory($input: ListBananaPurchasesInput!) {
    me {
      balance
      familyName
      isAdmin
      givenName
    }

    listBananaPurchases(input: $input) {
      items {
        id
        amount
        price
        status
        timestamp
      }
    }
  }
`;

export const paymentParamsAndBananaPackages = /* GraphQL */ `
  query paymentParamsAndBananaPackages {
    me {
      balance
      familyName
      isAdmin
      givenName
    }

    paymentParams {
      paynetFormScriptUrl
      paynetOwnerId
      paynetUserUniqueId
      paynetPublishableKey
      paynetRatioCode
    }

    bananaPackages {
      amount
      id
      price
      purchaseFormActionUrl
      iconUrl
    }
  }
`;

export const listAds = /* GraphQL */ `
  query listAds($input: ListPublishedAdsInput!) {
    me {
      balance
      familyName
      isAdmin
      givenName
    }
    listPublishedAds(input: $input) {
      items {
        id
        isAnswerable
        thumbnailMediaUrl
      }
    }
  }
`;

export const getAd = /* GraphQL */ `
  query getAd($input: GetPublishedAdInput!) {
    me {
      balance
      familyName
      isAdmin
      givenName
    }
    getPublishedAd(input: $input) {
      id
      isAnswerable
      question
      videoMediaUrl
      choices {
        title
        value
      }
    }
  }
`;

/***************ADMIN****************/
export const adminDashboardQuery = /* GraphQL */ `
  query adminDashboardQuery {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    generalStatistics {
      validSubCount
      userCount
      loggedInUserCount
      validUserCountWithReferralCount
    }
  }
`;

export const defaultListMedias = /* GraphQL */ `
  query MediaFiles($input: ListMediaFilesInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    listMediaFiles(input: $input) {
      items {
        cdnUrl
        fileName
        status
        type
        thumbnailImageMediaUrl
      }
    }
  }
`;

export const listPrograms = /* GraphQL */ `
  query Programs($input: ListProgramsInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    listPrograms(input: $input) {
      items {
        id
        title
        verticalPosterImageMediaUrl
        episodesCount
        category
      }
    }
  }
`;

export const getEditProgram = /* GraphQL */ `
  query getEditProgram(
    $media: ListMediaFilesInput!
    $program: GetProgramInput!
  ) {
    me {
      email
      isAdmin
      familyName
      givenName
    }

    listMediaFiles(input: $media) {
      items {
        cdnUrl
        fileName
        type
      }
    }

    getProgram(input: $program) {
      id
      title
      bannerImageMediaUrl
      category
      horizontalPosterImageMediaUrl
      verticalPosterImageMediaUrl
    }
  }
`;

export const getEditRaffle = /* GraphQL */ `
  query getEditRaffle($media: ListMediaFilesInput!, $raffle: GetRaffleInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }

    listMediaFiles(input: $media) {
      items {
        cdnUrl
        type
        fileName
      }
    }

    getRaffle(input: $raffle) {
      id
      description
      endTime
      isPublic
      isArchived
      promoVideoMediaUrl
      sponsorBannerMediaUrl
      startTime
      title
      verticalPosterImageMediaUrl
    }
  }
`;

export const getEpisode = /* GraphQL */ `
  query getEpisode($input: GetEpisodeInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    getEpisode(input: $input) {
      id
      description
      cast
      season
      index
      videoThumbnailImageMediaUrl
      title
      maturityIcons
      programId
    }
  }
`;

export const getEditEpisode = /* GraphQL */ `
  query getEditEpisode(
    $media: ListMediaFilesInput!
    $episode: GetEpisodeInput!
  ) {
    me {
      email
      isAdmin
      familyName
      givenName
    }

    listMediaFiles(input: $media) {
      items {
        cdnUrl
        type
        fileName
      }
    }

    getEpisode(input: $episode) {
      id
      cast
      description
      index
      isPublic
      isTrailer
      maturityIcons
      premiereTime
      season
      title
      videoMediaUrl
      videoThumbnailImageMediaUrl
    }
  }
`;

export const adminListAds = /* GraphQL */ `
  query adminListAds($input: AdminListAdsInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    adminListAds(input: $input) {
      items {
        thumbnailMediaUrl
        isPublished
        id
        bananaAmount
      }
    }
  }
`;

export const getEditAd = /* GraphQL */ `
  query getEditAd($media: ListMediaFilesInput!, $ad: AdminGetAdInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }

    listMediaFiles(input: $media) {
      items {
        cdnUrl
        type
        fileName
      }
    }

    adminGetAd(input: $ad) {
      thumbnailMediaUrl
      isPublished
      id
      bananaAmount
      wrongChoices
      rightChoice
      question
      videoMediaUrl
    }
  }
`;

export const listAffiliates = /* GraphQL */ `
  query Affiliates($input: ListAffiliatesInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    listAffiliates(input: $input) {
      items {
        code
        email
        gsm
        name
        type
        id
        signedUpsCount
      }

      pagination {
        pageItems
        pageNumber
        totalItems
        totalPages
      }
    }
  }
`;

export const getAffiliate = /* GraphQL */ `
  query getAffiliate($input: GetAffiliateInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    getAffiliate(input: $input) {
      code
      email
      gsm
      name
      type
      id
    }
  }
`;

export const programSections = /* GraphQL */ `
  query programSections($input: ListProgramsInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    programListings {
      id
      title
      index
      type
      items {
        id
        title
        bannerImageMediaUrl
        verticalPosterImageMediaUrl
        horizontalPosterImageMediaUrl
        categoryDisplayName
      }
    }

    listPrograms(input: $input) {
      items {
        id
        title
        verticalPosterImageMediaUrl
        category
      }
    }
  }
`;

export const listUsers = /* GraphQL */ `
  query Users($input: ListUsersInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    listUsers(input: $input) {
      items {
        familyName
        givenName
        email
        gsm
        id
      }

      pagination {
        pageItems
        pageNumber
        totalItems
        totalPages
      }
    }
  }
`;

export const viewParticipants = /* GraphQL */ `
  query viewParticipants($input: ListRaffleParticipantsInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    listRaffleParticipants(input: $input) {
      totalTicketCount
      items {
        ticketCount
        phoneNumber
        givenName
        familyName
        email
      }
      raffle {
        title
      }
      pagination {
        pageItems
        pageNumber
        totalItems
        totalPages
      }
    }
  }
`;

export const listSubscribers = /* GraphQL */ `
  query Users($input: ListSubscribersInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }
    listSubscribers(input: $input) {
      items {
        id
        givenName
        familyName
        email
        balance
        gsm
        subscription {
          paymentProvider
          status
          validBefore
          createTime
        }
      }

      pagination {
        pageItems
        pageNumber
        totalItems
        totalPages
      }
    }
  }
`;

export const listSignedUsers = /* GraphQL */ `
  query convertedUsers($input: GetConvertedUsersInput!) {
    getConvertedUsers(input: $input) {
      items {
        subscription {
          status
        }
        givenName
        familyName
        email
        gsm
        id
      }
      pagination {
        totalItems
      }
    }
  }
`;

export const listAdminPackages = /* GraphQL */ `
  query listAdminPackages($input: ListMediaFilesInput!) {
    me {
      email
      isAdmin
      familyName
      givenName
    }

    listMediaFiles(input: $input) {
      items {
        cdnUrl
        fileName
        type
      }
    }

    bananaPackages {
      amount
      iconUrl
      id
      price
    }
  }
`;
