import React, { useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Stack,
  Container,
  Grid,
  Typography,
  Zoom,
  Button,
  Tooltip,
  Box,
  CardMedia,
  Divider,
  CircularProgress,
} from "@mui/material";
import AdminTemplate from "../../../templates/global";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EpisodeCard from "./components/EpisodeCard";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../store/actions";

const ProgramDetails = () => {
  const program = useSelector((state) => state.program.program);
  const episodes = useSelector((state) => state.episode.episodes);
  const uiLoading = useSelector((state) => state.default.loading.container);
  const buttonLoading = useSelector((state) => state.default.loading.button);
  const dispatch = useDispatch();

  let { program_id } = useParams();

  useEffect(() => {
    dispatch(actions.dActions.uiLoading());
    dispatch(actions.program.getAdminProgram(program_id));
  }, [dispatch]);

  const handleDelete = () => {
    dispatch(actions.dActions.buttonLoading());
    dispatch(actions.program.deleteProgram(program_id));
  };

  return (
    <AdminTemplate
      title={program.title ? `${program.title} - Program` : "Yükleniyor..."}
    >
      <Container maxWidth="false" sx={{ maxWidth: "1950px" }}>
        <Button
          variant="text"
          component={RouterLink}
          to={`/admin/program/all`}
          startIcon={<ArrowBackIcon />}
        >
          Back to All Programs
        </Button>
        <Typography variant="h4" gutterBottom>
          Program Details
        </Typography>

        {uiLoading ? (
          <Grid
            container
            component="main"
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "50vh" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              spacing={3}
              pt={3}
              pb={8}
            >
              <Box
                sx={{
                  position: "relative",
                  minHeight: { xs: "5vh", md: "10vh" },
                  width: "50%",
                }}
              >
                <CardMedia
                  component="img"
                  image={program.horizontalPosterImageMediaUrl}
                  alt="program image"
                  sx={{
                    px: 1,
                  }}
                />
              </Box>

              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                pt={3}
              >
                <Typography variant="h5">{program.title}</Typography>
                <Typography variant="h6" sx={{ color: "text.secondary" }}>
                  {program.category}
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={3}
                  pt={3}
                >
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to={`/admin/program/${program_id}/edit`}
                    startIcon={<ModeEditOutlineIcon />}
                  >
                    Edit
                  </Button>
                  <Tooltip
                    title="To delete program, first delete all its episodes"
                    TransitionComponent={Zoom}
                    disableHoverListener={episodes?.length < 1}
                    followCursor
                  >
                    <Button
                      variant={
                        episodes?.length < 1 && !buttonLoading
                          ? "contained"
                          : "outlined"
                      }
                      color="warning"
                      disableRipple={episodes?.length > 0}
                      startIcon={<DeleteIcon />}
                      onClick={episodes?.length < 1 ? handleDelete : null}
                      sx={{
                        "&:hover": {
                          cursor: episodes?.length > 0 ? "default" : "pointer",
                        },
                      }}
                    >
                      {buttonLoading ? (
                        <CircularProgress color="inherit" size={30} />
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
            </Stack>

            <Divider sx={{ borderStyle: "dashed", borderColor: "#ffde00" }} />

            <Container maxWidth="xl">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={100}
                pt={3}
              >
                <Typography variant="h5" gutterBottom>
                  Episodes
                </Typography>
                <Button
                  variant="text"
                  component={RouterLink}
                  to={`/admin/program/${program_id}/episode/new`}
                >
                  Add Episode
                </Button>
              </Stack>

              <Grid container spacing={3}>
                {episodes
                  ?.sort((a, b) => Number(b.isTrailer) - Number(a.isTrailer))
                  .map((ep) => (
                    <Grid key={ep.id} item xs={12} sm={6} md={3}>
                      <EpisodeCard ep={ep} />
                    </Grid>
                  ))}
              </Grid>
            </Container>
          </>
        )}
      </Container>
    </AdminTemplate>
  );
};

export default ProgramDetails;
